import React from 'react';
import { baseURL } from 'controllers/Api';
import { specs } from "utils/Constants";
import moment from "moment";
import Icons from "utils/Icons";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { getEventText } from 'containers/WorkerPage/WorkerCard';
import api from "controllers/Api";
import Modal2 from 'components/Modal2';
import { isCheckinTime } from 'utils/Helpers';

class Item extends React.Component {

  onExtendJob = () => {
    Modal2('ModalExtendJob', {
      extend: (hours) => this.handleAction('extend', { hours }),
      max: this.props.data.max_extend_hours
    });
  }

	onClickEventAction = (e) => {
		const { action } = e.currentTarget.dataset;
    this.handleAction(action)
  }

  handleAction = (action, reqData) => {
    const { data, fetch } = this.props;
		api
		.post(`/order/${data.id}/${action}`, reqData)
		.then(r => {
			fetch(true);
		})
		.catch(e => {
			console.error('onClickEventAction', e.response?.data || e.message);
			alert(e.response?.data?.error || e.message);
		});
  }

	renderEventActions = (status) => {
		const { data } = this.props;
    let output;
    const canExtend = data.max_extend_hours > 0;
    const isCheckin = isCheckinTime(data.start);

		switch (status) {

			case 'delayed':
				output = (
					<>
						<span className='btn btn_small btn_outline' onClick={this.onClickEventAction} data-action="notwait">Отменить</span>
						<span className='btn btn_small btn_primary' onClick={this.onClickEventAction} data-action="wait">Подождать {data.delay} мин.</span>
					</>
				);
				break;

			case 'waiting':
			case 'going':
			case 'checkingin':
				output = (
					<>
						{ isCheckin && <span className='btn btn_small btn_primary' onClick={this.onClickEventAction} data-action="checkin">Исполнитель на месте</span> }
					</>
				);
				break;

			case 'inprogress':
        output = (
          <>
            { canExtend && <span className='btn btn_small btn_outline' onClick={this.onExtendJob} data-action="extend">Продлить смену</span>}
          </>
        );
        break;
			case 'checkingout':
			case 'completed':
				output = (
					<>
						<span className='btn btn_small btn_primary' onClick={this.onClickEventAction} data-action="confirm">Закончить смену</span>
					</>
				);
				break;

			case 'failed':
				if (new Date(data.finish) > new Date()) {
					output = (
						<>
							<span className='btn btn_small btn_primary' onClick={this.onClickEventAction} data-action="restore">Возобновить заявку</span>
						</>
					);
				}
				break;

			case 'confirmed':
				if(!data.review) {
					const link = `/orders/${data.source}/${data.date}/${data.id}/${data.worker._id}`;
					output = <Link to={link} className='btn btn_small btn_primary'>Оставить отзыв</Link>;
				}
				break;

			default:
		}

		return output ? <div className="flex mt2" style={{ justifyContent: 'flex-end' }}>{output}</div> : null;
	}

	render() {
		const { data, searchText, hideActions = false } = this.props;
		const avatar = `${baseURL}/user/${data.worker._id}/avatar`;
		let name = `${data?.worker?.user?.firstname} ${data?.worker?.user?.lastname}`;
		const spec = specs[data.spec] || data.spec;
		const link = `/orders/${data.source}/${data.date}/${data.id}`;

		// events timeline
		const event = {
			status: null,
			time: null,
			text: null,
			actions: null
		};

		// поменять на статусы
		const lastEvent = data?.events[data.events.length-1];
		if(lastEvent && !hideActions) {
			event.status = data.status;
			event.time = moment(lastEvent.createdAt).format("HH:mm");
			event.text = getEventText(lastEvent, null, data);
			event.actions = this.renderEventActions(data.status);
		}

		if(searchText) {
			name = <Highlighter
				highlightClassName="YourHighlightClass"
				searchWords={[searchText]}
				autoEscape={true}
				textToHighlight={name}
			/>;
		}

		return (
			<div className="workers-list_item">
				<Link to={link} className={`workers-list_worker color_black p2`}>
					<div className="workers-list_worker-img" style={{backgroundImage: `url(${avatar})`}}/>
					<div className="workers-list_worker-texts">
						<div className="workers-list_worker-name">{name}</div>
						<div className="workers-list_worker-status">{spec}, {data.start_time} - {data.finish_time}</div>
					</div>
					{Icons.next}
				</Link>
				{event.status && !hideActions && (
					<div className="ph2 pb2 workers-list_item-events">
						<div className="flex">
							<strong>{event.time}</strong>
							<div>{event.text}</div>
						</div>
						{event.actions}
					</div>
				)}
				{hideActions && <div className=""/>}
			</div>
		);
	}
}

export default Item;
