import React from 'react';
import Modal2 from 'components/Modal2';
import TagsController from 'controllers/Tags';

export default (props) => {
  const { data, hideInstruction = false } = props;
  const { instruction, description, able_to_call, phone } = data;

  const onClickInstruction = () => Modal2('ModalJobDuties', { instruction });

  const onClickCall = () => TagsController.event('Buttons', 'CustomerContact', 'Call');
  const needDisplayPhone = able_to_call && phone;

  const PhonePart = (
    <a
      onClick={onClickCall}
      href={`tel:+${phone}`}
      className='btn btn_small btn_green btn_outline'
    >Позвонить</a>
  );

  if (!description && !instruction && !able_to_call) return null;

  return (
    <div className='content_block'>
      <div className='content_block-title mb2'>Детали</div>

      <div className='job_page__description'>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className='flex_btns mt2'>{needDisplayPhone && PhonePart}</div>
      {!hideInstruction && instruction && (
        <>
          <br />
          <br />
          <div className='btn btn_small btn_fullwidth mt2' onClick={onClickInstruction}>Инструкция и правила работы</div>
        </>
      )}
    </div>
  );
}
