import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { simulateModalLayout } from 'utils/Helpers';
import DocumentUpload from 'components/DocumentUpload';
import { Header } from 'components/Modal2';

class Document extends React.Component {
  state = {
    isUploading: false
  }

  componentDidMount() {
    simulateModalLayout();
  }

  componentWillUnmount() {
    simulateModalLayout(false);
  }

  render() {
    const { type } = this.props.match.params;
    return (
      <>
      <Header title="Загрузка документов" back="/profile" />
      <div>
        <div className="content_block">
          <div className="alert_header flex mb2">
            <div className="alert_title">Загрузите фото документов</div>
            <span role="img" aria-label="photo" className="alert_icon" style={{ fontSize: 48 }}>📷</span>
          </div>
          <p>
            Проверка займёт не более 10 минут.
            <br />Документы нужны для того, чтобы ваша работа была легальной.
          </p>
          <a href="https://mygig.zendesk.com/hc/ru/articles/360007486757-Не-получается-добавить-фотографию-документа" target="_blank" rel="noopener noreferrer" className="color_blue"><strong>Не получается загрузить документы </strong></a>
        </div>
        <DocumentUpload
          type={type}
          multiple={true}
          onUploadStart={() => this.setState({ ...this.state, isUploading: true })}
          onUploadFinish={() => this.setState({ ...this.state, isUploading: false })}
        />
        <div className="modal_btn-holder" />
      </div>
      <Link
        to="/profile"
        className="btn btn_primary modal_btn"
        disabled={this.state.isUploading}
      >Готово</Link>
      </>
    );
  }
}

export default connect(state => ({ user: state.user }))(Document);
