import React, { lazy, Suspense } from 'react';

import { simulateModalLayout } from 'utils/Helpers';
import api from 'controllers/Api';
import history from 'controllers/History';
import { RECEIPT_STATUSES } from 'utils/Constants';

import { ACT_ROUTE, RECEIPT_ROUTE } from './constants';

class Wrapper extends React.Component {
  state = {
    isLoading: true,
    data: {},
    route: ACT_ROUTE,
  };

  routes = {
    Act: lazy(() => import('./screens/Act')),
    Receipt: lazy(() => import('./screens/Receipt')),
  };

  actions = {
    navigate: (route) => this.setState({ route }),
    setReceiptStatus: (status) =>
      this.setState({
        ...this.state,
        data: { ...this.state.data, receipt_status: status },
      }),
  };

  componentDidMount() {
    simulateModalLayout();
    const { route } = this.state;

    // history state
    const { state: historyState } = this.props.location;
    if (historyState?.data) {
      this.setState({
        data: historyState.data,
        isLoading: false,
        route: checkRoute(
          route,
          historyState.data.receipt_status,
          historyState.data.receipt
        ),
      });
    }

    // актуализация данных
    const { id } = this.props.match.params;
    api.get(`/user/transaction/${id}`).then((r) => {
      this.setState({
        data: r.data,
        isLoading: false,
        route: checkRoute(route, r.data.receipt_status, r.data.receipt),
      });
    });
  }

  componentWillUnmount() {
    simulateModalLayout(false);
  }

  goBack = () => {
    const hasHistory = !!window.history.state;
    hasHistory ? history.goBack() : history.push('/profile');
  };

  render() {
    const { isLoading, data, route } = this.state;
    if (isLoading) return null;
    const Route = this.routes[route];
    return (
      <Suspense fallback={<div>Загрузка...</div>}>
        <Route data={data} actions={this.actions} />
      </Suspense>
    );
  }
}

function skipReceipt(receipt_status) {
  return [
    RECEIPT_STATUSES.BANK_RECEIPT,
    RECEIPT_STATUSES.BANK_PROCESSED,
  ].includes(receipt_status);
}

function checkRoute(route, receipt_status, receipt) {
  const receiptCondition =
    !receipt ||
    [
      RECEIPT_STATUSES.NEED_RECEIPT,
      RECEIPT_STATUSES.INCORRECT,
      RECEIPT_STATUSES.VOID,
    ].includes(receipt_status);

  return receiptCondition && !skipReceipt(receipt_status)
    ? RECEIPT_ROUTE
    : route;
}

export default Wrapper;
