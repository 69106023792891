import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatPrice } from 'utils/Helpers';
import Icons from 'utils/Icons';
import Modal2 from 'components/Modal2';

const TransactionWithdraw = ({ data }) => {
  const { _id, payment_status: status, value, createdAt } = data;
  const date = moment(createdAt).format('DD.MM.YYYY, в HH:mm');

  let isProcessing = false;
  let isRejected = false;

  switch (status) {
    case 'new':
    case 'synced': {
      isProcessing = true;
      break;
    }
    case 'rejected': {
      isRejected = true;
      break;
    }
    case 'done':
    default:
  }

  const classes = classNames({
    'transactions-history_item': true,
    pv2: true,
    bb: true,
    'transactions-history_item-action-required': isRejected,
    'transactions-history_item-processing': isProcessing,
    'transactions-history_item-ok': !isProcessing && !isRejected,
  });

  const onClick = () => Modal2('ModalTransactionInfo', { data });

  return (
    <div
      role="button"
      tabIndex={0}
      key={_id}
      className={classes}
      onClick={onClick}
    >
      <div className="flex mb1">
        <span className="color_gray">{date}</span>
        <span className="transactions-history_item-amount mla color_red">
          - {formatPrice(Math.abs(value))}
          {isProcessing && Icons.clock}
          {isRejected && Icons.stopSign}
        </span>
      </div>
      <div>{data.title}</div>
      {isProcessing && (
        <div className="color_gray mt1">
          <span>В обработке</span>
        </div>
      )}
      {isRejected && (
        <div className="color_red mt1">
          <span>Отказано</span>
        </div>
      )}
    </div>
  );
};

TransactionWithdraw.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    payment_status: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default TransactionWithdraw;
