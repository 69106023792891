import React from 'react';
import moment from 'moment';

import api from 'controllers/Api';
import { simulateModalLayout } from 'utils/Helpers';
import history from 'controllers/History';
import { Header } from 'components/Modal2';

import WorkerCard from './WorkerCard';

class WorkerPage extends React.Component {

  state = {
    data: null,
    isLoading: true
  }

  fetch = (id) => {
    const date = this.props.match?.params?.date || moment().format('YYYY-MM-DD');

    api.get(`/order/${id}/${date}`)
    .then(r => {
      this.setState({
        data: r.data,
        isLoading: false,
      });
//      simulateModalLayout();
    })
    .catch(e => {
      this.setState({
        data: null,
        isLoading: false,
      });
    });
  }

  componentDidMount = () => {
    const id = this.props.match?.params?.id;
    this.fetch(id);
    simulateModalLayout();
  }

  componentWillUnmount = () => {
    simulateModalLayout(false);
  }

  handleAction = (action, data) => {
    const orderid = this.props.match?.params?.id;
    const jobid = this.props.match?.params?.jobid;

    api
      .post(`/order/${jobid}/${action}`, data)
      .then(r => {
        this.fetch(orderid);
      })
      .catch(e => {
        console.error('action error', e.response.data);
        alert(e.response.data.error);
      });
  }

  goBack = () => {
    const hasHistory = !!window.history.state;
    const { id, date } = this.props.match?.params;
    hasHistory ? history.goBack() : history.push(`/orders/${id}/${date}`);
  }

  render() {
    const { jobid } = this.props.match?.params;
    const { id, date } = this.props.match?.params;
    const { data, isLoading } = this.state;
    return (
      <>
        <Header title="Карточка исполнителя" close={this.goBack} />
        <div>
          {!isLoading && <WorkerCard data={data} orderUrl={`/orders/${id}/${date}`} jobid={jobid} handleAction={this.handleAction} />}
        </div>
      </>
    );
  }
}


export default WorkerPage;
