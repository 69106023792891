import React from 'react';
import { Link } from 'react-router-dom';
import TagsController from 'controllers/Tags';

const SELF_EMPLOYED_HINT_URL =
  'https://mygig.zendesk.com/hc/ru/articles/360007583318-%D0%A7%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-%D1%81%D1%82%D0%B0%D1%82%D1%83%D1%81-%D1%81%D0%B0%D0%BC%D0%BE%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%BE%D0%B3%D0%BE-';

const SelfEmployedConfirmationAlert = () => {
  const onConfirm = () => {
    TagsController.event('Buttons', 'ConfirmStatus', 'BookingJob');
  };

  return (
    <div className="content_block bg_yellow">
      <div className="content_block-title color_black mb2">Внимание!</div>
      <p>
        Для вывода средств на карту нужно подтвердить статус самозанятого
        гражданина.
      </p>
      <div className="flex flex-end">
        <a
          href={SELF_EMPLOYED_HINT_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn_small btn_text"
        >
          Что это такое?
        </a>
        <Link
          onClick={onConfirm}
          to="/profile/self-employed-confirmation"
          className="btn btn_small btn_blue"
        >
          Подтвердить
        </Link>
      </div>
    </div>
  );
};

export default SelfEmployedConfirmationAlert;
