import React from 'react';
import { baseURL } from 'controllers/Api';
import moment from 'moment';
// import Item from './components/Item';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { formatName } from 'utils/Helpers';

class Chats extends React.Component {

  renderChats = () => {
    const { list } = this.props.chats;
    if(!list.length) return <div className="p2">Сообщений нет.</div>;
    return list.map(item => {
      const activeClass = item.isActive ? '' : 'chats-list_contact-inactive';
      const isMyMsg = item.message.sender !== item.user._id;
      const image = item.workplace ? `/client/${item.workplace.client}` : `/user/${item.user._id}/avatar`;
      return (
        <Link key={item.user._id} to={ `/chats/${item.user._id}` } className={`chats-list_contact bb p2 ${activeClass}`}>
          <span className="chats-list_contact-avatar"
               style={{ backgroundImage: `url(${baseURL}${image})` }}>
          </span>
          <div className="chats-list_contact-details">
            <div className="flex">
              <strong className="chats-list_contact-name pb1">
                {formatName(item.user)}
              </strong>
              <span className="chats-list_contact-latest-message-time mla">
                {moment(item.message.createdAt).calendar()}
              </span>
            </div>
            <div className="flex">
              <span className="chats-list_contact-latest-message">
                {`${isMyMsg ? 'Вы: ' : ''}${item.message.text}`}
              </span>
              {item.unreadMessagesCount ? <span className="bubble_count mla">{item.unreadMessagesCount}</span> : null}
            </div>
          </div>
        </Link>
      );
    });
  }

  render() {
    return (
      <>
        <h1 className="p2 mt1">Сообщения</h1>
        <hr className="mb0" />
        <div>
          {this.renderChats()}
        </div>
      </>
    );
  }

}

export default connect(state => ({ chats: state.chats }))(Chats);
