import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Header } from 'components/Modal2';

import Login from './components/Login';

const ModalAuth = ({ close, data }) => {
  useEffect(() => {
    // это было тут закомментировано - пусть тут и остаётся :P
    // Modal2(`ModalLegalPrivacyPolicy`);
  }, []);

  return (
    <>
      <Header close={close} />
      <div className="p2 pt3">
        <div className="alert_header flex mb3">
          <div className="alert_title">Авторизация</div>
          <span role="img" aria-label="success" className="alert_icon">
            👋🏼
          </span>
        </div>
        <Login close={close} data={data} />
      </div>
    </>
  );
};

ModalAuth.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.objectOf({
    callback: PropTypes.func,
  }),
};

ModalAuth.defaultProps = {
  data: {},
};

export default ModalAuth;
