import React from 'react';
import PropTypes from 'prop-types';

import Preloader from 'components/Preloader';

import Day from './Day';

const List = ({ loading, data, status }) => {
  const renderItems = () => {
    const entries = Object.entries(data);

    return (
      <div style={{ height: 'calc(100vh - 191px)', overflowY: 'auto' }}>
        {entries.map((entry) => {
          const date = entry[0];
          const items = entry[1];

          return <Day key={date} items={items} date={date} />;
        })}
      </div>
    );
  };

  const { length } = Object.entries(data);
  const listClass = `orders_list ${
    status === 'inactive' ? 'orders_list-inactive' : ''
  }`;

  if (!loading && !length) {
    return (
      <div className="layout_tall">
        <div className="empty_state">
          <span role="img" aria-label="empty" className="empty_state-icon">
            🤷‍♂️
          </span>
          <div className="empty_state-title">
            Нет {status === 'active' ? 'актуальных' : 'завершённых'} заказов
          </div>
          <div className="empty_state-text">
            Создайте заказ, чтобы найти <br /> новых исполнителей
          </div>
        </div>
      </div>
    );
  }

  return loading ? (
    <Preloader className="layout_tall" />
  ) : (
    <div className={listClass}>{renderItems()}</div>
  );
};

List.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.array),
  status: PropTypes.string.isRequired,
};

List.defaultProps = {
  loading: false,
  data: {},
};

export default List;
