import React from 'react';
import Worker from './worker';

function WorkersList (props) {
  const { order, onClickItem } = props;

  const list = (new Array(order.amount)).fill({});
  for (let i=0; i<order.inwork.length; i++) {
    list[i] = order.inwork[i];
  }

  return list.length ? (
    <div className="workers-list mt2">
      {list.map((item, index) =>
        item.worker
        ? <Worker key={item.id} job={item} status={item.status} onClickItem={onClickItem} />
        : <Worker key={`worker${index}`} />
      )}
    </div>
  ) : null;
}

export default WorkersList;
