import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { dateToText } from 'utils/Helpers';

import Context from '../context';

const visibleItemsCount = 6;

const Schedule = ({ data, disableSelectDate }) => {
  const { onDateChange } = useContext(Context);
  const { id: paramId } = useParams();
  const [isExpanded, setExpanded] = useState(false);
  const [active, setActive] = useState(paramId);

  const { datetimes } = data ?? {};

  const onClickItem = (e) => {
    const { id } = e.currentTarget.dataset;
    if (disableSelectDate === true) return;
    setActive(id);
    if (onDateChange) onDateChange(id);
  };

  return (
    <>
      <div className="job_page-schedule mb2">
        <div className="tag_wrapper">
          {datetimes ? (
            (isExpanded
              ? datetimes
              : datetimes.slice(0, visibleItemsCount)
            ).map(({ start, finish, id, is_night: night }) => {
              const text = dateToText(start);
              const startT = dateToText(start, 'HH:mm');
              const finishT = dateToText(finish, 'HH:mm');

              const isActive = active === id;

              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={`tag ${isActive ? 'tag--black' : ''}`}
                  key={id}
                  onClick={onClickItem}
                  data-date={start}
                  data-id={id}
                  data-active={+isActive}
                >
                  {text}, {night && '🌙'} {startT} — {finishT}
                </div>
              );
            })
          ) : (
            <div className="color_red">Нет доступных дат</div>
          )}
        </div>
        {datetimes && datetimes.length > visibleItemsCount && (
          <span
            role="button"
            tabIndex={0}
            className="btn_expand"
            onClick={() => setExpanded((v) => !v)}
          >
            {isExpanded ? 'Скрыть' : 'Показать ещё'}
          </span>
        )}
      </div>
    </>
  );
};

Schedule.propTypes = {
  data: PropTypes.shape({
    datetimes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        start: PropTypes.string.isRequired,
        finish: PropTypes.string.isRequired,
        is_night: PropTypes.bool,
      }),
    ),
  }).isRequired,
  disableSelectDate: PropTypes.bool,
};

Schedule.defaultProps = {
  disableSelectDate: false,
};

export default Schedule;
