import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { baseURL } from 'controllers/Api';
import {dateToTextLong, formatPhoneNumber, formatDuration, formatPrice, isDesktop, decl, isCheckinTime} from 'utils/Helpers';
import { Link } from 'react-router-dom';
import starEmpty from './star_empty.svg';
import starFull from './star_full.svg';
import starHalf from './star_half.svg';
import Modal2 from 'components/Modal2';

class WorkerCard extends React.Component {
  renderEvents = (job, data) => {
    return job.events?.map(event => {
      const time = moment(event.createdAt).format('HH:mm');
      const text = getEventText(event, data, job);
      if (!text) return null;
      return (
        <div className='worker-card_event' key={event.createdAt}>
          <strong className='worker-card_event-time'>{time}</strong>
          <span className='worker-card_event-time'>{text}.</span>
        </div>
      );
    });
  }


  renderActions = (job, data) => {
    const { handleAction } = this.props;

    const extend = (hours) => handleAction('extend', { hours });
    const showExtendModal = () => Modal2('ModalExtendJob', { extend, max: job.max_extend_hours });
    const canExtend = job.max_extend_hours > 0;
    const isCheckin = isCheckinTime(job.start);

    switch (job.status) {
      case 'booked':
      case 'waiting':
      case 'going': return (
        <>
          { isCheckin && <div className='btn btn_small btn_primary' onClick={() => handleAction('checkin')}>Исполнитель на месте</div> }
        </>
      );
      case 'delayed': return (
        <div>
          <div className='btn btn_small btn_outline' onClick={() => handleAction('notwait')}>Отменить</div>
          <div className='btn btn_small btn_primary' onClick={() => handleAction('wait')}>Подождать {data.delay && `${data.delay} мин.`}</div>
        </div>
      );
      case 'checkingin': return (
        <div>
          <div className='btn btn_small btn_outline' onClick={() => handleAction('cancel')}>Не пришёл</div>
          { isCheckin && <div className='btn btn_small btn_primary' onClick={() => handleAction('checkin')}>Исполнитель на месте</div> }
        </div>
      );
      case 'inprogress': return (
        <div>
          {canExtend && <div className='btn btn_small btn_outline' onClick={showExtendModal}>Продлить смену</div> }
          <div className='btn btn_small btn_primary' onClick={() => handleAction('confirm')}>Закончить смену</div>
        </div>
      );
      case 'checkingout':
      case 'completed': return (
        <div>
          <div className='btn btn_small btn_primary' onClick={() => handleAction('confirm')}>Закончить смену</div>
        </div>
      );
      case 'confirmed': return job.reviews.length ? null : (
        // переход с карточки работника на страницу отзыва
        <Link to={`/orders/${data.id}/${job.date}/${job.id}/${job.worker._id}`} className='btn btn_small btn_primary'>Оставить отзыв</Link>
      );
      case 'failed': return new Date(job.finish) < new Date() ? null : (
        <div>
          <div className='btn btn_small btn_primary' onClick={() => handleAction('restore')}>Возобновить заявку</div>
        </div>
      );
      default: return null;
    }
  }


  renderDocuments = (job) => {
    if (![
      'booked',
      'going',
      'delayed',
      'waiting',
      'checkingin',
      'inprogress',
      'checkingout',
      'completed'
    ].includes(job.status)) return 'Доступны только для действующей подработки.';

    const docs = job.documents?.map(document => {
      const pages = document.pages.map(page =>
        <img src={`${baseURL}${page.url}`} alt={document.doctype} key={page.id} width='100%' />
      );
      return <div key={document.doctype}>{pages}</div>
    });
    return docs;
  }


  renderMessageCall = (job) => {
    if ([
      'completed',
      'confirmed',
      'cancelled',
      'expired',
      'failed',
      'disputed',
      'refunded'
    ].includes(job.status)) {
      // return null;
    }
    const phone = job.worker.user.phone;
    const phoneBtnText = isDesktop() ? formatPhoneNumber(phone) : `Позвонить`;

    let chatBtnText = `Написать`;
    const chat = this.props.chats.list.find(item => item.user._id === job.worker.user._id);
    if(chat && chat.unreadMessagesCount) {
      const countText = decl(chat.unreadMessagesCount, ['новое', 'новых', 'новые']);
      chatBtnText = `${chat.unreadMessagesCount} ${countText} сообщений`;
    }

    return(
      <div className="flex_btns mt2">
        <Link to={{ pathname: `/chats/${job.worker.user._id}`, state: { fromCard: true } }} className="btn btn_small">{chatBtnText}</Link>
        <a href={`tel:+${phone}`} className="btn btn_small btn_green btn_outline">{phoneBtnText}</a>
      </div>
    );
  }


  renderRating = (job) => {
    const { rating } = job;
    if (!rating) return;

    const stars = [];
    for (let i=1; i<6; i++) {
      let star = starEmpty;
      if (rating >= i-0.8) star = starHalf;
      if (rating >= i-0.2) star = starFull;
      stars.push(star);
    }
    const starsImg = stars.map((star,i) => <img key={`s${i}`} src={star} alt=""/>);
    const ratingText = `${rating}${rating.toString().length === 1 ? '.0' : ''}`;

    return <div className="worker-card_rating flex flex_center">
      <strong className="mr1">{ratingText}</strong>
      {starsImg}
    </div>
  }

  onClickSupport = () => {
    Modal2('ModalEmployerSupport', { job: this.props.jobid });
  }

  render = () => {
    const { data, jobid, orderUrl } = this.props;
    const { role } = this.props.user;
    const job = data.inwork.find(item => item.id === jobid);
    if (!job) return <h1 className="p2">404</h1>

    const { user } = job.worker;
    const { custom_name } = data;
    const { start_time_fact, finish_time_fact, start_time, finish_time, payment_per_job_fact, minutes_fact } = job;

    const avatar = `${baseURL}/user/${user._id}/avatar`;
    const userStatus = getEventText(job.events.slice(-1)[0], data, job);

    const datetime = `${dateToTextLong(job.date)}, ${start_time_fact || start_time} - ${finish_time_fact || finish_time}`;
    const salary = `${formatPrice(payment_per_job_fact)} ₽ / ${formatDuration(minutes_fact)}`;

    return (
      <>
        <div className="">
          <div className="content_block">
            <div className="worker-card_worker p2">
              <div className="worker-card_worker-img" style={{ backgroundImage: `url(${avatar})` }}></div>
              <div className="worker-card_worker-name">{user.firstname}<br/>{user.lastname}</div>
              <div className="worker-card_worker-status">{userStatus}</div>
              <div className="worker-card_worker-phone">
                Тел: <span style={{ color: 'black' }}>+{user.phone}</span>
              </div>
            </div>
            {this.renderRating(job)}
            {role === 'employer' && this.renderMessageCall(job)}
          </div>
          <div className="content_block">
            <div className="content_block-header mb2">
              <div className="content_block-title">Смена</div>
            </div>
            <p>
              <Link to={orderUrl} className="worker-card_spec">{custom_name}</Link>
            </p>
            <span className="tag">{datetime}</span>
            <span className="tag">{salary}</span>
            <div className="worker-card_events pt2 mt2">
              {this.renderEvents(job, data)}
            </div>
            <div className="worker-card_actions">
              {this.renderActions(job, data)}
            </div>
          </div>
          <div className="content_block">
            <div className="content_block-header mb2">
              <div className="content_block-title">Документы</div>
            </div>
            <div className="worker-card_documents">
              {this.renderDocuments(job)}
            </div>
          </div>
          <div className="content_block">
            <div className="btn btn_fullwidth" onClick={this.onClickSupport}>Есть проблема</div>
          </div>
        </div>
      </>
    );
  }
}

export function getEventText (event, data, job) {
  if (!event) return null;
  switch (event.event_status) {
    case 'docchecked': return 'Подтвердил документы';
    case 'booked': return 'Забронировал подработку';
    case 'going': return 'Подтвердил бронирование';
    // МБ стоит сделать humanize для delay?
    case 'delayed': return <span>Исполнитель опаздывает на <b>{job.delay} мин</b>{job.delay_reason && `: "${job.delay_reason}"`}</span>;
    case 'waiting': return <span>Ждём исполнителя в <b>{moment(job.start).add(job.delay, 'm').format('HH:mm')}</b></span>;
    case 'toolate': return 'Заявка отменена';
    case 'inprogress': return 'Начал смену';
    case 'extended': return event.text;
    case 'completed': return 'Закончил работать';
    case 'confirmed': return 'Выполнение работы подтверждено';
    case 'cancelled': return 'Заявка отменена';
    // case 'failed': return `Исполнитель отказался от подработки: ${data.failure_reason}`;
    case 'failed': return `Похоже, исполнитель не пришёл. Заявка отменена`;
    case 'checkingin': return `Исполнитель хочет указать начало работы. Пожалуйста, подтвердите, что он пришёл`;
    case 'checkingout': return `Исполнитель хочет указать окончание работы. Пожалуйста, подтвердите выполнение`;

    default: return event.event_status;
  }
}


export default connect(state => ({ chats: state.chats, user: state.user }))(WorkerCard);
