import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import Loading from 'react-fullscreen-loading';
import { useSwipeable } from 'react-swipeable';

import Icons from 'utils/Icons';
import api from 'controllers/Api';
import { Events, simulateModalLayout } from 'utils/Helpers';
import Modal2 from 'components/Modal2';

import Map from './components/Map';
import Header from './components/Header';
import Details from './components/Details';
import Dates from './components/Dates';
import Salary from './components/Salary';
import Documents from './components/Documents';
import Context from './context';
import DocumentsForSignature from './components/DocumentsForSignature';

const Job = () => {
  const history = useHistory();
  const location = useLocation();
  const { id: orderId } = useParams();

  const mobileTeam = useSelector((state) => state.user.mobile_team);
  const isAuthorized = useSelector((state) => state.user.isAuthorized);

  const [job, setJob] = useState({});
  const [jobs, setJobs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const similar = location?.state?.similar;
  const [curIdx, setCurIdx] = useState(0);

  const handlers = useSwipeable({
    onSwipedRight: () =>
      setCurIdx((curIdx - 1 + similar.length) % similar.length),
    onSwipedLeft: () => setCurIdx((curIdx + 1) % similar.length),
    trackMouse: true,
    swipeDuration: 500,
  });

  useEffect(() => {
    if (!jobs) return;
    setJob(jobs[curIdx]);
  }, [curIdx, jobs]);

  const getOrder = (id) => {
    return api.get(`/search/order/${id}?others=true&merge=true`);
  };

  const onError = useCallback(
    (error = 'Подработка не найдена') => {
      simulateModalLayout(false);
      toast(error, {
        position: 'top-center',
        type: 'error',
        autoClose: 2000,
      });
      history.push(`/`);
    },
    [history],
  );

  const fetch = useCallback(async () => {
    try {
      if (similar) {
        const res = await Promise.all(similar.map((o) => getOrder(o.id)));
        setJobs(res.map((r) => r.data));
        setJob(res[0].data);
        simulateModalLayout();
        setIsLoading(false);
        return;
      }
      const r = await getOrder(orderId);
      setJob(r.data);
      simulateModalLayout();
      setIsLoading(false);
    } catch (e) {
      onError();
    }
  }, [onError, orderId, similar]);

  const onDateChange = (id) => {
    if (!job?.datetimes?.find((d) => d.id === id)) return;

    getOrder(id)
      .then(({ data: nextJob }) => {
        simulateModalLayout();
        setJob((prev) => ({ ...prev, ...nextJob }));
      })
      .catch(() => {
        onError();
      });
  };

  const onClickTakeJob = (payload) => {
    const selectedDateElement =
      document.querySelectorAll(`[data-active="1"]`)[0];
    if (!selectedDateElement) {
      toast('Не выбрана дата подработки');
      return;
    }

    const { date } = selectedDateElement.dataset;

    const open = () => Modal2('ModalTakeJob', { data: payload, date });
    if (!isAuthorized) {
      Events.dispatch('MODAL_SHOW-ModalAuth', { callback: open });
    } else {
      open();
    }
  };

  const onClickSendRequestOnInterview = async () => {
    const open = () => Modal2('ModalInterview', { data: job });
    if (!isAuthorized) {
      Events.dispatch('MODAL_SHOW-ModalAuth', { callback: open });
    } else {
      open();
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(
    () => () => {
      simulateModalLayout(false);
    },
    [],
  );

  const needInterview = job.type === 'regular' && !job.has_success_interview;

  const text = needInterview ? 'Заявка на собеседование' : 'Взять подработку';

  if (isLoading) return <Loading loading loaderColor="#F7CE17" />;

  return (
    <Context.Provider value={{ onDateChange }}>
      <Formik
        enableReinitialize
        initialValues={job}
        onSubmit={
          !needInterview ? onClickTakeJob : onClickSendRequestOnInterview
        }
      >
        {({ values }) => (
          <Form>
            <div className="job_page" {...handlers}>
              <Link to="/" className="modal_close">
                {Icons.close}
              </Link>
              <Map data={values} ignoreDatetimes />
              {jobs && (
                <div className="job_page__nav">
                  <button
                    type="button"
                    className="btn btn_text"
                    onClick={() =>
                      setCurIdx((curIdx - 1 + similar.length) % similar.length)
                    }
                  >
                    {/* Prev */}
                  </button>
                  <span className="job_page__nav-cur">
                    {Array.from({ length: similar.length }, (_, i) => (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <div
                        className={classNames('job_page__nav-dot', {
                          'job_page__nav-dot--active': i === curIdx,
                        })}
                        onClick={() => {
                          setCurIdx(i);
                          setJob(jobs[i]);
                        }}
                      />
                    ))}
                  </span>
                  <button
                    type="button"
                    className="btn btn_text"
                    onClick={() =>
                      setCurIdx((prev) => (prev + 1) % similar.length)
                    }
                  >
                    {/* Next */}
                  </button>
                </div>
              )}
              <div className="job_page-container">
                {/* XXX: костыль */}
                <Header data={values} onFetch={fetch} />
                <Dates data={values} />
                <Details data={values} />
                {!mobileTeam && <Salary data={values} ignoreDatetimes />}
                <Documents data={values} />
                <DocumentsForSignature
                  documents={values.documents_for_signature}
                />
                {!values.is_active && (
                  <div className="content_block">
                    <Link to="/" className="btn btn_fullwidth">
                      Похожие предложения
                    </Link>
                  </div>
                )}
              </div>
              {values.is_active && (
                <>
                  <div className="modal_btn-holder" />
                  <button
                    type="submit"
                    className={classNames(
                      'btn',
                      'modal_btn',
                      { btn_regular: values.type === 'regular' },
                      { btn_primary: values.type !== 'regular' },
                    )}
                  >
                    {text}
                  </button>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Context.Provider>
  );
};

export default Job;
