import store from 'reducers';
import qs from 'qs';

const FilterController = {
  toggleFilter: (_filter, value) => {
    const { activeFilters, otherSearchParams } = store.getState().jobs;
    const active = JSON.parse(JSON.stringify(activeFilters));

    const isArray = _filter.includes('[]');
    const filter = isArray ? _filter.replace('[]', '') : _filter;

    if(isArray) {
      if(!active[filter]) {
        active[filter] = [value];
      } else {
        if(active[filter].includes(value)) {
          active[filter] = active[filter].filter(id => id !== value);
          if(!active[filter].length) delete active[filter];
        } else {
          active[filter].push(value);
        }
      }
    } else {
      if(active[filter]) {
        delete active[filter];
      } else {
        active[filter] = value;
      }
    }

    store.dispatch({
      type: 'JOBS_FILTER_SET',
      params: {
        activeFilters: active,
        otherSearchParams,
      },
    });
  },

  removeFilterValue: (_filter, value) => {

    const { activeFilters, otherSearchParams } = store.getState().jobs;
    const active = JSON.parse(JSON.stringify(activeFilters));

    const isArray = _filter.includes('[]');
    const filter = isArray ? _filter.replace('[]', '') : _filter;

    if(active[filter] && active[filter].includes(value)) {
      active[filter] = active[filter].filter(v => v !== value);
      store.dispatch({
        type: 'JOBS_FILTER_SET',
        params: {
          activeFilters: active,
          otherSearchParams,
        },
      });
    }

  },

  clearFilter: (filter) => {
    const { activeFilters, otherSearchParams } = store.getState().jobs;
    const active = JSON.parse(JSON.stringify(activeFilters));
    delete active[filter];
    store.dispatch({
      type: 'JOBS_FILTER_SET',
      params: {
        activeFilters: active,
        otherSearchParams,
      },    });
  },
  clear: () => {
    const { otherSearchParams } = store.getState().jobs;

    store.dispatch({
      type: 'JOBS_FILTER_SET',
      params: {
        activeFilters: {},
        otherSearchParams,
      },    });
  },

  parse: () => {
    const search = qs.parse(document.location.search.substr(1), {      plainObjects: true,
      depth: -2,
      ignoreQueryPrefix: true,
      allowDots: true,
      parseArrays: false
    });

    const filters = { q: {}};
    const otherParams = {};

    for (let key in search) {
      const qIndex = key.indexOf('q');
      if (qIndex === 0) {
        const splitKey = key.split('q');
        filters.q[splitKey[1]] = search[key];
      } else {
        otherParams[key] = search[key];
      }
    }

    if(!filters['q']) return {};
    const keys = Object.keys(filters['q']);
    keys.forEach(key => {
      const filter = key.split('][')[0].replace('[', '');
      let type = key.split('][')[1].replace(']', '');
      const value =  filters['q'][key];
      switch(type) {
        case 'in':
          filters[filter] = value.split(',');
          break;
        default:
          filters[filter] = value;
      }
      if (filter === 'spec') {
        filters.profession = filters.spec;
        delete filters.spec;
      }
    })
    delete filters['q']

    return { filters, otherParams };
  },

  prepare: () => {
    const { activeFilters: values, otherSearchParams } = store.getState().jobs;

    const preparedFilters = {};
    const keys = Object.keys(values);
    keys.forEach(key => {
      let v = values[key];
      let k = key;

      // custom renames
      if(key === 'profession') k = 'spec';

      const isArray = Array.isArray(v);
      if(isArray) {
        preparedFilters[`q[${k}][in]`] = v.join(',');
        return;
      }

      switch (key) {
        case 'name':
          preparedFilters[`q[${k}][like]`] = v;
          break;
        default:
          preparedFilters[`q[${k}][equal]`] = v;
      }
    });

    return { preparedFilters, otherSearchParams };
  },

  toUrl: (filters) => qs.stringify(filters, { encode: false }),

  actions: {

    'JOBS_FILTER_SET': (action, state) => {
      const activeFilters = action.params.activeFilters;
      const otherSearchParams = action.params.otherSearchParams;
      return {
        ...state,
        otherSearchParams,
        activeFilters,
        activeFavoriteFilterId: null,
      };
    },

    'JOBS_FILTER_SET_TEXT': (action, state) => {
      const { value } = action.params;
      let activeFilters = {
        ...state.activeFilters,
        text: value
      };
      // if(!value) delete activeFilters.text;
      return {
        ...state,
        activeFilters: { ...activeFilters },
        activeFavoriteFilterId: null,
      };
    },

    'JOBS_TOTALCOUNT_SET': (action, state) => {
      return {
        ...state,
        totalCount: action.params,
      };
    },

    'JOBS_FILTER_FAVORITE_ADD': (action, state) => {
      const { id, any } = action.params;
      const { favoriteFilters } = state;
      favoriteFilters.push({ id, any });
      return {
        ...state,
        activeFavoriteFilterId: id,
        favoriteFilters,
      }
    },

    'JOBS_FILTER_FAVORITE_UPDATE': (action, state) => {
      const { id, is_subscribed } = action.params;
      const { favoriteFilters } = state;
      const index = favoriteFilters.findIndex(v => v.id === id);
      favoriteFilters[index].is_subscribed = is_subscribed;
      return {
        ...state,
        favoriteFilters,
      }
    },

    'SEARCH_DATA_SET': (action, state) => {
      return {
        ...state,
        ...action.params,
      }
    },

    'SEARCH_CACHE_LOAD': (action, state) => {
      // silent
      Object.keys(action.params).forEach(key => {
        state[key] = action.params[key];
      })
      return state;
    }


  },
}

export default FilterController;
