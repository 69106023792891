import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TALKBANK_INSTRUCTIONS_LINK, TALKBANK_STATUSES } from 'utils/Constants';

import { openFNS } from 'utils/Helpers';
import UserController from 'controllers/User';

const TalkBankConnectBanner = () => {
  const status = useSelector((state) => state.user.talkBankStatus);

  useEffect(() => {
    // eslint-disable-next-line no-console
    UserController.getTalkBankStatus().catch(console.error);
  }, []);

  if (status !== TALKBANK_STATUSES.UNBOUND) return null;

  return (
    <div className="content_block bg_blue color_white">
      <div className="content_block-title-alt color_white mb2">
        <strong>Подключите Talkbank</strong>
      </div>

      <p className="mb2">
        Оплата за некоторые подработки переводится через Talkbank. Чтобы мы
        могли провести оплату, настройте права в Моём Налоге.
        <br />
      </p>
      <div className="flex flex_center flex_between">
        <a
          href={TALKBANK_INSTRUCTIONS_LINK}
          target="_blank"
          className="color_white underline"
          style={{ marginRight: '.6rem', flex: 1 }}
          rel="noopener noreferrer"
        >
          Как настроить Мой Налог
        </a>
        <div
          role="button"
          tabIndex={0}
          className="btn btn_small"
          onClick={openFNS}
        >
          Перейти в Мой налог
        </div>
      </div>
    </div>
  );
};

export default TalkBankConnectBanner;
