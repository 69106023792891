import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Header } from 'components/Modal2';
import api from 'controllers/Api';
import { useErrorHandler } from 'utils/Hooks';
import store from 'reducers';
import TagsController from 'controllers/Tags';

const CITIZENSHIPS = [
  { caption: 'Россия', value: 'ru' },
  { caption: 'Армения', value: 'am' },
  { caption: 'Белоруссия', value: 'by' },
  { caption: 'Казахстан', value: 'kz' },
  { caption: 'Киргизия', value: 'kg' },
  { caption: 'Другое', value: 'other' }
];

const STATUSES = [
  { caption: 'Самозанятый', value: 'self_employed' },
  { caption: 'ИП', value: 'individual' },
  { caption: 'Нет особого статуса', value: 'none' },
];

const DEFAULTS = {
  lastname: '',
  firstname: '',
  middlename: '',
  email: '',
  citizenship: 'ru',
  status: 'self_employed'
};

export default function Registration({ close }) {
  const { errors, handleError, resetErrors } = useErrorHandler();

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      resetErrors();

      await api.post('/user/register', values);

      TagsController.event('FormSend', 'SaveName', 'SignIn');

      store.dispatch({
        type: 'USER_SET',
        params: {
          firstname: values.firstname,
          lastname: values.lastname,
          middlename: values.middlename,
        }
      });

      close();
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }

  const dismiss = () => {
    close();
    TagsController.event('Buttons', 'SkipSave', 'SignIn');
  }

  const optionsMapper = ({ value, caption }) => <option key={value} value={value}>{caption}</option>
  const citizenshipOptionsPart = CITIZENSHIPS.map(optionsMapper);
  const statusOptionsPart = STATUSES.map(optionsMapper)

  return (
    <>
      <Header />
      <div>
        <div className="content_block">
          <div className="alert_header flex mb3">
            <div className="alert_title">Добро пожаловать в MyGig!</div>
            <span role="img" aria-label="congrats" className="alert_icon">😁</span>
          </div>
          <div className="mb3"><strong className="pbi3">Ещё пара вопросов:</strong></div>
          <Formik initialValues={DEFAULTS} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <>
                <Form>
                  <div className="form_group">
                    <Field placeholder="Фамилия" type="text" name="lastname" required />
                  </div>
                  <div className="form_group">
                    <Field placeholder="Имя" type="text" name="firstname" required />
                  </div>
                  <div className="form_group">
                    <Field placeholder="Отчество" type="text" name="middlename" required />
                  </div>
                  <div className="form_group">
                    <Field placeholder="Email" type="email" name="email" required />
                  </div>
                  <div className="form_group">
                    <Field component="select" name="citizenship">{citizenshipOptionsPart}</Field>
                  </div>
                  <div className="form_group">
                    <Field component="select" name="status">{statusOptionsPart}</Field>
                  </div>
                  {errors && <div className="form_error">{errors}</div>}
                  <button
                    className="btn btn_fullwidth mbi2"
                    disabled={isSubmitting}
                    onClick={dismiss}
                  >Пропустить</button>
                  <button
                    className="btn btn_primary btn_fullwidth"
                    disabled={isSubmitting}
                    type="submit"
                  >Сохранить</button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
