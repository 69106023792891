import React from 'react';
import api from 'controllers/Api';
import moment from 'moment';
import Item from './components/Item';
import { connect } from "react-redux";

var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

class Schedule extends React.Component {

  state = {
    data: [],
    isLoading: true,
    ref: React.createRef() // используется для обращения к актуальному элементу
  }

  componentDidMount = () => {
    this.fetch();
  }

  fetch = () => {
    const { isLoading } = this.state;
    if(!isLoading) this.setState({ isLoading: true });
    api.get('/schedules', { params: { limit: 1000 } })
    .then(response => {
      this.setState({
        data: this.prepareData(response.data), //this.prepareData([ ...response.data, ...test]), //test, //response.data,
        isLoading: false
      });
      if (this.state.ref.current) {
        this.state.ref.current.scrollIntoView({behavior: "smooth"}) // ереводим на актуальный день
      }
    });
  }

  prepareData = (data) => {
    if(!data.length) return [];
    const today  = moment().utcOffset(180).startOf('day').unix();

    // сортировка и временные ключи
    let tmp = data.map(item => {
      const date = moment(item.start).utcOffset(180).startOf('day');
      item.timestamp = date.unix();
      return item;
    }).sort((a, b) => a.timestamp - b.timestamp);

    // проставляем флаг актуальности (для пересылки на этот день)
    const actualDayIndex = tmp.findIndex(item => moment(item.start).isAfter());
    tmp[actualDayIndex > -1 ? actualDayIndex : (tmp.length - 1)].actual = true;

    // группировка в объект по timestamp
    const tmpGrouppedByTimestamp = groupBy(tmp, 'timestamp');

    // перевод объекта в массив
    tmp = Object.keys(tmpGrouppedByTimestamp).map(timestamp => {
      const date = moment.unix(timestamp);
      return {
        timestamp: parseInt(timestamp),
        month: date.format('MMMM'),
        date: date.format('[<div>]ddd[</div>][<div>]DD[</div>]'),
        items: tmpGrouppedByTimestamp[timestamp],
      }
    });

    // пробелы между днями
    const timestamps = tmp.map(v => v.timestamp);
    timestamps.forEach((timestamp, i) => {
      if(today > timestamp) return;
      const nextTimestamp = timestamps[i+1];
      if(!nextTimestamp) return;
      const daysCount = Math.floor((nextTimestamp - timestamp) / 86400) - 1;
      if(daysCount < 1) return;
      const index = tmp.findIndex(v => v.timestamp === timestamp);
      let title = moment.unix(timestamp).add(1, 'days').format('DD MMMM');
      if(daysCount > 1) title = moment.unix(timestamp).add(1, 'days').format('DD') + ' - ' + moment.unix(nextTimestamp).subtract(1, 'days').format('DD MMMM');
      tmp.splice( index+1, 0, {
        type: 'freeDates',
        daysCount,
        title,
        items: Array(daysCount).fill(' '),
      });

      /* const index = calendar[monthIndex].items.findIndex(v => v.timestamp === timestamp);
      let title = moment.unix(timestamp).add(1, 'days').format('DD MMMM');
      if(daysCount > 1) title += ' - ' + moment.unix(nextTimestamp).subtract(1, 'days').format('DD MMMM');
      calendar[monthIndex].items.splice( index+1, 0, {
        daysCount,
        title,
        items: Array(daysCount).fill(' '),
      }); */
    });

    // тайтлы месяцов
    tmp.forEach((date, i) => {
      let nextDate = tmp[i+1];
      if(!nextDate?.month) nextDate = tmp[i+2];
      if(!nextDate || !date.month) return;
      if(date.month === nextDate.month) return;
      const index = tmp.findIndex(v => v.timestamp === nextDate.timestamp);
      tmp.splice( index, 0, {
        title: nextDate.month,
        type: 'monthName',
      });
    })

    // тайтл первого месяца
    tmp.splice( 0, 0, {
      title: tmp[0].month,
      type: 'monthName',
    });

    return tmp;
  }

  renderSchedule = () => {
    const { data } = this.state;
    const { mobile_team } = this.props.user;
    return data.map((date, i) => {
      if(date.type === 'freeDates') {
       return (
         <div className="schedule_list-freedates mb3 mt3">
           <div className="schedule_list-date"><div>...</div></div>
           <div>{date.title}</div>
         </div>
       );
      } else if(date.type === "monthName") {
        let className = `schedule_list-monthname mb3 color_gray`;
        if(i !== 0) className += ` mt3`;
        return (
          <div
            key={`${date.type}-${date.title}`}
            className={className}
            >
            {date.title}
          </div>
        );
      }

      const items = date.items.map(item => <Item refProp={item.actual ? this.state.ref : null} key={item.id} data={item} mobile_team={mobile_team} />);
      return (
        <div key={date.timestamp}>
          <div className="schedule_list-date" dangerouslySetInnerHTML={{ __html: date.date }} />
          <div className="schedule_list-items">
            {items}
          </div>
        </div>
      );
    });

    // return null;

    /*
    return Object.keys(calendar).map(timestamp => {
      const date = moment.unix(timestamp).format('[<div>]ddd[</div>][<div>]DD[</div>][<span></span>]');
      const items = calendar[timestamp].map(item => <Item key={item.id} data={item} />);
      return (
        <div key={timestamp}>
          <div className="schedule_list-date" dangerouslySetInnerHTML={{ __html: date }} />
          <div className="schedule_list-items">
            {timestamp}
            {items}
          </div>
        </div>
      );
    });
     */

  }

  // document.getElementsByClassName('__next')[0].offsetTop

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data, isLoading } = this.state;

    if(data.length && !isLoading) {
      setTimeout(() => {
        const offsetTop = document.getElementsByClassName('__next')[0]?.offsetTop;
        if(!offsetTop) return;
        document.querySelector('.layout').scrollTop = offsetTop + 40;
      }, 100);
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <h1 className="p2 mt1">Расписание</h1>
        <hr className="mb0" />
        <div className="schedule_list p2 pt3">
          {isLoading ? (
            <>
              <img alt="" src="/img/schedule-preloader.svg" key="fake-schedule-item-1" />
              <img alt="" src="/img/schedule-preloader.svg" key="fake-schedule-item-2" />
              <img alt="" src="/img/schedule-preloader.svg" key="fake-schedule-item-3" />
              <img alt="" src="/img/schedule-preloader.svg" key="fake-schedule-item-4" />
              <img alt="" src="/img/schedule-preloader.svg" key="fake-schedule-item-5" />
            </>
          ) : this.renderSchedule()}
        </div>
      </>
    );
  }

}

export default connect(state => {
  return {
    user: {
      mobile_team: state.user.mobile_team,
    }
  }
})(Schedule);