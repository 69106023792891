import React, { PureComponent } from 'react';

const randomPx = (min, max) => (Math.floor(Math.random() * (max - min + 1)) + min) + 'px';

export default class FakeJobCard extends PureComponent {

  state = {
    data: {
      name: randomPx(50, 120),
      date: randomPx(120, 150),
      study: randomPx(50, 90),
      payment: randomPx(80, 130),
      companyName: randomPx(100, 180),
      companyAddress: randomPx(120, 150),
    }
  }

  render() {
    const { data } = this.state;

    return (
      <div className="job_card p2">
        <div className="job_card-header flex mb2">
          <div className="job_card-name">
            <div className="fake fake-dark" style={{ width: data.name, height: 14 }}></div>
          </div>
          <div className="job_card-salary" style={{ minWidth: 40 }}>
          </div>
        </div>
        <div className="job_card-date flex mb2">
          <div className="fake" style={{ width: data.date, height: 24, marginBottom: 8 }}></div>
        </div>
        <div className="job_card-info flex mb2">
          <div className="fake" style={{ width: data.study }}></div>
          <div className="fake" style={{ width: data.payment }}></div>
        </div>
        <hr />
        <div className="job_card-company flex">
          <div className="job_card-company-image">
            <div className="fake" style={{ width: 31, height: 31, borderRadius: '100%' }}></div>
          </div>
          <div className="job_card-company-info">
            <div className="job_card-company-name">
              <div className="fake fake-dark" style={{ width: data.companyName }}></div>
            </div>
            <div className="job_card-company-address">
              <div className="fake" style={{ width: data.companyAddress, height: 10, minHeight: 10, marginTop: 8 }}></div>
            </div>
          </div>
          {/*<div className="job_card-company-favorite">
            <span onClick={this.onClickFavorite}>{isFavorite ? Icons.heartActive : Icons.heart}</span>
          </div> */}
        </div>

      </div>
    )


  }

}
