import moment from 'moment';
import store from 'reducers';
import Cookies from 'js-cookie';

import history from 'controllers/History';
import api from 'controllers/Api';
import { Events, pick } from 'utils/Helpers';
import { TALKBANK_STATUSES } from 'utils/Constants';
import Modal2 from 'components/Modal2';

import ChatsController from './Chats';
import TagsController from './Tags';

const { REACT_APP_DOMAIN } = process.env;
const cookieDomain = () =>
  document.location.hostname.includes('localhost')
    ? 'localhost'
    : `.${REACT_APP_DOMAIN}`;

const CHATS_POOL_TIMEOUT_MS = 60 * 1000;

// токены на test. не стираются

const profileFields = [
  'firstname',
  'lastname',
  'middlename',
  'phone',
  'balance',
  'role',
  'accept_agreement',
  'accept_oferta',
  'legals',
  'is_self_employed',
  'had_jobs',
  'inn',
  'birthday',
  'gender',
  'passport_series',
  'passport_number',
  'fns_connection_status',
  'is_fns_enabled',
  'citizenship',
  'hasTalkbankJob',
  'hasRaiffeisenJob',
];

const UserController = {
  check: async () => {
    const { token } = Cookies.get() || {};
    if (!token) {
      store.dispatch({
        type: 'USER_SET',
        params: {
          isAuthorized: false,
        },
      });
      return;
    }

    const profile = await UserController.getProfile();

    if (
      profile &&
      profile.role === 'worker' &&
      Object.values(profile.legals).some((v) => !v)
    ) {
      Modal2('ModalAcceptLegals');
    }

    const { role } = Cookies.get();
    if (role === 'worker') {
      const documents = await UserController.getDocuments();
      TagsController.populateWorker(profile, documents);
    } else {
      const workplaces = await api
        // TODO: remove limit
        .get(`super/workplaces?limit=10000`)
        .then((response) => response.data);

      store.dispatch({
        type: 'SET_WORKPLACES',
        payload: workplaces,
      });
    }

    // chats
    const getChats = () => ChatsController.getList();

    setInterval(getChats, CHATS_POOL_TIMEOUT_MS);
    getChats();
  },

  onLogin: async (data, redirect = true) => {
    UserController.setCookie('token', data.token); // указать текущий домен
    UserController.setCookie('role', data.role);

    UserController.check();
    UserController.getJobs();

    if (!redirect) return;

    const url = data.role === 'employer' ? '/orders' : '/';
    history.push(url);

    if (data.isFirstLogin) {
      Events.dispatch('MODAL_SHOW-ModalRegistration');
      TagsController.event('Auth', 'Registration');
    }
  },

  /* onSwitch: async (id) => {
    await store.dispatch({
      type: 'USER_SWITCH_CURRENT',
      params: {
        id: parseInt(id),
      }
    });
    history.push('/');
  }, */

  onLogout: (withRedirect = true) => {
    ['token', 'role', 'mobile_team', 'mobile_team_type'].forEach((key) => {
      Cookies.remove(key);
      Cookies.remove(key, {
        domain: cookieDomain(),
      });
    });

    TagsController.reset();
    if (withRedirect) document.location.pathname = '/';
  },

  setCookie: (key, value, expires = 7) => {
    Cookies.set(key, value, {
      expires,
      domain: cookieDomain(),
    });
  },

  getJobs: async (query = {}) => {
    const city = JSON.parse(localStorage.getItem('city'));

    const response = await api.get(`/search/orders`, {
      params: {
        ...query,
        inactive: false,
        start: moment().subtract(4, 'week').format('YYYY-MM-DD'),
        merge: true,
        limit: 1000,
        regular: true,
        ...(city && { location: city.name }),
      },
    });

    store.dispatch({
      type: 'SEARCH_DATA_SET',
      params: {
        data: response.data,
        totalCount: parseInt(response.headers['x-pagination-total-count'] || 0),
      },
    });
  },

  getProfile: async () => {
    try {
      const { data } = await api.get(`/user/profile`);
      if (!data.role) data.role = 'worker';
      store.dispatch({
        type: 'USER_SET',
        params: {
          id: data._id,
          ...pick(data, profileFields),
          mobile_team: data.is_mobile_team,
          mobile_team_type: data.mobile_team?.team_type,
          mobile_team_contract_signed_at: data.mobile_team?.contract_signed_at,
          // had_jobs: false, // TODO
          // is_self_employed: false,
          isAuthorized: true,
          permissions: data.permissions,
        },
      });

      UserController.setCookie('role', data.role);

      if (data.is_mobile_team) {
        UserController.setCookie('mobile_team', data.is_mobile_team);
        if (data?.mobile_team?.team_type) {
          UserController.setCookie(
            'mobile_team_type',
            data?.mobile_team?.team_type
          );
        } else {
          Cookies.remove('mobile_team_type');
        }
      } else {
        ['mobile_team', 'mobile_team_type'].forEach((key) => {
          Cookies.remove(key);
        });
      }

      UserController.checkNotifications();

      return data;
    } catch (e) {
      store.dispatch({
        type: 'USER_SET',
        params: {
          isAuthorized: false,
        },
      });
    }
  },

  getDocuments: async () => {
    const { data } = await api.get(`/documents`);

    store.dispatch({
      type: 'USER_SET',
      params: {
        documents: data,
      },
    });
    return data;
  },

  getBalance: async () => {
    const { data } = await api.get(`/user/balance`);
    const { balance } = data;

    store.dispatch({
      type: 'USER_SET',
      params: {
        balance,
      },
    });
  },

  getBalanceV2: async () => {
    const { data } = await api.get(`/user/balance/v2`);
    const { balance } = data;

    store.dispatch({
      type: 'USER_SET',
      params: {
        balance,
      },
    });
  },

  getTalkBankStatus: async () => {
    const { data } = await api.post('/talkbank/refreshstatus');

    store.dispatch({
      type: 'USER_SET',
      params: {
        talkBankStatus: data ?? TALKBANK_STATUSES.ERROR,
      },
    });
  },

  bindTalkBank: async () => {
    try {
      await api.post('/talkbank/connect');
      await api.post('/talkbank/bind');
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  },

  checkNotifications: async () => {
    const { data } = await api.get(`/notifications?q[deleted]=false&limit=50`);
    const notificationsCount = data.filter(({ _id }) =>
      _id.match(/^fns/)
    ).length;

    store.dispatch({
      type: 'USER_SET',
      params: {
        notificationsCount,
      },
    });
  },

  onVersionSwitch: () => {
    document.location.assign(
      `https://${document.location.host.replace('app', 'hrd')}`
    );
  },
};

export default UserController;
