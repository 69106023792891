import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import TagsController from 'controllers/Tags';
import { formatPrice, getOfferString, getSalary } from 'utils/Helpers';

import { DATE_OF_PAYMENT } from 'utils/Constants';

const { END_OF_WORK, CHAR_OF_ACTUAL, DATE_OF_PAYMENT_TEXTS_MAPPING } =
  DATE_OF_PAYMENT;

const Salary = ({ data: rawData, ignoreDatetimes }) => {
  const data = useMemo(() => {
    if (!ignoreDatetimes) return rawData;
    const { datetimes, ...rest } = rawData ?? {};
    return rest;
  }, [rawData, ignoreDatetimes]);

  const dateOfPayment = data.workplace?.client?.dateOfPayment ?? END_OF_WORK;

  const logClick = useCallback(
    ({ target }) => TagsController.event('Links', 'JobCard', target.innerText),
    [],
  );

  const salary = getSalary(data);
  const offer = getOfferString(data, { displayBy: false });

  return (
    <div className="content_block">
      <div className="content_block-title mb2">Оплата</div>
      <p>
        <strong>{DATE_OF_PAYMENT_TEXTS_MAPPING[dateOfPayment]}</strong>{' '}
        {dateOfPayment === END_OF_WORK && CHAR_OF_ACTUAL}
        <br />
        <br /> На ваш счёт вы получите <strong>{offer}</strong>
        <br />
        <a
          href="https://help.mygig.ru/article/42588"
          onClick={logClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>Как получить деньги на карту?</strong>
        </a>
      </p>
      <p>
        Налог составит: <strong>{formatPrice(salary * 0.06)}₽</strong>
        <br />
        <a
          href="https://help.mygig.ru/article/44117"
          onClick={logClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>Что такое налог?</strong>
        </a>
      </p>
    </div>
  );
};

Salary.propTypes = {
  data: PropTypes.shape({
    salary_per_job_fact: PropTypes.number,
    salary_per_job: PropTypes.number,
    salary_max: PropTypes.number,
    salary_min: PropTypes.number,
    type: PropTypes.oneOf('regular', 'once'),
    workplace: PropTypes.shape({
      client: PropTypes.shape({
        includedTax: PropTypes.bool,
        dateOfPayment: PropTypes.string,
      }),
    }),
  }).isRequired,
  ignoreDatetimes: PropTypes.bool,
};

Salary.defaultProps = {
  ignoreDatetimes: false,
};

export default Salary;
