import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import UserController from 'controllers/User';
import { TALKBANK_INSTRUCTIONS_LINK, TALKBANK_STATUSES } from 'utils/Constants';
import { openFNS } from 'utils/Helpers';

import Popup from '../Popup';

const TalkBankConnectPopup = () => {
  const [visible, setVisible] = useState(false);

  const isSelfEmployed = useSelector((state) => state.user.is_self_employed);
  const talkBankStatus = useSelector((state) => state.user.talkBankStatus);

  useEffect(() => {
    UserController.getTalkBankStatus();
  }, []);

  useEffect(() => {
    if (isSelfEmployed) {
      if (talkBankStatus === TALKBANK_STATUSES.ERROR) {
        UserController.bindTalkBank();
      }
    }
  }, [isSelfEmployed, talkBankStatus]);

  useEffect(() => {
    if (
      talkBankStatus === TALKBANK_STATUSES.ERROR ||
      talkBankStatus === TALKBANK_STATUSES.UNBOUND
    ) {
      setVisible(true);
    }
  }, [talkBankStatus]);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Popup visible={visible} onClose={handleClose}>
      <div className="content_block-title-alt mb2 mr3">
        <h3 className="flex flex_between flex_center">
          <span style={{ fontWeight: 'bold' }}>Подключите Talkbank</span>
          <span role="img" aria-label="snowman" style={{ fontSize: 36 }}>
            ☃️
          </span>
        </h3>
      </div>
      <div
        className="mt2 flex flex_center flex_wrap"
        style={{ fontWeight: 'bold' }}
      >
        <p className="mb2 color_gray">
          Оплата за некоторые подработки переводится через Talkbank. Чтобы мы
          могли провести оплату, настройте права в Моём Налоге.
          <br />
        </p>
        <div
          className="flex flex_center flex_between flex_wrap"
          style={{ width: '100%' }}
        >
          <a
            href={TALKBANK_INSTRUCTIONS_LINK}
            target="_blank"
            className="mr1 mb2"
            rel="noopener noreferrer"
          >
            Как настроить Мой Налог
          </a>
          <div
            role="button"
            tabIndex={0}
            className="btn btn_primary btn_small"
            style={{ marginLeft: 'auto' }}
            onClick={openFNS}
          >
            Перейти в Мой налог
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default TalkBankConnectPopup;
