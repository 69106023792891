const initialState = {
  specs: {},
  professions: [],
  documents: {
    // passport: 'Паспорт',
    // passport_selfie: 'Фото с паспортом',
    medical: 'Медицинская книжка',
    drive: 'Водительское удостоверение',
    // labor: 'Трудовая книжка',
  },
};

export default (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {

    case 'CONFIG_SET':
      nextState = {
        ...state,
        ...action.params,
      };
      break;

    default:
  }
  return nextState;
}
