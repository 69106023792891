import axios from 'axios';
import Cookies from 'js-cookie';
import UserController from 'controllers/User';
import { useRequest } from 'utils/Hooks';

export const baseURL = process.env.REACT_APP_BASE_URL;
const { PROXY_API_USERNAME, PROXY_API_PASSWORD } = process.env;
const Api = axios.create({ baseURL });

const API_CLIENT = 'web';
const auth = {
  username: PROXY_API_USERNAME || 'forest',
  password: PROXY_API_PASSWORD || 'runforestrun',
};

Api.interceptors.request.use(
  async (config) => {
    if (config.url.startsWith('/admin/')) {
      // eslint-disable-next-line no-param-reassign
      config.auth = auth;
      return config;
    }
    const { token, mindboxDeviceUUID } = Cookies.get() || {};
    const { headers } = config;
    if (token) {
      headers.Authorization = token;
      // config.withCredentials = true;
    }
    headers['x-utc-offset'] = new Date().getTimezoneOffset();
    headers['x-api-client'] = API_CLIENT;
    headers['x-device-id'] = mindboxDeviceUUID;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

//  401
Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      UserController.onLogout(false);
    } else if (error.response && error.response.status === 429) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return axios.request(error.config);
    }

    return Promise.reject(error);
  },
);

/**
 * @return {{
 * request: ((function({
 *   url: string,
 *   method?: string,
 *   params?: Object,
 *   data?: Object,
 *   headers?: Object,
 * }): Promise<*>)|*),
 * resetErrors: (function(): void),
 * isRequesting: boolean,
 * errors: string[],
 * }}
 */
export const useApi = () => useRequest(Api);

// TODO: auth
/* Api.defaults.headers.common['Authorization'] =
"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZGY3MzZhZDNmYjJjMTFkMGFmYTQxN2YiLCJyb2xlIjoicm9vdCIsImlhdCI6MTU4MjAwODY1MCwiZXhwIjoxNTgyMDk1MDUwfQ.qU30PciblN9sSufM5doBiaOH4EAQ1IeAtC7CKvINlDQ"; */
export default Api;
