import React from 'react';
import PropTypes from 'prop-types';

const DisplayError = ({ isDisplay, value, style }) => {
  return (
    <>{isDisplay && <div style={{ color: 'red', ...style }}>{value}</div>}</>
  );
};

DisplayError.propTypes = {
  isDisplay: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};

DisplayError.defaultProps = {
  style: {},
};

export default React.memo(DisplayError);
