import React from 'react';
import { dateToTextLong } from 'utils/Helpers';
import Item from './../Item';

class Day extends React.Component {
  renderItems = () => {
    const { items, updateRates } = this.props;
    return items.map(item => {
      return (
        <Item key={item.id || item._id} data={item} onClick={() => {}} updateRates={updateRates} />
      );
    });
  }


  render() {
    const { items } = this.props;

    return (
    <div className="orders_list-day">
      <div className="orders_list">
        {!items && <span className="orders_list-loading">Ошибка</span>}
        {items && !items.length && <span className="orders_list-empty">Нет заявок</span>}
        {items && items.length && this.renderItems()}
      </div>
    </div>
    );
  }

}

export default Day;
