import React, { useState } from 'react';
import Cookies from 'js-cookie';
import UserController from "controllers/User";

const key = 'cookies_accepted';

export default () => {
	const [isAccepted, setAccepted] = useState(!!Cookies.get(key));
	if(isAccepted) return  null;
	const onClick = () => {
		setAccepted(true);
		UserController.setCookie(key, true, 365);
	};

	return (
		<div className="cookies-popup ph2 pv3">
			<strong className="cookies-popup_title">
        <span role="img" aria-label="cookies">🍪</span>
        Cookies
      </strong>
			<div className="mt2 flex flex_center">
				<span className="mr2">Для корректной работы сайта мы используем cookies</span>
				<div className="btn btn_primary btn_small mla" onClick={onClick}>Хорошо</div>
			</div>
		</div>
	)
}