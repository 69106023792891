import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  return (
    <div className="layout_tall p2">
      <div className="error_page-header mb2">
        4<span role="img" aria-label="zero">😬</span>4
      </div>
      <h3>Страница не найдена</h3>
      <div className="mb3">Такой страницы нет</div>
      <Link to="/" className="btn btn_primary btn_fullwidth">На главную</Link>
    </div>
  )
};
