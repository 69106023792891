import controller from 'controllers/Filter';

const initialState = {
  data: [],
  listingType: 'map',
  activeFilters: {},
  otherSearchParams: {},
  activeFavoriteFilterId: null,
  favoriteFilters: [],
  totalCount: 0,
  isInitialCache: false,
};

export default (state = initialState, action) => {
  const fn = controller?.actions[action.type];
  if(fn && typeof fn === 'function') return fn(action, state);
  return state;
}
