import React, { useEffect, useState } from 'react';
import moment from 'moment';
import JobCard from 'components/JobCard';
import api from 'controllers/Api';

const JOBS_COUNT = 6;

export default function JobTiles() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetch = async () => {

      const { data } = await api.get('/search/orders', {
        params: {
          inactive: false,
          sort: 'landing',
          regular: true,
          start: moment().subtract(2, 'week').format('YYYY-MM-DD'),
          limit: JOBS_COUNT
        }
      });
      setJobs(data);
    }

    fetch();
  }, [])

  const domain = process.env.REACT_APP_DOMAIN;
  const proto = process.env.NODE_ENV === 'development' ? 'http' : 'https';
  const jobsUrl = `${proto}://${domain}/jobs`;

  const cards = jobs.map((job) => {
    const open = () => window.open(`${jobsUrl}/${job._id}`, '_blank');
    return (
      <div key={job._id} className="clickable" onClick={open}>
        <JobCard data={job} noOpen />
      </div>
    );
  });

  const css = `
    #root { max-width: none; padding-top: 0; }
    .header, .cookies-popup{ display: none; }
    .layout { height: 100vh; }
  `;

  const content = jobs.length ? <div className="tiles">{cards}</div> : <p>Loading...</p>

  return (
    <>
      <style>{css}</style>
      {content}
    </>
  );
}
