import React, { Component } from 'react';
import qs from 'qs';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ProfessionSelector from '../ProfessionSelector';
import Icons from 'utils/Icons';
import Modal2 from 'components/Modal2';
import TagsController from 'controllers/Tags';

class Search extends Component {

  state = {
    isActiveProfessionSelector: false,
  }

  input = React.createRef()

  openFilters = () => {
    //Events.dispatch('MODAL_SHOW-Filters');
    Modal2('ModalSearch');
  }

  onKeyDown = (e) => {
    if (!e.key || e.key !== 'Enter') return;
    e.preventDefault();

    const { value } = this.input.current;

    this.props.dispatch({
      type: 'JOBS_FILTER_SET_TEXT',
      params: { value }
    });

    TagsController.event('Action', 'SearchJobs', value);
  }

  handleClear = () => {
    this.props.dispatch({
      type: 'JOBS_FILTER_SET_TEXT',
      params: { value: '' }
    });

    this.input.current.value = ''
    this.input.current.focus()

    TagsController.event('Action', 'SearchJobs', '');
  }

  render() {
    const query = qs.parse(window.location.search.substr(1))

    const { onChangeListingType, listingType, mobile_team } = this.props;
    const { activeFilters } = this.props.jobs;

    if (activeFilters === null) return null;

    // filters count bubble
    let activeFiltersCount = Object.keys(activeFilters).length;
    if(activeFilters.location || activeFilters.city) activeFiltersCount -= 1;
    if(activeFilters.text) activeFiltersCount -= 1;

    return (
      <div className='jobs_search' data-type={listingType}>
        {!mobile_team && (
          <>
            <form action='.' onSubmit={this.onKeyDown}>
              <div className='jobs_search-elements flex p2'>
                <input
                  type='text'
                  ref={this.input}
                  defaultValue={query?.q?.text?.equal || activeFilters.text}
                  className='jobs_search-input'
                  placeholder='Поиск подработки'
                  onKeyDown={this.onKeyDown} />

                <div className='btn-square' onClick={this.openFilters}>
                  {activeFiltersCount > 0 && <div className='bubble_count'/>}
                  {Icons.filters}
                </div>

                {(activeFilters.text || query?.q?.text?.equal) && (
                  <span className='jobs_clear' onClick={this.handleClear}>
                    {Icons.close}
                  </span>
                )}
              </div>
            </form>
            <ProfessionSelector />
          </>
        )}
        <div className='jobs_search-listing-type flex'>
          <div onClick={onChangeListingType} data-type='map' className={classNames({ active: listingType === 'map', clickable: true })}>
            {Icons.marker}
            <span>Карта</span>
          </div>
          <div onClick={onChangeListingType} data-type='list' className={classNames({ active: listingType === 'list', clickable: true })}>
            {Icons.list}
            <span>Список</span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ jobs, ...state }) => ({ jobs, ...state }))(Search);
