import TagsController from 'controllers/Tags';
import React, { Component} from 'react';

const timeout = 60;

class CodeResender extends Component {

  state = {
    timeout,
    isShowModal: false,
  }

  timer = null;

  componentDidMount = () => {
    this.start();
  }

  start = () => {
    this.timer = setInterval(() => {
      let { timeout } = this.state;
      timeout -= 1;
      this.setState({ timeout });
      if(timeout === 0) this.stop();
    }, 1000);
  }

  stop = () => {
    clearInterval(this.timer);
  }

  onClickResend = () => {
    TagsController.event('Auth', 'Buttons', 'ResendCode');
    this.setState({ timeout });
    this.start();
    this.props.onResend();
  }

  render() {
    const { timeout } = this.state;
    const isWaiting = timeout > 0;

    const WaitingPart = <div className="color_gray">Отправить код ещё раз ({timeout} сек.)</div>;
    const ResendPart = <span onClick={this.onClickResend} className="color_blue">Отправить код ещё раз</span>;

    return (
      <div className="mt3 mb2">
        { isWaiting ? WaitingPart : ResendPart }
      </div>
    );
  }

}

export default CodeResender;
