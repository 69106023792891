import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import SignDoc from './SignDoc';

const DocumentsForSignature = ({ documents }) => {
  if (documents?.length === 0) {
    return null;
  }

  return (
    <div className="content_block">
      <div className="content_block-title mb2">Документы для подписи</div>
      {documents?.map((doc, index) => (
        <Field
          key={doc._id}
          validate={(value) => !value && 'Необходимо подписать документ!'}
          name={`documents_for_signature.[${index}].status`}
          component={(props) => <SignDoc {...props} doc={doc} index={index} />}
        />
      ))}
    </div>
  );
};

DocumentsForSignature.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any).isRequired),
};

DocumentsForSignature.defaultProps = {
  documents: [],
};

export default React.memo(DocumentsForSignature);
