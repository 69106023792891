import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Icons from 'utils/Icons';

import MenuIcons from './MenuIcons';

const UserMenuSection = () => {
  const { isAuthorized, role, balance, notificationsCount } = useSelector(
    (state) => state.user,
  );
  if (!isAuthorized || !role || role === 'supervisor') return null;

  const notificationsBubble = notificationsCount && (
    <span className="bubble_count">{notificationsCount}</span>
  );

  if (role === 'employer')
    return (
      <>
        <div className="menu_overlay-list">
          <Link to="/notifications">
            {MenuIcons.bell} <span>Уведомления</span> {notificationsBubble}{' '}
            {Icons.next}
          </Link>
        </div>
        <hr />
      </>
    );

  return (
    <>
      <div className="menu_overlay-list">
        <Link to="/profile/balance">
          {MenuIcons.shield} <span>На счёте</span> <span>{balance} ₽</span>{' '}
          {Icons.next}
        </Link>
        <Link to="/profile">
          {MenuIcons.folder} <span>Документы</span> {Icons.next}
        </Link>
        <Link to="/notifications">
          {MenuIcons.bell} <span>Уведомления</span> {notificationsBubble}{' '}
          {Icons.next}
        </Link>
        {/* <Link to="/study">{MenuIcons.clipboard} <span>Обучение</span> {Icons.next}</Link>
            <Link to="/referal">{MenuIcons.smile} <span>Пригласить друзей</span> {Icons.next}</Link> */}
      </div>
      <hr />
    </>
  );
};

export default UserMenuSection;
