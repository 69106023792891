import React from 'react';
import { documentTypes2 } from 'utils/Constants';

export default ({ data }) => {
  if(!data.documents) data.documents = [];

  const documentsText = ['passport'].concat(data.documents).map(key => {
    const name = documentTypes2[key]?.name || key;
    return name;
  }).join(', ');

  return data.type === 'regular' ? '' : (
    <div className="content_block">
      <div className="content_block-title mb2">Документы</div>
      {documentsText}
    </div>
  );
}
