import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Icons from 'utils/Icons';

import MenuIcons from './MenuIcons';

const MenuSection = ({ onClickAuth }) => {
  const { isAuthorized, role } = useSelector((state) => state.user);
  const { unreadMessagesCount } = useSelector((state) => state.chats);
  const workplaces = useSelector((state) => state.env.workplaces);
  const isBalanceVisible = useMemo(
    () =>
      workplaces.findIndex(
        (workplace) => workplace.contractor?.is_deposit_enabled,
      ) > -1,
    [workplaces],
  );

  if (!isAuthorized) {
    return (
      <div className="header__menu flex">
        <strong
          role="button"
          tabIndex={0}
          onClick={onClickAuth}
          style={{ cursor: 'pointer' }}
        >
          Войти
        </strong>
      </div>
    );
  }

  const chatsLinkPart = (
    <NavLink to="/chats">
      {Icons.menuInbox}
      {unreadMessagesCount ? (
        <span className="bubble_count">{unreadMessagesCount}</span>
      ) : null}
    </NavLink>
  );

  return (
    <div className="header__menu flex">
      {role === 'employer' || role === 'supervisor' ? (
        <>
          <NavLink to="/orders">{Icons.menuOrders}</NavLink>
          <NavLink to="/workers">{MenuIcons.workers}</NavLink>
          {isBalanceVisible && (
            <NavLink to="/balance">{MenuIcons.rouble}</NavLink>
          )}
          {(role === 'employer' || role === 'supervisor') && chatsLinkPart}
        </>
      ) : (
        <>
          <NavLink to="/" exact>
            {Icons.menuSearch}
          </NavLink>
          {/* <NavLink to="/favorites">{Icons.menuFavorite}</NavLink> */}
          {chatsLinkPart}
          <NavLink to="/schedule">{Icons.menuSchedule}</NavLink>
        </>
      )}
    </div>
  );
};

MenuSection.propTypes = {
  onClickAuth: PropTypes.func.isRequired,
};

export default MenuSection;
