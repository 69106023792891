import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { dateToText, getOfferString } from 'utils/Helpers';
import history from 'controllers/History';
import { baseURL } from 'controllers/Api';
import { DATE_OF_PAYMENT } from 'utils/Constants';

const { END_OF_WORK, DATE_OF_PAYMENT_TEXTS_MAPPING } = DATE_OF_PAYMENT;

const JobCard = ({ data, noOpen }) => {
  const mobileTeam = useSelector((state) => state.user.mobile_team);
  const open = () => !noOpen && history.push(`/jobs/${data.id}`);
  const { workplace } = data;
  const needInterview = data.type === 'regular' && !data.has_success_interview;

  const renderSchedule = () => {
    const { datetimes, is_night: night } = data;
    const count = datetimes?.length;
    if (!count) return null;
    const text = needInterview
      ? 'Необходимо пройти собеседование'
      : `${
          datetimes?.[0]?.start
            ? dateToText(datetimes[0].start)
            : 'Дата не указана'
        }`;
    let isRedTag = false;
    if (text.includes(`Завтра`)) isRedTag = true;
    if (text.includes(`Сегодня`)) isRedTag = true;

    const start = dateToText(datetimes[0].start, 'HH:mm');
    const finish = dateToText(datetimes[0].finish, 'HH:mm');
    const time = !data.type === 'regular' && (
      <strong>
        {night && '🌗'} {start} – {finish}
      </strong>
    );

    const className = classNames({
      'tag--red': isRedTag,
      tag: true,
    });

    return (
      <>
        <div className={className}>
          {text}
          {time}
        </div>
        {count > 1 && (
          <div className="tag">
            <strong>Ещё {count - 1}</strong>
          </div>
        )}
      </>
    );
  };

  const offer = getOfferString(data, { mobileTeam })
    ?.split(' / ')
    .reverse()
    .join(' / ');

  const dateOfPayment = data.workplace?.client?.dateOfPayment || END_OF_WORK;

  return (
    <div className="job_card p2">
      <div
        role="button"
        tabIndex={0}
        className="job_card-header flex mb2 clickable"
        onClick={open}
      >
        <div className="job_card-name">
          <strong>{data.custom_name}</strong>
        </div>
        <div
          className={classNames(
            { 'job_card-salary': data.is_active && data.type !== 'regular' },
            { 'job_card-salary job_card-salary-inactive': !data.is_active },
            {
              'job_card-salary job_card-salary-regular':
                data.type === 'regular',
            },
          )}
        >
          {offer}
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className="job_card-date flex mb2 clickable"
        onClick={open}
      >
        {renderSchedule()}
      </div>
      {!mobileTeam && (
        <div
          role="button"
          tabIndex={0}
          className="job_card-info flex mb2 clickable"
          onClick={open}
        >
          <div>
            Оплата:{' '}
            <span className="color_gray">
              {DATE_OF_PAYMENT_TEXTS_MAPPING[dateOfPayment]}
            </span>
          </div>
        </div>
      )}
      <hr role="presentation" onClick={open} />
      <div
        role="button"
        tabIndex={0}
        className="job_card-company flex"
        onClick={open}
      >
        <div
          className="job_card-company-image"
          style={{ backgroundImage: `url(${baseURL}/client/${data.client})` }}
        />
        <div className="job_card-company-info">
          <div className="job_card-company-name ellipsis">
            {workplace?.client.title}
          </div>
          <div className="job_card-company-address">
            {data.distance && <>~{data.distance} м. &nbsp;</>}
            <span className="color_gray ellipsis">
              {workplace?.address?.metro_station
                ? `м. ${workplace?.address?.metro_station}, `
                : ''}
              {workplace?.address?.street}, {workplace?.address?.house}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

JobCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    custom_name: PropTypes.string,
    type: PropTypes.string,
    is_active: PropTypes.bool,
    is_night: PropTypes.bool,
    has_success_interview: PropTypes.bool,
    datetimes: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.string,
        finish: PropTypes.string,
      }),
    ),
    distance: PropTypes.number,
    client: PropTypes.string,
    workplace: PropTypes.shape({
      address: PropTypes.shape({
        metro_station: PropTypes.string,
        street: PropTypes.string,
        house: PropTypes.string,
      }),
      client: PropTypes.shape({
        title: PropTypes.string,
        dateOfPayment: PropTypes.string,
      }),
    }),
  }).isRequired,
  noOpen: PropTypes.bool,
};

JobCard.defaultProps = {
  noOpen: false,
};

export default React.memo(JobCard);
