import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icons from 'utils/Icons';
import WorkersList from 'components/WorkersList';
import { formatPrice } from 'utils/Helpers';

import arrow from './arrow.svg';

const OrdersItem = ({ data, hideSalary }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    const date = data.dates[0];
    // переход со списка заказов на детальную заказа
    history.push(`/orders/${data.id}/${date}`, { data });
  };

  const onClickWorker = ({ job }) => {
    if (!job) return;
    // переход со списка заказов на карточку работника
    history.push(`/orders/${data.id}/${job.date}/${job.id}`, { data });
  };

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsOpen((v) => !v);
  };

  const salary = data.payment_per_hour * data.hours;
  // const repeat =
  //   data.repeat_days?.length &&
  //   data.repeat_days
  //     .map((item) => item.replace('everyday', 'ежедневно'))
  //     .join(', ');
  const currentJobs = data.inwork.filter(
    (i) => !['cancelled', 'failed', 'doccheck', 'toolate'].includes(i.status),
  );
  const peopleCurrent = currentJobs.length;
  const peopleTotal = data.amount;
  const salaryCurrent = currentJobs.reduce(
    (sum, item) => sum + item.payment_per_job_fact,
    0,
  );
  const salaryTotal =
    salary * peopleTotal + (data.payment_per_unit * data.units_planned || 0);
  const showBreak = !!data.break_time;

  const openClass = isOpen ? 'order_item-expand-open' : '';

  const salaryView = () => {
    if (data.payment_per_hour && !data.payment_per_unit) {
      return (
        <div className="job_card-salary">
          <strong>{formatPrice(data.payment_per_hour)} ₽ / ч.</strong>
        </div>
      );
    }

    if (data.payment_per_hour && data.payment_per_unit) {
      return (
        <div className="job_card-salary">
          <strong>
            {data.payment_per_hour} ₽ / ч. + ~{' '}
            {data.payment_per_unit * data.units_planned} ₽ ({data.units_planned}{' '}
            ед.)
          </strong>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className="orders_item p2"
      onClick={onClick}
    >
      <div className="color_gray mb1">{data.workplace.title}</div>
      <div className="flex mb1">
        <div className="job_card-name">
          <strong>
            <span>{data.custom_name}</span>
          </strong>
        </div>
        {!hideSalary && salaryView()}
      </div>
      <div className="flex mb2">
        <div className="tag_wrapper">
          <div className="tag">
            <strong>
              {data.is_night && '🌗'} {data.start_time?.replace(/^0/, '')} –{' '}
              {data.finish_time?.replace(/^0/, '')}
            </strong>
          </div>
          {showBreak && (
            <div className="tag">
              <strong>Перерыв {data.break_time}</strong>
            </div>
          )}
        </div>
      </div>

      <hr />

      <div
        role="button"
        tabIndex={0}
        className="order_item-workers-total flex"
        onClick={toggleExpand}
      >
        <div className="order_item-workers flex">
          <span>
            {Icons.worker} &nbsp; {peopleCurrent}
          </span>
          {peopleCurrent !== peopleTotal && (
            <>
              &nbsp;/&nbsp;
              <span className="color_gray">{peopleTotal}</span>
            </>
          )}
        </div>
        <strong className="order_item-price">
          <span className="">{formatPrice(salaryCurrent)} ₽</span>
          {salaryCurrent !== salaryTotal && (
            <>
              &nbsp;/&nbsp;
              <span className="color_gray">{formatPrice(salaryTotal)} ₽</span>
            </>
          )}
        </strong>
        <img alt="▼" src={arrow} className={`order_item-expand ${openClass}`} />
      </div>

      {isOpen && <WorkersList order={data} onClickItem={onClickWorker} />}
    </div>
  );
};

OrdersItem.propTypes = {
  data: PropTypes.objectOf().isRequired,
  hideSalary: PropTypes.bool,
};

OrdersItem.defaultProps = {
  hideSalary: false,
};

export default OrdersItem;
