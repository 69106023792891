import React, { useContext } from 'react';
import cn from 'classnames';

import ActionButtons from './components/ActionButtons';
import ActionTitle from './components/ActionTitle';
import ActionText from './components/ActionText';
import Context from '../../Context';

const className = 'content_block schedule_page-action';

const ActionBlock = () => {
  const { data, timeLeftHours, timeLeftMinutes, timeLeftDays, isLoading } =
    useContext(Context);

  const getClassName = () => {
    if (
      ['booked', 'going', 'doccheck'].includes(data?.status) &&
      timeLeftDays === 0 &&
      ((timeLeftMinutes < 30 && timeLeftHours === 0) ||
        (timeLeftHours < 16 && data.status !== 'going'))
    )
      return null;

    switch (data.status) {
      case 'delayed':
      case 'waiting':
      case 'inprogress':
        return null;

      case 'going':
      case 'doccheck':
      case 'completed':
      case 'confirmed':
      default:
        return 'bg_green';

      case 'checkingin':
      case 'toolate':
      case 'checkingout':
      case 'cancelled':
      case 'failed':
      case 'disputed':
        return 'bg_orange';
    }
  };

  if (isLoading) {
    return (
      <div className={cn(className)}>
        <div className="content_block-title mb2">
          <div className="fake" style={{ width: '60%', height: 16 }} />
        </div>
        <div className="mb2">
          <div className="fake" style={{ width: '80%', marginBottom: 8 }} />
          <div className="fake" style={{ width: '70%' }} />
        </div>
        <div className="text-right">
          <div
            className="fake mr2"
            style={{ width: '30%', height: 30, display: 'inline-block' }}
          />
          <div
            className="fake"
            style={{ width: '40%', height: 30, display: 'inline-block' }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={cn(className, getClassName())}>
      <ActionTitle />
      <ActionText />
      <ActionButtons />
    </div>
  );
};

export default ActionBlock;
