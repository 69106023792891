import React, { useState, useEffect } from 'react';
import Schedule from './Schedule';
import api from 'controllers/Api';
import { dateToText } from 'utils/Helpers';
import { connect } from 'react-redux';
import { Events } from 'utils/Helpers';
import classNames from 'classnames';
import TagsController from 'controllers/Tags';

const Dates = ({
  user,
  data,
  disableSelectDate,
  isSchedulePage,
  onFetch,
  ...props
}) => {
  const [isLiked, setLiked] = useState(data.is_favorite);
  useEffect(() => setLiked(data.is_favorite), [data.is_favorite]);

  const isAuthorized = user.isAuthorized;

  const toggle = async () => {
    TagsController.event('Buttons', 'SubscribeJob', isLiked ? 'false' : 'true');
    const id = data.source || data._id;
    if (isAuthorized) {
      const uri = `/favorites/${id}`;
      if (isLiked) {
        await api.delete(uri);
        if (!data.is_active)
          TagsController.event('Actions', 'UnsubscribeJob', data.custom_name);
      } else {
        await api.put(uri);
        if (!data.is_active)
          TagsController.event('Actions', 'SubscribeJob', data.custom_name);
      }
      setLiked(!isLiked);
    } else {
      // XXX: костыль */
      const open = () => onFetch?.();
      Events.dispatch('MODAL_SHOW-ModalAuth', { callback: open });
    }
  };

  let schedule = <Schedule data={data} disableSelectDate={disableSelectDate} />;
  if (isSchedulePage) {
    let date = dateToText(data.start);
    const _start = dateToText(data.start, 'HH:mm');
    const _finish = dateToText(data.finish, 'HH:mm');
    schedule = (
      <div className="job_page-schedule mb2">
        <div className="tag_wrapper">
          <div className={`tag tag--black`}>
            {date}, {data.is_night && '🌙'} {_start} - {_finish}
          </div>
        </div>
      </div>
    );
  }

  const inactive = (
    <span className="mb2">
      Сейчас заказ неактивен.
      <br />
      Подпишитесь на уведомление по SMS, чтобы узнать сразу, как только он снова
      станет активным.
      <br />
      <br />
      <br />
    </span>
  );
  const regular = (
    <div>
      <div className="tag tag--interview">
        Требуется собеседование
        <br />
      </div>
      <div>
        <br />
        Для выбора дат необходимо пройти собеседование. После этого вы сможете
        выбрать удобные вам даты или постоянный сменный график
        <br />
      </div>
    </div>
  );

  const btnClasses = classNames({
    btn: true,
    btn_fullwidth: true,
    btn_inactive: isLiked,
    btn_primary: !isLiked,
  });

  return (
    <div className="content_block">
    <div className="content_block-title mb2">ДОСТУПНЫЕ ДАТЫ</div>
      { (data.type === 'regular' && !data.has_success_interview && !isSchedulePage) ? regular : schedule }
      { (!data.is_active && data.type !== 'regular' && !isSchedulePage) ? inactive : '' }
      {
        !isSchedulePage && !data.is_active && (
        <div className="mb1">
          <button onClick={toggle} className={btnClasses}>
            {isLiked ? 'Вы подписаны' : 'Подписаться на уведомление'}
          </button>
        </div>
      )}
    </div>
  );
};

export default connect((state) => ({ user: state.user }))(Dates);
