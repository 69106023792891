import store from 'reducers';
import api from 'controllers/Api';

const DictionariesController = {
  init: async () => {
    await DictionariesController.getProfessions();
    await DictionariesController.getSpecs();
  },

  getProfessions: async () => {
    const { data } = await api.get('/dictionaries/professions');
    store.dispatch({
      type: 'CONFIG_SET',
      params: { professions: data },
    });
  },

  getSpecs: async () => {
    const { data } = await api.get('/dictionaries/specs');
    store.dispatch({
      type: 'CONFIG_SET',
      params: { specs: data },
    });
  },
};

export default DictionariesController;
