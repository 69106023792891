import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { documentsGroups } from 'utils/Constants';
import Icons from "utils/Icons";


class Documents extends React.Component {

  renderItems = (items) => {
    const { documents } = this.props.user;
    return items.map(item => {
      const exist = documents.filter(document => document.doctype === item.doctype)[0];
      if(!exist) {
        return (
          <Link to={`/profile/documents/${item.doctype}`} className="flex_center profile_page-document-placeholder" key={item.doctype}>
            {item.name}
          </Link>
        );
      }

      let className = `box p2 profile_page-documents-item`;
      let errorText = null;
      const isConfirmed = exist.is_confirmed;
      if(isConfirmed === false) {
        className += ` bg_gray`;
        errorText = exist.pages.map(page => page.error).filter(text => text !== null);
      }

      return (
        <Link to={`/profile/documents/${item.doctype}`} className={className}  key={item.doctype}>
          <div className="flex flex_center">
            <strong className="mra">{item.name}</strong>
            {isConfirmed && Icons.approved}
            {isConfirmed === null && <span className="tag mla tag--orange">Проверяется</span>}
            {isConfirmed === false && Icons.warning}
          </div>
          {errorText?.length ? (
            <div className="mt1">
              <span className="tag tag--red color_red">{errorText}</span>
            </div>
          ) : null}
        </Link>
      );
    });
  }

  render() {
    return documentsGroups.map(group => {
      return (
        <div className="content_block" key={group.docgroup}>
          <div className="content_block-header mb2">
            <div className="content_block-title">{group.name}</div>
          </div>
          <div className="profile_page-documents">
            {this.renderItems(group.items)}
          </div>
        </div>
      );
    });

  }
}
export default connect(state => ({ user: state.user }))(Documents);
