import TagManager from 'react-gtm-module'

const DEFAULT_EVENT = 'ev-app_mygig';
const CURRENCY = 'RUB';

const TagsController = {
  init: () => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  },
  populateWorker: (profile, documents) => {
    const { user, role, balance, is_self_employed, accept_oferta, analytics } = profile;

    const docStatus = (docs) => {
      if (!docs || !docs.length) return 'incorrect';

      const hasIncorrect = docs.some(({ is_confirmed }) => is_confirmed === false);
      const hasModeration = docs.some(({ is_confirmed }) => is_confirmed === null);

      if (hasIncorrect) return 'incorrect';
      else if (hasModeration) return 'moderation';
      else return 'correct';
    }

    const docs = documents.reduce(
      (acc, { docgroup }) => ({
        ...acc,
        [docgroup]: acc[docgroup] || docStatus(documents.filter(d => d.docgroup === docgroup ))
      }),
      {}
    );

    TagsController.push({
      userId: user,
      role,
      balance,
      npdStatus: !!is_self_employed,
      ofertaStatus: accept_oferta,
      documents: docs,
      ...analytics
    });
  },
  reset: () => {
    TagsController.push(function () { this.reset() });
  },
  push: (data) => {
    TagManager.dataLayer({ dataLayer: data });
  },
  event: (category, action, label) => {
    const data = {
      event: DEFAULT_EVENT,
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    };
    TagsController.push(data);
  },

  ecomEventBooking: (data) => {
    TagsController.ecomEvent('Booking', data.custom_name, {
      currencyCode: CURRENCY,
      add: {
        products: TagsController.createProductsPayload(data)
      }
    });
  },

  ecomEventBookingCancel: (data) => {
    TagsController.ecomEvent('BookingCancel', data.custom_name, {
      currencyCode: CURRENCY,
      remove: {
        products: TagsController.createProductsPayload(data)
      }
    });
  },

  ecomEventJobStart: (data) => {
    TagsController.ecomEvent('JobStart', data.custom_name, {
      currencyCode: CURRENCY,
      checkout: {
        actionField: { step: 1 },
        products: TagsController.createProductsPayload(data)
      }
    });
  },

  ecomEventJobCancel: (custom_name) => {
    TagsController.ecomEvent('JobCancel', custom_name, {
      checkout_option: {
        actionField: { step: 1, option: 'Отмена заказа — Исполнитель' }
      }
    });
  },

  ecomEventJobFinish: (data) => {
    TagsController.ecomEvent('JobFinish', data.custom_name, {
      currencyCode: CURRENCY,
      checkout: {
        actionField: { step: 2 },
        products: TagsController.createProductsPayload(data)
      }
    });
  },

  ecomEventJobConfirmed: (data) => {
    const _tax = data.workplace?.contractor?.type === 'saas'
      ? 1
      : 0.94;

    const salary = data.salary_per_job / _tax;
    const tax = data.workplace?.contractor?.type === 'saas'
      ? 0
      : salary * 0.06;

    TagsController.ecomEvent('JobConfirmed', data.custom_name, {
      currencyCode: CURRENCY,
      purchase: {
        actionField: {
          id: data._id,
          affiliation: data?.workplace?.full_address,
          revenue: salary,
          tax
        },
        products: TagsController.createProductsPayload(data)
      }
    });
  },

  createProductsPayload: ({ custom_name, spec, salary_per_job, workplace }) => {
    return [{
      name: custom_name,
      id: spec,
      price: salary_per_job,
      brand: workplace?.client?.title,
      quantity: 1
    }];
  },

  ecomEvent: (action, label, payload) => {
    const data = {
      ecommerce: payload,
      event: 'EEC',
      eventCategory: 'EEC',
      eventAction: action,
      eventLabel: label,
      nonInteraction: false
    }

    TagsController.push(data);
  }
}

export default TagsController;
