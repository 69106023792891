import React from 'react';
import PropTypes from 'prop-types';

import TransactionIncome from './income';
import TransactionWithdraw from './withdrawal';

const TransactionItem = ({ data }) => {
  if (data.type === 'income') return <TransactionIncome data={data} />;
  return <TransactionWithdraw data={data} />;
};

TransactionItem.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default TransactionItem;
