import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';

import {
  getLocationByCity,
  getOfferString,
  makePlacemarks,
  getPosition,
  getDistance,
} from 'utils/Helpers';
import Icons from 'utils/Icons';
import { ICON_OFFSET, ICON_SHAPE } from 'utils/Constants';
import { useDebouncedEffect } from 'utils/Hooks';

// из-за авто-обновления баланса этот компонент иногда перерендерится

const Map = ({
  isLoading,
  data,
  mobileTeam,
  currentLocation,
  requestUserLocation,
  onMove,
}) => {
  const [map, setMap] = useState(null);
  const [clusterer, setClusterer] = useState(null);
  const history = useHistory();
  const mounted = useRef(false);

  const { ymaps } = window;

  const renderEmptyMessage = () => {
    const phrases = [
      <>
        Тут нет работы{' '}
        <span role="img" aria-label="no work">
          🤷🏼‍♂️
        </span>
      </>,
      <>
        Ничего не найдено{' '}
        <span role="img" aria-label="nothing found">
          😬
        </span>
      </>,
      <>
        Здесь рыбы нет{' '}
        <span role="img" aria-label="no fish">
          🐟
        </span>
      </>,
      <>
        Холодно. Ищите дальше{' '}
        <span role="img" aria-label="cold">
          🥶
        </span>
      </>,
    ];
    const text = phrases[Math.floor(Math.random() * phrases.length)];
    return <span className="jobs_map-empty flex flex_center">{text}</span>;
  };

  // рендеринг меток на яндекс карту
  const renderPlacemarks = useCallback(() => {
    if (!map) return;
    const placemarks = makePlacemarks(data, history, { mobileTeam });

    if (!clusterer) return;
    clusterer.removeAll().add(placemarks);
    map.geoObjects.add(clusterer);
  }, [clusterer, data, history, map, mobileTeam]);

  // рендеринг локации воркера
  const renderUserLocationPlacemark = useCallback(() => {
    const placemark = new ymaps.Placemark(
      currentLocation,
      {},
      {
        preset: 'islands#circleIcon',
        iconColor: '#3caa3c',
      },
    );
    map.geoObjects.add(placemark);
  }, [currentLocation, map?.geoObjects, ymaps?.Placemark]);

  // инициализация карт
  const initYMap = useCallback(() => {
    if (!ymaps) return;

    const elementId = 'jobsMap';

    const initMap = new ymaps.Map(
      elementId,
      {
        center: currentLocation || getLocationByCity(),
        zoom: (currentLocation && 14) || 9,
        controls: ['zoomControl'],
      },
      {
        suppressMapOpenBlock: true,
      },
    );

    const clusterIconLayout = ymaps.templateLayoutFactory?.createClass(
      `<div class="map_placemark map_cluster">
          <div class="map_placemark-circle {{ properties.circleClass }}">{{ properties.iconContent }}</div>
          <div class="map_placemark-price {{ properties.baloonClass }}">{{ properties.salary }}</div>
        </div>
      `,
      {
        build() {
          /* eslint-disable react/no-this-in-sfc */
          this.constructor.superclass.build.call(this);
          // eslint-disable-next-line no-underscore-dangle
          const objectsInCluster = this.getData().properties._data.geoObjects;
          // eslint-disable-next-line no-underscore-dangle
          const orders = objectsInCluster.map((o) => o.properties._data.data);

          const hasActive = orders.some((item) => item.is_active);
          const hasRegular = orders.some((item) => item.type === 'regular');
          const hasInactive = orders.some((item) => !item.is_active);

          const offer = getOfferString({ datetimes: orders }, { mobileTeam });

          const { properties } = this.getData();

          if (hasInactive)
            properties.set(
              'circleClass',
              hasActive ? 'semiactive' : 'inactive',
            );
          if (hasInactive && hasActive && hasRegular)
            properties.set('circleClass', 'threeactive');
          properties.set('baloonClass', hasActive ? '' : 'inactive');

          properties.set('salary', offer);
          /* eslint-enable react/no-this-in-sfc */
        },
      },
    );

    const initClusterer = new ymaps.Clusterer({
      clusterIconLayout,
      clusterIconShape: ICON_SHAPE,
      // clusterIconSize: ICON_SIZE,
      clusterIconOffset: ICON_OFFSET,
      gridSize: 64,
      // maxZoom: 15,
      groupByCoordinates: false,
      clusterDisableClickZoom: true,
      // clusterCursor: 'pointer',
      // cursor: 'pointer',
      clusterOpenBalloonOnClick: false,
      clusterHideIconOnBalloonOpen: false,
      geoObjectHideIconOnBalloonOpen: false,
      hasBalloon: false,
    });
    initClusterer.events.add(['click'], (e) => {
      /* eslint-disable no-underscore-dangle */
      const target = e.get('target');
      const { geoObjects } = target.properties._data;
      if (!geoObjects) return;
      const zoom = initMap.getZoom();

      const allTheSame = geoObjects.every((o) => {
        const fc = geoObjects[0].geometry._coordinates;
        const oc = o.geometry._coordinates;

        return fc[0] === oc[0] && fc[1] === oc[1];
      });
      if (zoom >= 19 || allTheSame) {
        // открытие страницы с метками
        const items = geoObjects.map((o) => o.properties._data.data);
        history.push(`/jobs/${items[0].id}`, {
          similar: items,
        });
        return;
      }
      // зум на bbox меток в кластере
      const bounds = target.getBounds();
      initMap.setBounds(bounds, { checkZoomRange: true });
      /* eslint-enable no-underscore-dangle */
    });

    const updatePos = (emap) => {
      const center = emap.getCenter();
      const topRightBound = emap.getBounds()[0];
      onMove([...center, getDistance(topRightBound, center) * 1000]);
    };

    updatePos(initMap);

    initMap.events.add('actionend', (e) => {
      updatePos(e.originalEvent.map);
    });

    setMap(initMap);
    setClusterer(initClusterer);

    mounted.current = true;
  }, [currentLocation, history, mobileTeam, onMove, ymaps]);

  useEffect(() => {
    if (ymaps) ymaps.ready(initYMap);
  }, [initYMap, ymaps]);

  useEffect(() => {
    renderPlacemarks();
  }, [data, map, renderPlacemarks]);

  useEffect(() => {
    if (mounted.current) renderPlacemarks();
  }, [mobileTeam, renderPlacemarks]);

  // логика при определении гео
  useEffect(() => {
    if (!map || !currentLocation) return;
    map.setCenter(currentLocation, 14);
    renderUserLocationPlacemark();
  }, [currentLocation, map, renderUserLocationPlacemark]);

  return (
    <div className="jobs_map" id="jobsMap">
      {isLoading && <span className="jobs_map-loading">Загрузка...</span>}
      {!isLoading && data.length === 0 && renderEmptyMessage()}
      <span
        role="button"
        tabIndex="0"
        className="jobs_map-my-location"
        onClick={requestUserLocation}
      >
        {Icons.geoLocation}
      </span>
    </div>
  );
};

Map.propTypes = {
  data: PropTypes.arrayOf({}).isRequired, // TODO:
  isLoading: PropTypes.bool,
  mobileTeam: PropTypes.bool,
  currentLocation: PropTypes.objectOf({}).isRequired, // TODO:
  requestUserLocation: PropTypes.func.isRequired,
  onMove: PropTypes.func,
};

Map.defaultProps = {
  isLoading: false,
  mobileTeam: false,
  onMove: () => {},
};

const areEqual = (prevProps, props) => {
  const prevIds = prevProps.data.map((item) => item._id);
  const nextIds = props.data.map((item) => item._id);
  const isEqualData = isEqual(prevIds, nextIds);
  const isEqualMobileTeam = prevProps.mobile_team === props.mobile_team;
  const isEqualLoading = prevProps.isLoading === props.isLoading;
  return isEqualData && isEqualMobileTeam && isEqualLoading;
};

const MemoizedMap = React.memo(Map, areEqual);

const MapWrapper = ({ fetch, isLoading }) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.jobs.data);
  const mobileTeam = useSelector((state) => state.user.mobile_team);
  const currentLocation = useSelector((state) => state.user.currentLocation);

  // lat, lon, radius
  const [curPos, updatePos] = useState(null);

  const [initFetched, setInitFetched] = useState(false);

  const fetchOnPos = useCallback(() => {
    if (!curPos) return;
    const [lat, lon, radius] = curPos;
    fetch({ lat, lon, radius });
  }, [curPos, fetch]);

  useEffect(() => {
    if (!curPos || initFetched) return;
    fetchOnPos();
    setInitFetched(true);
  }, [curPos, fetchOnPos, initFetched]);

  useDebouncedEffect(
    () => {
      if (!initFetched) return;
      fetchOnPos();
    },
    [fetchOnPos],
    500,
  );

  const requestUserLocation = async () => {
    const { lat, lon } = await getPosition();
    dispatch({
      type: 'USER_SET',
      params: { currentLocation: [lat, lon] },
    });
  };

  return (
    <>
      <MemoizedMap
        mobileTeam={mobileTeam}
        isLoading={isLoading}
        data={data}
        requestUserLocation={requestUserLocation}
        currentLocation={currentLocation}
        onMove={updatePos}
      />
    </>
  );
};

MapWrapper.propTypes = {
  fetch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

MapWrapper.defaultProps = {
  isLoading: false,
};

export default React.memo(MapWrapper);
