import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makePlacemarks } from 'utils/Helpers';
import { PLACEMARK_ZOOM } from 'utils/Constants';

const Map = ({ data: rawData, ignoreDatetimes }) => {
  const data = useMemo(() => {
    if (!ignoreDatetimes) return rawData;
    const { datetimes, ...rest } = rawData ?? {};
    return rest;
  }, [ignoreDatetimes, rawData]);

  const { ymaps } = window;
  const elementId = `jobPageMap`;
  const mobileTeam = useSelector((state) => state?.user?.mobile_team);
  const mounted = useRef(false);

  const renderPlacemark = useCallback(() => {
    const placemark = makePlacemarks([data], null, {
      single: true,
      mobileTeam,
    })[0];
    const map = window[`${data._id}_jobMap`];
    if (!map) return;
    map.geoObjects.removeAll().add(placemark);
  }, [data, mobileTeam]);

  const initYMap = useCallback(() => {
    const location = data?.workplace?.latlon;
    if (!location) return;

    if (!ymaps) return;

    // очистить карту если была уже инициализирована, не на хуках тк реакт не знает о карте и ее доме
    const domNode = document.getElementById(elementId);
    if (!domNode) return;
    if (domNode.hasChildNodes()) domNode.innerHTML = '';

    const map = new ymaps.Map(
      elementId,
      {
        center: location,
        zoom: PLACEMARK_ZOOM,
        controls: ['zoomControl'],
      },
      {
        suppressMapOpenBlock: true,
      },
    );

    window[`${data._id}_jobMap`] = map;

    renderPlacemark();
  }, [data._id, data?.workplace?.latlon, elementId, renderPlacemark, ymaps]);

  useEffect(() => {
    if (ymaps) ymaps.ready(initYMap);
  }, [initYMap, ymaps]);

  useEffect(() => {
    renderPlacemark();
  }, [data.salary_per_job, renderPlacemark]);

  // обновление метки при изменении mobile_team
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      initYMap();
    }
  }, [initYMap, mobileTeam]);

  return <div className="job_page-map" id={elementId} />;
};

Map.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    salary_per_job: PropTypes.number.isRequired,
    workplace: PropTypes.shape({
      latlon: PropTypes.arrayOf(PropTypes.number.isRequired),
    }).isRequired,
  }).isRequired,
  ignoreDatetimes: PropTypes.bool,
};

Map.defaultProps = {
  ignoreDatetimes: false,
};

const areEqual = (prevProps, props) => {
  return (
    prevProps.data._id === props.data._id &&
    prevProps.data.salary_per_job === props.data.salary_per_job
  );
};
export default React.memo(Map, areEqual);
