import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { baseURL } from 'controllers/Api';
import history from 'controllers/History';
import { statuses } from 'utils/Constants';
import { calculateJobTotalSalary, formatDuration } from 'utils/Helpers';


class Item extends React.Component {

  state = {
    checkIn: false,
    isDisabled: null,
  }

  componentDidMount = () => {
    const { data } = this.props;

    const isToday = moment(data.start).isSame(new Date(), "day");
    const isDisabled = (!isToday && moment(data.start).isBefore(new Date()))
      || ['confirmed', 'cancelled'].includes(data.status);

    this.setState({ isDisabled });
  }

  onClick = (e) => {
    if(e && e.target.classList.contains('btn')) return;
    const { data } = this.props;
    history.push(`/schedule/${data.id}`, { data });
  }

  onClickCheckin = () => {
    this.onClick(null);
  }

  renderStatus = () => {
    let { hours, minutes, status } = this.props.data;
    const { mobile_team } = this.props;
    if(mobile_team) return null;
    let className = ['tag', 'mla'];
    let text = statuses[status];

    let salary = calculateJobTotalSalary(this.props.data);

    //status = 'confirmed'

    switch (status) {
      case 'completed':
        text = `Ожидается ${salary} ₽`;
        className.push('tag--orange color_orange');
        break;
      case 'disputed':
        text = `Решается спор`;
        className.push('tag--red color_red');
        break;
      case 'confirmed':
        text = `Вы заработали ${salary} ₽`;
        className.push('tag--green color_green');
        break;
      default:
        text = <><strong>{salary} ₽</strong> / {formatDuration(minutes, hours)}</>;
        className.push('tag--yellow');
    }



    return <span className={className.join(' ')}>{text}</span>;
  }


  render = () => {
    const { data } = this.props;
    const { isDisabled } = this.state;

    const className = classNames({
      'schedule_list-items-item': true,
      'card': true,
      'p1': true,
      '__disabled': isDisabled
    });

    return (
      <div className={className} onClick={this.onClick} ref={this.props.refProp}>
        <div className="flex mb1">
          <span className="tag">{data.is_night && '🌙'} {data.start_time} - {data.finish_time}</span>
          {this.renderStatus(data.status)}
        </div>
        <div className="mb1"><strong>{data.custom_name}</strong></div>
        <div className="job_card-company flex">
          <div className="job_card-company-image" style={{ backgroundImage: `url(${baseURL}/client/${data.client})` }}></div>
          <div className="job_card-company-info">
            <div className="job_card-company-address">
              <span className="color_gray">{data.workplace?.client?.title}</span>
            </div>
          </div>
        </div>
        {/* isToday && data.status === 'booked' && (
          <div className="mt2 flex_btns">
            <div className="btn btn_small">Опаздываю</div>
            <div className="btn btn_small btn_primary" onClick={this.onClickCheckin}>Я на месте</div>
          </div>
        ) */}
      </div>
    );
  }
}

export default Item;
