import store from 'reducers';
import api from 'controllers/Api';

const TransactionsController = {

  getList: async () => {
    const { data } = await api.get('/user/transactions');
    store.dispatch({
      type: 'TRANSACTIONS_LIST_SET',
      params: { data }
    });
  },

  getStats: async () => {
    const { data } = await api.get('/user/transactions/stats');
    store.dispatch({
      type: 'SET_TRANSACTIONS_STATS',
      payload: data
    });
  },

  signAct: async (id) => {
    const { data } = await api.post(`/user/transaction/${id}/act`);

    store.dispatch({
      type: 'TRANSACTION_SIGN_ACT',
      params: { id, receipt_status: data.receipt_status }
    });
  },

  addReceipt: async (id, receipt) => {
    const { data } = await api.post(`/user/transaction/${id}/receipt`, { receipt });

    store.dispatch({
      type: 'TRANSACTION_ADD_RECEIPT',
      params: { id, receipt_status: data.receipt_status, receipt }
    });
  },
  _setArrayProps(arr, id, props) {
    const clone = (arr || []).slice();
    const obj = clone?.find((i) => i._id === id);
    if (obj) Object.assign(obj, props);
    return clone;
  },

  actions: {
    TRANSACTIONS_LIST_SET: (action) => action.params.data,
    TRANSACTION_ADD_RECEIPT: (action, state) => {
      const { id, receipt_status, receipt } = action.params;
      return TransactionsController._setArrayProps(state, id, { receipt_status, receipt });
    },
    TRANSACTION_SIGN_ACT: (action, state) => {
      const { id, receipt_status } = action.params;
      return TransactionsController._setArrayProps(state, id, { receipt_status });
    }
  },
}

export default TransactionsController;
