import React, { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import CurrencyInput from 'react-currency-input-field'

import Icons from 'utils/Icons';

import { handleGetInvoice } from './service'

function ModalRefillBalance({ name, contractorId, onSubmit }) {
    const [amount, setAmount] = useState(1000)

    const handleSubmit = useCallback(() => {
        handleGetInvoice(contractorId, amount)
        onSubmit()
    }, [onSubmit, amount])

    const handleAmountChange = useCallback((value) => {
        setAmount(value)
    }, [setAmount])

    return createPortal(
        <div className="refill-container">
            <div className="refill-header">
                <span className="refill-header__title">
                    Пополнить баланс
                </span>

                <span className="refill-header__close" onClick={onSubmit}>
                    {Icons.close}
                </span>
            </div>

            <div className="refill-body">
                <span className="refill-name">
                    {name}
                </span>

                <CurrencyInput
                    id="input-example"
                    name="input-name"
                    placeholder="Введите сумму"
                    defaultValue={amount}
                    decimalsLimit={2}
                    onValueChange={handleAmountChange}
                    suffix=" ₽" />
            </div>

            <div className="refill-type">
                <span className="refill-type__title">
                    СПОСОБ ОПЛАТЫ
                </span>

                <div className="refill-radio refill-radio--disabled">
                    <label htmlFor="refill-pay-card">Банковская карта</label>
                    <input type="radio" id="refill-pay-card" disabed="true" checked={false} onChange={() => { }} />
                </div>

                <div className="refill-radio">
                    <label htmlFor="refill-pay-invoice">Выставить счёт</label>
                    <input type="radio" id="refill-pay-invoice" checked onChange={() => { }} />
                </div>
            </div>

            <div className="refill-button">
                <button className="btn btn_primary btn_fullwidth" onClick={handleSubmit}>
                    Скачать PDF
                </button>
            </div>
        </div>,
        document.getElementById('root')
    )
}

export default ModalRefillBalance
