import React, { useContext } from 'react';

import Context from '../../../Context';

const ActionTitle = () => {
  const { data, timeLeftHours, timeLeftMinutes, timeLeftDays } =
    useContext(Context);

  const getText = () => {
    if (
      ['booked', 'going', 'doccheck'].includes(data?.status) &&
      timeLeftDays === 0
    ) {
      if (timeLeftMinutes < 30 && timeLeftHours === 0) {
        return 'Вы на месте?';
      }

      if (timeLeftHours < 16 && data.status !== 'going') {
        return 'Подтвердите бронирование';
      }
    }

    switch (data.status) {
      case 'going':
      case 'doccheck':
      default:
        return 'Подработка забронирована';

      // опоздания
      case 'delayed':
      case 'waiting':
        return `Вы опаздываете на ${data.delay} минут`;
      case 'toolate':
        return 'Вы опоздали';

      // чекин-работа
      case 'checkingin':
      case 'checkingout':
        return 'Не удалось отметить вас';

      case 'inprogress':
        return 'Работа идёт!';

      case 'completed':
      case 'confirmed':
        return 'Работа сделана';

      // ошибки
      case 'cancelled':
        return data.initiator === 'worker'
          ? 'Вы отменили смену'
          : 'Смена отменена 😪';
      case 'failed':
        return 'Похоже, вы не пришли на работу';
      case 'disputed':
        return 'У заказчика есть претензии';
    }
  };

  const text = getText();
  if (!text) return null;
  return <div className="content_block-title mb2">{text}</div>;
};

export default ActionTitle;
