import React from 'react';
import moment from 'moment';
import api from 'controllers/Api';
import { simulateModalLayout } from 'utils/Helpers';
import OrderDetails from './components/OrderDetails';
import Preloader from 'components/Preloader';
import { Header } from 'components/Modal2';

class OrderPage extends React.Component {

  state = {
    data: null,
    isLoading: true,
  }

  componentDidMount = () => {
    const id = this.props.match?.params?.id;
    const date = this.props.match?.params?.date || moment().format('YYYY-MM-DD');
    const data = this.props.location?.state?.data;

    if (data) {
      this.setState({
        data,
        isLoading: false
      });
    }

    api.get(`/order/${id}/${date}`)
    .then(r => {
      this.setState({
        data: {
          ...r.data,
        },
        isLoading: false,
      });
    });

    simulateModalLayout();
  }

  componentWillUnmount = () => {
    simulateModalLayout(false);
  }

  render = () => {
    const { isLoading, data } = this.state;

    if (isLoading) return <Preloader className="layout_tall" />;
    return (
      <>
        <Header title="Детали заказа" close="/orders" />
        <div>
          <OrderDetails data={data} />
        </div>
      </>
    );
  }
}

export default OrderPage;
