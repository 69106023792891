import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Login from 'components/ModalAuth/components/Login';
import { Header } from 'components/Modal2';
import { simulateModalLayout } from 'utils/Helpers';

const LoginContainer = ({ disableClose, disableRedirect }) => {
  const history = useHistory();

  useEffect(() => {
    simulateModalLayout();
    return () => {
      simulateModalLayout(false);
    };
  }, []);

  const close = () => {
    history.push('/');
  };

  return (
    <>
      <Header close="/" />
      <div>
        <div className="p2 pt3">
          <div className="alert_header flex mb3">
            <div className="alert_title">Авторизация</div>
            <span role="img" aria-label="success" className="alert_icon">
              👋🏼
            </span>
          </div>
          <Login
            close={close}
            disableRedirect={disableRedirect}
            disableClose={disableClose}
          />
        </div>
      </div>
    </>
  );
};

LoginContainer.propTypes = {
  disableClose: PropTypes.bool,
  disableRedirect: PropTypes.bool,
};

LoginContainer.defaultProps = {
  disableClose: false,
  disableRedirect: false,
};

export default LoginContainer;
