import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';

import Job from 'containers/Job';
import UserController from 'controllers/User';

import FilterController from 'controllers/Filter';
import qs from 'qs';
import SearchBox from './components/Search';
import Map from './components/Map';
import List from './components/List';

const Search = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const listingType = useSelector((s) => s.jobs.listingType);
  const mobileTeam = useSelector((s) => s.user.mobile_team);
  const role = useSelector((s) => s.user.role);

  const [isLoading, setIsLoading] = useState(true);

  const fetch = useCallback(
    async (filters = {}) => {
      setIsLoading(true);

      const { preparedFilters, otherSearchParams } = FilterController.prepare();

      history.push({
        search: `?${qs.stringify(
          { ...preparedFilters, ...otherSearchParams },
          { encode: false },
        )}`,
      });

      delete otherSearchParams.referrer;
      delete otherSearchParams.ym_tracking_id;

      await UserController.getJobs({
        ...preparedFilters,
        ...otherSearchParams,
        ...filters,
      });

      setIsLoading(false);
    },
    [history],
  );

  const onChangeListingType = (e) => {
    const { type } = e.currentTarget.dataset;
    dispatch({
      type: 'SEARCH_DATA_SET',
      params: {
        listingType: type,
      },
    });
  };

  // редирект если роль с кэша/стора
  if (role === 'employer' || role === 'supervisor') {
    return <Redirect to="/orders" />;
  }

  return (
    <>
      <Switch>
        <Route path="/jobs/:id" key="job" component={Job} exact />
      </Switch>
      {listingType === 'map' && <Map fetch={fetch} isLoading={isLoading} />}
      <SearchBox
        onChangeListingType={onChangeListingType}
        listingType={listingType}
        mobile_team={mobileTeam}
      />
      {listingType === 'list' && <List fetch={fetch} isLoading={isLoading} />}
    </>
  );
};

export default Search;
