import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import api from 'controllers/Api';
import Preloader from 'components/Preloader';
import Icons from 'utils/Icons';
import history from 'controllers/History';

class Notifications extends React.Component {

  state = {
    data: [],
    isLoading: true,
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.fetch();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  fetch = () => {
    const { isLoading } = this.state;
    if(!isLoading) this.setState({ isLoading: true });
    api.get(`/notifications?limit=50`)
    .then(response => {
      this.setState({
        data: response.data,
        isLoading: false,
      });
    });
  }

  /* readNotifications = () => {
    setTimeout(() => {
      if(!this._isMounted) return;
      let { data } = this.state;
      let { notificationsCount } = this.props.user;
      const ids = data.filter(item => !item.deleted).map(item => item._id);
      ids.forEach(id => {
        api.delete(`/notification/${id}`);
      });

      data = data.map(item => {
        item.deleted = true;
        return item;
      });

      this.setState({ data });

      this.props.dispatch({
        type: 'USER_SET',
        params: {
          notificationsCount: notificationsCount - ids.length,
        }
      });

    }, 3000);
  } */

  onClickItem = async (e) => {
    const { id } = e.currentTarget.dataset;
    const { data } = this.state;
    const notification = data.find(item => item._id === id);
    const action = notification.actions[0];
    if (action.name !== 'openLink') return;

    if (!notification.deleted) await api.delete(`/notification/${id}`);

    // TODO: при возврате через "назад" в браузере берутся старые кэшированные уведомления
    if (action.param.match(/^http/)) {
      document.location = action.param;
    } else {
      history.push(action.param);
      const { notificationsCount } = this.props.user;

      // Отметаем уведомления не из ифнс и уже прочитанные
      if (notificationsCount > 0 && notification.is_bubble && !notification.deleted) {
        this.props.dispatch({
          type: 'USER_SET',
          params: {
            notificationsCount: notificationsCount - 1,
          }
        });
      }
    }
  }

  parseText = (text) => {
    const segs = text?.split(': http');
    return segs?.length > 1 ? `${segs[0]}.` : text;
  }

  renderItems = () => {
    const { data } = this.state;
    if(!data.length) return <div className="p2">Нет уведомлений</div>;
    return data.map(item => {
      let className = `p2 bb notifications_item`;
      // if(item.deleted) className += ` notifications_item-readed`;
      const text = this.parseText(item.text);

      if(!item.title) item.title = text;

      let date = 'сегодня';
      const daysAgo = moment().diff(item.createdAt, 'days');
      if(daysAgo === 1) {
        date = 'вчера';
      } else {
        date = moment(item.createdAt).format('DD.MM')
      }

      let openLink = null;
      if(item.actions[0]?.name === 'openLink') openLink = true;

      return (
        <div className={className} key={item._id} onClick={openLink && this.onClickItem} data-id={item._id}>
          <div className="mb1 flex flex_center">
            {!item.deleted && item.is_bubble && <div className="notifications_item-circle" />}
            <strong className="text_medium ellipsis">{item.title}</strong>
            <span className="ml1 color_gray">{date}</span>
          </div>
          <div className="flex">
            <div>{text}</div>
            {openLink && Icons.next}
          </div>
        </div>
      )
    });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <h1 className="p2 mt1">Уведомления</h1>
        <div className="bb"></div>
        <div className="">
          {isLoading ? <div className="p2"><Preloader /></div> : this.renderItems()}
        </div>
      </>
    );
  }

}

export default connect(state => ({ user: state.user }))(Notifications);
