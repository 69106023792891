import React from 'react';
import PropTypes from 'prop-types';

const MGStarIcon = ({ color, size }) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <rect
        width="16"
        height="16"
        rx="8"
        fill="url(#paint0_linear_6079_343578)"
      />
      <path
        d="M8 3L9.73397 5.6134L12.7553 6.45492L10.8056 8.9116L10.9389 12.0451L8 10.95L5.06107 12.0451L5.19438 8.9116L3.24472 6.45492L6.26603 5.6134L8 3Z"
        fill="white"
      />
      <path
        d="M8 3L9.73397 5.6134L12.7553 6.45492L10.8056 8.9116L10.9389 12.0451L8 10.95L5.06107 12.0451L5.19438 8.9116L3.24472 6.45492L6.26603 5.6134L8 3Z"
        fill="url(#paint1_linear_6079_343578)"
        fillOpacity="0.7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6079_343578"
          x1="8"
          y1="20.5"
          x2="8"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.559616" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6079_343578"
          x1="8"
          y1="3"
          x2="8"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </>
);

MGStarIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

MGStarIcon.defaultProps = {
  color: '#F7CE17',
  size: 16,
};

export default MGStarIcon;
