import React, { lazy, Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { simulateModalLayout } from 'utils/Helpers';

export const title = 'Статус самозанятого';

// модуль также используется в модалке при бронировании
export default function Wrapper() {
  const [route, setRoute] = useState('Home');

  const routes = {
    Home: lazy(() => import('./screens/Home')),
    Confirm: lazy(() => import('./screens/Confirm')),
    Register: lazy(() => import('./screens/Register')),
    Invalid: lazy(() => import('./screens/Invalid'))
  };

  const actions = {
    navigate: (route) => setRoute(route)
  };

  useEffect(() => {
    simulateModalLayout();
    return () => simulateModalLayout(false);
  });

  const Route = routes[route];

  return (
    <Suspense fallback={<div>Загрузка...</div>}>
      <Route actions={actions} />
    </Suspense>
  );
}

export const SelfEmployedConfirmation = connect(state => ({ user: state.user }))(Wrapper);
