import React, { useEffect } from 'react';
import Icons from 'utils/Icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { simulateModalLayout, formatPrice } from 'utils/Helpers';
import { baseURL } from 'controllers/Api';
import { Header } from 'components/Modal2';
import SelfEmployedConfirmationAlert from 'components/SelfEmployedConfirmationAlert';

import Documents from './components/Documents';

const Profile = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    simulateModalLayout();
    return () => simulateModalLayout(false);
  }, []);

  const {
    id,
    balance,
    firstname,
    lastname,
    phone,
    is_self_employed: isSelfEmployed,
  } = user;

  return (
    <>
      <Header title="Профиль" back="/" />
      <div>
        <div className="profile_page-header pv3">
          <div
            className="profile_page-header-avatar mb2"
            style={{ backgroundImage: `url(${baseURL}/user/${id}/avatar)` }}
          />
          <h1 className="profile_page-header-name mb1">
            {firstname} {lastname}
          </h1>
          <strong>+{phone}</strong>
        </div>
        {!isSelfEmployed && <SelfEmployedConfirmationAlert />}
        <div className="content_block">
          <div className="content_block-header">
            <div className="content_block-title">Доступно к получению</div>
            <Link to="/profile/balance" className="content_block-link">
              {formatPrice(balance)} {Icons.next}
            </Link>
          </div>
        </div>
        <Documents />
      </div>
    </>
  );
};

export default Profile;
