import React, { useEffect, useState } from 'react';
import { Router, BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'moment/locale/ru';
// import 'react-toastify/dist/ReactToastify.css';
import 'rc-tooltip/assets/bootstrap_white.css';

import Routes from 'controllers/Router';
import history from 'controllers/History';
import Header from 'components/Header';
import { Provider } from 'react-redux';
import store from 'reducers';
import UserController from 'controllers/User';

import Api from 'controllers/Api';
import Modal from 'components/Modal';
import Job from 'containers/Job';
import ModalCancelJob from 'components/ModalCancelJob';
import ModalAuth from 'components/ModalAuth';
import ModalRegistration from 'components/ModalRegistration';
import SmartAppBanner from 'components/SmartAppBanner';
import CookiesPopup from 'components/CookiesPopup';
import CitiesList from 'components/CitiesList';
import TagsController from 'controllers/Tags';
import { MONTHS_SHORT } from 'utils/Constants';
import AcceptOferta from 'components/AcceptOferta';
import DictionariesController from './controllers/Dictionaries';

moment.locale('ru', {
  monthsShort: MONTHS_SHORT,
});

// временно скрываем выбор города до выяснения подробностей запуска в регионах
const IS_CITY_SELECTION_ENABLED = false;

const App = () => {
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    TagsController.init();
    UserController.check();
    DictionariesController.init();

    try {
      const newCity = JSON.parse(localStorage.getItem('city'));

      if (!newCity) {
        localStorage.removeItem('city');

        throw new Error('No city found');
      } else {
        setCity(newCity);
      }
    } catch {
      Api.get('/info/cities')
        .then((response) => response.data)
        .then(setCities);
    }

    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onSelectCity = (newCity) => {
    setCity(newCity);

    localStorage.setItem('city', JSON.stringify(newCity));
  };

  const content = Routes.map((route) => <Route key={route.path} {...route} />);

  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey="6Lek63QlAAAAAMuUPqHmedpukVJf5NPmcUAeAL9J">
        <SmartAppBanner />

        {(city || !IS_CITY_SELECTION_ENABLED) && (
          <BrowserRouter>
            <Router history={history}>
              <>
                <Header />
                <div className="layout">
                  <Switch>{content}</Switch>
                </div>
                <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
                <Modal id="Job">
                  <Job />
                </Modal>
                <Modal id="ModalCancelJob">
                  <ModalCancelJob />
                </Modal>
                <Modal id="ModalAuth">
                  <ModalAuth />
                </Modal>
                <Modal id="ModalRegistration">
                  <ModalRegistration />
                </Modal>
                <CookiesPopup />
              </>
            </Router>
          </BrowserRouter>
        )}

        <AcceptOferta />

        {IS_CITY_SELECTION_ENABLED && !city && (
          <CitiesList source={cities} onSelect={onSelectCity} />
        )}
      </GoogleReCaptchaProvider>
    </Provider>
  );
};

export default App;
