import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import history from 'controllers/History';
import store from 'reducers';
import Icons from 'utils/Icons';
import api from 'controllers/Api';
import { Alert } from 'utils/Helpers';
import TagsController from 'controllers/Tags';

const getErrText = (err) => `Произошла ошибка, попробуйте позже или обратитесь в поддержку (+7-495-215-53-58).
\nКод ошибки: ${err.toString()}
\n${err.response?.data?.error || ''}`;

const FNS_CONN_TEXT = {
  'on': 'Подключено',
  'off': 'Отключено',
  'pending': 'Ожидается подтверждение',
};

const FNS_CONN_ICON = {
  'on': '✅',
  'off': '⛔️',
  'pending': '⚠️',
};

class FNSConnection extends React.Component {
  state = {
    status: this.props.user.fns_connection_status,
    isSending: false,
  }

  componentDidMount() {
    if (this.props.user.fns_connection_status !== 'off') {
      this.setState({ isSending: true });
      api.get(`/user/fns/refreshstatus`)
      .then(r => {
        this.setState({ status: r.data.fns_connection_status });
        const { is_self_employed, firstname, lastname, middlename } = r.data;
        console.log('is_self_employed', is_self_employed);
        if (typeof is_self_employed !== 'undefined') {
          if (this.props.user.is_self_employed !== is_self_employed) {
            Alert({
              title: <>Обновление статуса</>,
              icon: is_self_employed ? '👌' : '⛔️',
              text: (is_self_employed ? <>Вы являетесь самозанятым</> : <>Нет сведений о статусе самозанятого</>),
              buttons: (onClose) => (
                <>
                  <div className="btn btn_small btn_primary" onClick={onClose}>Закрыть</div>
                </>
              ),
            });
          }
          store.dispatch({
            type: 'USER_SET',
            params: { is_self_employed, firstname, lastname, middlename }
          });
        }
        this.setState({ isSending: false });
      })
      .catch(err => {
        alert(getErrText(err));
        this.setState({ isSending: false });
      });
    }
  }

  /**
   * Отправить запрос на подключение
   */
  onClickConnect = () => {
    if (!this.props.user.inn) {
      history.push('/profile/self-employed-confirmation');
      return;
    }
    this.setState({ isSending: true });
    api.post(`/user/fns/connect`)
    .then(r => {
      this.setState({ isSending: false });
      const autoReceiptEnabled = r.data.fns_connection_status === 'on';
      TagsController.event('Actions', 'AutoIssueChecks', autoReceiptEnabled ? 'true' : 'false');

      if (autoReceiptEnabled) {
        this.onClickRefresh();
        return;
      }

      if (r.data.is_self_employed === false) {
        Alert({
          title: <>Нужно стать самозанятым</>,
          icon: '⛔️',
          text: (<>
            Это можно сделать в приложении Мой Налог. <br/>
            Мы описали весь процесс пошагово в нашей инструкции: <br/><br/>
            <a target="_blank" rel="noopener noreferrer" href="https://mygig.zendesk.com/hc/ru/articles/360007583338-%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%BA%D0%B0%D0%BA-%D1%81%D1%82%D0%B0%D1%82%D1%8C-%D1%81%D0%B0%D0%BC%D0%BE%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D1%8B%D0%BC-">Как стать самозанятым?</a>
          </>),
          buttons: (onClose) => (
            <>
              <div className="btn btn_small btn_primary" onClick={onClose}>Закрыть</div>
            </>
          ),
        });
      } else {
        Alert({
          title: <>Отправили запрос</>,
          icon: '😉',
          text: (<>Откройте приложение Мой Налог. <br/> В списке партнёров нажмите "Подтвердить" для MyGig.</>),
          buttons: (onClose) => (
            <>
              <div className="btn btn_small btn_primary" onClick={onClose}>Хорошо</div>
            </>
          ),
        });
        this.setState({ status: 'pending' });
      }
    })
    .catch(err => {
      console.error(err.response);
      alert(getErrText(err));
      this.setState({ isSending: false });
    });
  }

  /**
   * Отправить запрос на отключение
   */
  onClickDisconnect = () => {
    api.post(`/user/fns/disconnect`)
    .then(r => {
      Alert({
        title: <>Отключено</>,
        icon: '👌',
        text: (<>Теперь чеки нужно выставлять самостоятельно.</>),
        buttons: (onClose) => (
          <>
            <div className="btn btn_small btn_primary" onClick={onClose}>Хорошо</div>
          </>
        ),
      });
      this.setState({ status: 'off' });
    })
    .catch(err => {
      console.error(err.response);
      alert(getErrText(err));
    });
  }

  /**
   * Отправить запрос за текущим статусом
   */
  onClickRefresh = () => {
    this.setState({ isSending: true });
    api.get(`/user/fns/refreshstatus`)
    .then(r => {
      const { fns_connection_status: status, is_self_employed } = r.data;
      this.setState({ status });

      Alert({
        title: <>{FNS_CONN_TEXT[status]}</>,
        icon: FNS_CONN_ICON[status],
        text: (<></>),
        buttons: (onClose) => (
          <>
            <div className="btn btn_small btn_primary" onClick={onClose}>Закрыть</div>
          </>
        ),
      });

      store.dispatch({
        type: 'USER_SET',
        params: { is_self_employed }
      });
      this.setState({ isSending: false });
    })
    .catch(err => {
      console.error(err.response);
      alert(getErrText(err));
      this.setState({ isSending: false });
    });
  }

  /**
   * Текущее состояние (на фронте) и возможность отключить
   */
  onClickStatus = () => {
    Alert({
      title: <>Чеки выставляются автоматически</>,
      icon: '📃',
      text: (<>Выставлять чеки вручную больше не нужно!
        По окончанию смены, чек будет выставлен автоматически.
        Готовый чек сам появится в приложении Мой Налог.</>),
      buttons: (onClose) => (
        <>
          <div className="btn btn_small" onClick={this.onClickDisconnectConfirm}>Отключить</div>
          <div className="btn btn_small btn_primary" onClick={onClose}>Закрыть</div>
        </>
      ),
    });
  }

  /**
   * Подтверждение отключения
   */
  onClickDisconnectConfirm = () => {
    Alert({
      title: <>Отключить?</>,
      icon: '📃',
      text: (<>MyGig не сможет формировать чеки автоматически,
        вам придётся делать это вручную в приложении Мой Налог.
        Вы уверены, что хотите отключить автовыставление чеков?</>),
      buttons: (onClose) => (
        <>
          <div className="btn btn_small" onClick={this.onClickDisconnect}>Отключить</div>
          <div className="btn btn_small btn_primary" onClick={onClose}>Не отключать</div>
        </>
      ),
    });
  }

  render () {
    const { status, isSending } = this.state;

    if (status === 'on') return (
      <div className="flex flex_center justify_center color_blue bb pb2">
        <span onClick={this.onClickStatus}>Чеки выставляются автоматически &nbsp;</span>{Icons.checkBlue}
      </div>
    );

    return (
      <div className="content_block bg_blue color_white">
        <div className="content_block-title-alt color_white mb2"><strong>Автовыставление чеков</strong></div>

        {(!status || status === 'off') &&
        <div>
          <p className="mb2">
            Выставлять чеки вручную больше не нужно!
            По окончанию смены, чек будет выставлен автоматически.
            Готовый чек сам появится в приложении Мой Налог.
          </p>
          <div className="text-right">
            <div className="btn btn_small" disabled={isSending} onClick={this.onClickConnect}>Подключить</div>
          </div>
        </div>}

        {status === 'pending' &&
        <div>
          <p className="mb2">
            Откройте приложение Мой Налог. <br/> В списке партнёров нажмите "Подтвердить" для MyGig.
          </p>
          <div className="text-right">
            <div className="btn btn_small" disabled={isSending} onClick={this.onClickRefresh}>Проверить</div>
          </div>
        </div>}
      </div>
    );
  }
}

export default connect(state => ({ user: state.user }))(FNSConnection);
