import React, { Component } from 'react';
import Cookies from 'js-cookie'

import api from 'controllers/Api';
import history from 'controllers/History';

import counter from './counter.svg';

class Banner extends Component {
  state = {
    showBanner: false,
    isLoading: true,
  }

  isClosedCookie = () => {
    const cookie = Cookies.get('is_closed')
    const isClosed = cookie && cookie !== 'false'

    return isClosed || false
  }

  componentDidMount = () => {
    if (this.isClosedCookie()) {
      return;
    }

    this.fetch();

    this.setState({ refreshTimer: setInterval(() => {
        console.log('fetching banners');
        this.fetch();
      }, 10000) });
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshTimer);
  }

  fetch = () => {
    const { isLoading } = this.state;
    if (!isLoading) this.setState({ isLoading: true });

    api.get('/show_banner')
      .then(response => {
        this.setState({
          showBanner: response.data,
          isLoading: false
        });
      });
  }

  hideBanner = () => {
    Cookies.set('is_closed', true, { expires: 1 })
    this.setState({ showBanner: false })
  }

  onClick = (e) => {
    history.push('/rates');
  }

  render() {
     const banner = (
      <div className="order_page-banner">
        <div>
          <div className="title">Советуем повысить почасовую ставку</div>
          <div className="info">
            До начала некоторых заказов осталось
            меньше 24 часов, но никто их не забронировал.
            Попробуйте повысить ставку до рекомендованной, чтобы быстрее найти исполнителей.
          </div>
        </div>
          <div className="hide" onClick={this.hideBanner}>Спросить позже</div>
          <button className="rise" onClick={this.onClick}>
            <img src={counter}></img>
            Повысить ставки
          </button>
      </div>);

    return this.state.showBanner ? banner : '';
  }
}

export default Banner;
