
const initialState = {
  basicProfessions: [
    {
      "_id": "5e3c13332beb249d04b593ff",
      "except": [],
      "documents": [],
      "skills": [],
      "deleted": false,
      "is_basic": true,
      "custom_name": "Грузчик",
      "spec": "cargo",
      "employer": "5dfc861efe302b2cf4d5c432",
      "workplace": "5e26b9e642deb2eb56dfdee0",
      "client": "5e26b90342deb2eb56dfdedf",
      "createdAt": "2020-02-06T13:22:59.481Z",
      "updatedAt": "2020-02-06T13:22:59.481Z",
      "__v": 0,

      "amount": 2,
      // "start": "2020-02-06T13:22:59.481Z",
      "start_time": "10:00",
      "finish_time": "19:00",
      "salary_per_hour": 300,
      "repeat": ['ЧТ', 'ПТ'],
    },
    {
      "_id": "5e3c13522beb249d04b59400",
      "except": [],
      "documents": [],
      "skills": [],
      "deleted": false,
      "is_basic": true,
      "custom_name": "Кассир",
      "spec": "cash",
      "employer": "5dfc861efe302b2cf4d5c432",
      "workplace": "5e26b9e642deb2eb56dfdee0",
      "client": "5e26b90342deb2eb56dfdedf",
      "createdAt": "2020-02-06T13:23:30.290Z",
      "updatedAt": "2020-02-06T13:23:30.290Z",
      "__v": 0,

      "amount": 1,
      // "start": "2020-02-06T13:22:59.481Z",
      "start_time": "07:00",
      "finish_time": "15:00",
      "salary_per_hour": 900,
      "repeat": ["everyday"]
    }
  ],
  customTemplates: [
    {
      "_id": "5e3c13922beb249d04b59401",
      "except": [],
      "documents": [],
      "skills": [],
      "deleted": false,
      "is_basic": false,
      "custom_name": "Кассир-продавец",
      "spec": "cash",
      "employer": "5dfc861efe302b2cf4d5c432",
      "workplace": "5e26b9e642deb2eb56dfdee0",
      "client": "5e26b90342deb2eb56dfdedf",
      "createdAt": "2020-02-06T13:24:34.264Z",
      "updatedAt": "2020-02-06T13:24:34.264Z",
      "__v": 0,

      "amount": 2,
      // "start": "2020-02-06T13:22:59.481Z",
      "start_time": "11:30",
      "finish_time": "22:30",
      "salary_per_hour": 100,
      "repeat": null,
    }
  ],
  cart: [],
  currentOrderItemState: null,
};

export default (state = initialState, action) => {
  let newState = state;
  switch (action.type) {

    case "ORDER_ITEM_SET_CURRENT":
      newState.currentOrderItemState = action.params;
      break;

    case "ORDER_ITEM_CLEAR_CURRENT":
      newState.currentOrderItemState = null;
      break;

    case "ORDER_CART_ADD":
      newState.cart.push(action.params);
      break;

    case "ORDER_CART_UPDATE":
      let { cartId } = action.params;
      const index = newState.cart.findIndex(item => item.cartId === cartId);
      if(index >= 0) newState.cart[index] = action.params;
      break;

    case "ORDER_CART_DELETE":
      newState.cart = newState.cart.filter(item => item.cartId !== action.params.cartId);
      break;

    case "ORDER_CART_RESET":
      newState.cart = [];
      break;

    default:
  }
  return newState;
}
