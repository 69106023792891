import React, { lazy, Suspense } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Router, BrowserRouter, Link } from 'react-router-dom';
import { Provider } from 'react-redux';

import history from 'controllers/History';
import store from 'reducers';
import Icons from 'utils/Icons';

const makeId = () => Math.random().toString(36).slice(2);
const timing = 100;

class Modal extends React.PureComponent  {

  componentDidMount = () => {
    const historyListener = (() => {
      let currentPath = history.location.pathname
      return (newHistory) => {
        if (currentPath !== newHistory.pathname) {
          this.close();
        }
      };
    })()


    setTimeout(() => {
      const { id } = this.props;
      document.getElementById(id).classList.add('__open');
    }, timing);

    this.unlisten = history.listen(historyListener);
  }

  componentWillUnmount = () => {
    this.unlisten && this.unlisten();
  }

  close = () => {
    const { id } = this.props;
    document.getElementById(id).classList.remove('__open');
    setTimeout(() => {
      removeElementReconfirm(id);
    }, timing + 100);
  }

  render = () => {
    const Component = lazy(() => import(`components/${this.props.component}`));
    const props = { ...this.props, close: this.close };
    delete props.id;
    delete props.component;
    return (
      <div className='modal2'>
        <Suspense fallback={<div>...</div>}>
          <Provider store={store}>
            <BrowserRouter>
              <Router history={history}>
                <Component {...props} />
              </Router>
            </BrowserRouter>
          </Provider>
        </Suspense>
      </div>
    );
  }

}

function createElementReconfirm (props) {
  const id = makeId();
  const target = document.createElement('div');
  target.id = id;
  target.className = `modal2_overlay`;
  document.body.appendChild(target);
  render(<Modal {...props} key={id} id={id} />, target);
}

function removeElementReconfirm (id) {
  const target = document.getElementById(id)
  if (target) {
    unmountComponentAtNode(target)
    target.parentNode.removeChild(target)
  }
}

export default (name, props = {}) => {
  props.component = name;
  createElementReconfirm(props);
}

// todo delete
window.Modal2 = (name, props = {}) => {
  props.component = name;
  createElementReconfirm(props);
}

export const Header = (props) => {
  let back = null;
  if(typeof props.back === 'string') {
    if(props.navigate) {
      back = <div className='modal_header-back' onClick={props.navigate} data-route={props.back}>{Icons.back}</div>;
    } else {
      back = <Link to={props.back} className='modal_header-back'>{Icons.back}</Link>;
    }
  } else if (typeof props.back === 'function') {
    back = <div className='modal_header-back' onClick={props.back}>{Icons.back}</div>;
  }


  let close = null;
  if(props.close) {
    if(typeof props.close === 'function') {
      close = <div className='modal_header-close' onClick={props.close}>{Icons.close}</div>;
    } else {
      close = <Link to={props.close} className='modal_header-close'>{Icons.close}</Link>;
    }
  }

  let suffix = null;
  if(props.suffix) {
    suffix = <div className='mla'>{props.suffix}</div>;
  }

  return (
    <div className='header modal_header'>
      {back}
      {props.title ? (
        <div className='modal_header-title text_medium'>{props.title}</div>
      ) : (
        <div className='header_logo'>
          <Link to='/'><img src='/img/logo.svg' alt="" /></Link>
        </div>
      )}
      {close}
      {suffix}
    </div>
  );
}

export class Wrapper extends React.Component {

  state = {
    route: null,
  }

  _context = null;

  componentDidMount = () => {
    const { initialState } = this.props;
    if(initialState) {
      this.setState({ ...initialState })
    }
  }

  navigate = (e) => {
    let route;
    if(typeof e === 'string') {
      route = e;
    } else {
      route = e.currentTarget.dataset.route;
    }
    this.setState({ route });
  }

  render() {
    const { close } = this.props;
    const { route } = this.state;
    if(!route || !this._context) return null;

    const Route = this.routes[route];

    const value = {
      state: this.state,
      navigate: this.navigate,
      close,
      ...this.props,
      functions: this.functions,
      actions: this.actions,
    }

    return (
      <this._context.Provider value={value}>
        <Suspense fallback={<div>Загрузка...</div>}>
          <Route />
        </Suspense>
      </this._context.Provider>
    );
  }

}
