import Cookies from 'js-cookie';

// const state = localStorage.getItem('userState');
// state ? JSON.parse(state) :
// console.log(Cookies.get('role'));

export const initialState = {
  current: null,
  accounts: [],
  role: Cookies.get('role') || null, // некий кэш для меню/карты и тд
  currentLocation: null,
  currentCity: Cookies.get('city') || 'Москва',
  locationAccess: null,

  isAuthorized: null,
  id: null,
  balance: 0,
  firstname: null,
  lastname: null,
  middlename: null,
  birthday: null,
  gender: null,
  inn: null,
  passport_series: null,
  passport_number: null,
  phone: null,
  accept_agreement: false,
  accept_oferta: false,
  is_self_employed: null,

  hasTalkbankJob: false,
  hasRaiffeisenJob: false,
  talkBankStatus: null,

  documents: [],
  cards: [],

  notificationsCount: 0,

  mobile_team: !!Cookies.get('mobile_team') || false,
  mobile_team_type: Cookies.get('mobile_team_type') || null, //'courier',
  mobile_team_contract_signed_at: null,

  permissions: {},
};

export default (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case 'USER_SET':
      nextState = {
        ...state,
        ...action.params,
      };
      break;

    case 'USER_SET_CURRENT':
      nextState = {
        ...state,
        current: action.params,
      };

      // add account if not exist, or switch to default
      //const exist = nextState.accounts.find(account => account.id === nextState.current.id);
      //if(!exist) nextState.accounts.push(action.params);
      localStorage.setItem('userState', JSON.stringify(nextState));
      break;

    case 'USER_SWITCH_CURRENT':
      const { id } = action.params;
      const current = nextState.accounts.find((account) => account.id === id);
      if (!current) return state;
      nextState = { ...state, current };
      localStorage.setItem('userState', JSON.stringify(nextState));
      break;

    default:
  }

  return nextState;
};
