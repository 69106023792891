import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { simulateModalLayout, Events, formatPrice } from 'utils/Helpers';
import Modal2, { Header } from 'components/Modal2';
import Icons from 'utils/Icons';
import { useApi } from 'controllers/Api';
import Preloader from 'components/Preloader';

import UserController from 'controllers/User';
import TagsController from 'controllers/Tags';
import TalkBankConnectPopup from '../../../components/TalkBankConnectPopup';

const Withdraw = () => {
  const [data, setData] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const balance = useSelector((state) => state.user.balance);
  const hasTalkbankJob = useSelector((state) => state.user.hasTalkbankJob);

  const { isRequesting: loading, errors, request } = useApi();

  const fetch = useCallback(async () => {
    const newData = await request({ url: `/user/cards` });
    setData(newData);
  }, [request]);

  useEffect(() => {
    simulateModalLayout();
    fetch();
    Events.follow(`WITHDRAW_CARDS_FETCH`, fetch);
    return () => {
      simulateModalLayout(false);
      Events.unfollow(`WITHDRAW_CARDS_FETCH`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCard = (e) => {
    const { id } = e.currentTarget.dataset;
    setSelectedCardId(id);
  };

  const renderItems = () => {
    return data?.map((item) => {
      const id = item.id || item._id;
      const isActive = id === selectedCardId;
      return (
        <div
          role="button"
          tabIndex={0}
          className="box p2"
          key={id}
          data-id={id}
          onClick={onClickCard}
        >
          <div className="mb1">
            <strong>{item.bank_name || 'Не распознан'}</strong>
          </div>
          <div>{item.number}</div>
          <div className={`radio ${isActive && 'active'}`} />
        </div>
      );
    });
  };

  const onSubmit = async () => {
    TagsController.event('Buttons', 'SendMoney', 'Balance');
    await request({
      url: `/user/withdraw`,
      method: 'POST',
      data: {
        amount: balance,
        card: selectedCardId,
      },
    });
    // UserController.check(); // обновление баланса
    await UserController.getBalance();
    Modal2('ModalWorkerWithdrawSuccess');
  };

  const onClickCardAdd = () => {
    TagsController.event('Buttons', 'AddBankCard', 'Small');
    Modal2('ModalWorkerCardAdd');
  };

  return (
    <>
      <Header title="Вывести деньги" back="/profile/balance" />
      <div>
        <div className="profile_page-header pv3">
          <h1 className="mb1">Доступно к получению</h1>
          <span className="profile-balance_amount mb2">
            {formatPrice(balance)}
          </span>
          {errors && <div className="form_error text_center">{errors}</div>}
        </div>
        <div className="content_block">
          <div className="content_block-header mb2">
            <div className="content_block-title">КУДА ПЕРЕВОДИМ</div>
            <div
              role="button"
              tabIndex={0}
              className="content_block-link"
              onClick={onClickCardAdd}
            >
              Добавить карту {Icons.plus}
            </div>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <div className="profile-cards_list">{renderItems()}</div>
          )}
          {!loading && !data?.length && (
            <div className="empty_state mt3">
              <span role="img" aria-label="card" className="empty_state-icon">
                💳️
              </span>
              <div className="empty_state-text">
                Добавьте счёт вашей банковской карты
              </div>
            </div>
          )}
        </div>
        <div className="modal_btn-holder" />
      </div>
      <div
        role="button"
        tabIndex={0}
        className="btn btn_primary modal_btn"
        onClick={onSubmit}
        disabled={!selectedCardId || loading}
      >
        {!loading ? 'Отправить перевод' : 'Отправляем...'}
      </div>
      {hasTalkbankJob && <TalkBankConnectPopup />}
    </>
  );
};

export default Withdraw;
