import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { formatPrice } from 'utils/Helpers';

import { fetchInitialState } from './service';

import ModalRefillBalance from '../../components/ModalRefillBalance';

function BalancePage() {
  const [companies, setCompanies] = useState([]);
  const [balance, setBalance] = useState([]);
  const [modalName, setModalName] = useState('');
  const [contractorId, setContractorId] = useState('');

  const valueClassName = useCallback(
    (value) =>
      classnames('balance-item__header-value', {
        'balance-item__header-value--green': value > 0,
      }),
    []
  );

  const handleModalSubmit = useCallback(() => {
    setModalName('');
  }, []);

  const handleOpenModal = useCallback((id, name) => {
    setContractorId(id);
    setModalName(name);
  }, []);

  useEffect(() => {
    fetchInitialState().then((payload) => {
      setCompanies(payload.companies);
      setBalance(payload.balance);
    });
  }, [modalName]);

  const totalBalance = useMemo(
    () =>
      companies.reduce(
        (result, company) => {
          const { depositTotal, paymentTotal } = company;

          return {
            depositTotal: result.depositTotal + depositTotal,
            paymentTotal: result.paymentTotal + paymentTotal,
          };
        },
        {
          depositTotal: 0,
          paymentTotal: 0,
        }
      ),
    [companies]
  );

  return (
    <>
      {modalName && (
        <ModalRefillBalance
          name={modalName}
          contractorId={contractorId}
          onSubmit={handleModalSubmit}
        />
      )}

      <div className="balance-container">
        <div className="balance-header">
          <span className="balance-header__title">Баланс</span>
          <div className="balance-header__list">
            {companies.map((company) => (
              <div className="balance-header__item" key={company._id}>
                <span className="balance-header__item-name">
                  {company.legal_name}
                </span>
                <div className="balance-header__item-amount">
                  <b>
                    {formatPrice(
                      company.depositTotal - company.paymentTotal,
                      true
                    )}{' '}
                    &#8381;
                  </b>
                </div>
                <div className="balance-header__item-actions">
                  <button
                    style={{ display: 'none' }}
                    className="btn btn_primary btn_fullwidth"
                    onClick={() =>
                      handleOpenModal(company._id, company.legal_name)
                    }
                  >
                    Пополнить
                  </button>
                </div>
              </div>
            ))}
          </div>
          {companies.length > 1 &&
            <div className="balance-total">
              <b>По всем компаниям: </b>
              {formatPrice(
                totalBalance.depositTotal - totalBalance.paymentTotal,
                true
              )}
              &#8381;
            </div>
          }
        </div>
        <div className="balance-body">
          <span className="balance-body__title">ИСТОРИЯ</span>

          <div className="balance-body__list">
            {balance.map((item) => (
              <div className="balance-item" key={item.createdAt}>
                <div className="balance-item__header">
                  <span className="balance-item__header-date">
                    {moment(item.createdAt).format('DD.MM.YYYY в HH:ss')}
                  </span>
                  <span className={valueClassName(item.value)}>
                    {formatPrice(item.value)}&#8381;
                  </span>
                </div>

                <span className="balance-item__name">{item.legal_name}</span>

                <span className="balance-item__type">{item.caption}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default BalancePage;
