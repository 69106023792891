import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from 'controllers/History';
import Icons from 'utils/Icons';
import { Events } from 'utils/Helpers';
import UserController from 'controllers/User';

import MenuIcons from './components/MenuIcons';
import ContactsSection from './components/ContactsSection';
import ProfileSection from './components/ProfileSection';
import MenuSection from './components/MenuSection';
import MenuInfo from './components/MenuInfo';
import UserMenuSection from './components/UserMenuSection';

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    history.listen(() => {
      setIsOpenMenu(false);
    });
  }, []);

  const toggleMenu = () => setIsOpenMenu((v) => !v);

  const onClickAuth = () => {
    Events.dispatch('MODAL_SHOW-ModalAuth');
  };

  const { isAuthorized, role, notificationsCount } = useSelector(
    (state) => state.user,
  );

  return (
    <>
      <div className="header flex">
        <div
          role="button"
          tabIndex={0}
          className="modal_header-back relative"
          onClick={toggleMenu}
        >
          {Icons.burger}
          {notificationsCount ? (
            <span className="bubble_count">{notificationsCount}</span>
          ) : null}
        </div>
        <Link to="/" className="header_logo">
          <img src="/img/logo.svg" alt="mygig" />
        </Link>
        <MenuSection onClickAuth={onClickAuth} />
      </div>
      <div className={`menu_overlay-bg ${isOpenMenu ? 'active' : ''}`} />
      <div className="menu_overlay">
        <div className="header flex">
          <div
            role="button"
            tabIndex={0}
            className="modal_header-back"
            onClick={toggleMenu}
          >
            {Icons.close}
          </div>
          <div className="header_logo">
            <img src="/img/logo_white.svg" alt="mygig" />
          </div>
        </div>
        <div className="p2 pt3">
          <ProfileSection onClickAuth={onClickAuth} />
          <hr />
          <UserMenuSection />
          <MenuInfo />
          <hr />
          <ContactsSection />
          <div className="menu_overlay-list">
            {isAuthorized === true && (
              <>
                <hr />
                <Link to="/" onClick={UserController.onLogout}>
                  {MenuIcons.exit} <span>Выход</span>
                </Link>
                {(role === 'employer' || role === 'supervisor') && (
                  <>
                    <hr />
                    <div
                      role="button"
                      tabIndex={0}
                      className="no-link"
                      onClick={UserController.onVersionSwitch}
                    >
                      Версия для компьютера
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
