import React from 'react';
import { connect } from 'react-redux';

import { simulateModalLayout, formatPrice } from 'utils/Helpers';
import { Header } from 'components/Modal2';
import { Link } from 'react-router-dom';
import Preloader from 'components/Preloader';
import SelfEmployedConfirmationAlert from 'components/SelfEmployedConfirmationAlert';
import FNSConnection from 'components/FNSConnection';
import TransactionItem from 'components/TransactionItem';
import Icons from 'utils/Icons';
import UserController from 'controllers/User';
import TransactionsController from 'controllers/Transactions';
import TagsController from 'controllers/Tags';
import SignAllActsAlert from './components/SignAllActsAlert';
import TalkBankConnectBanner from '../../../components/TalkBankConnectBanner';

class Balance extends React.Component {
  state = { isLoading: true };

  async componentDidMount() {
    simulateModalLayout();
    await Promise.all([
      TransactionsController.getList(),
      TransactionsController.getStats(),
    ]);

    this.setState({
      ...this.state,
      isLoading: false,
    });
    await UserController.getBalance();
  }

  componentWillUnmount() {
    simulateModalLayout(false);
  }

  renderTransactions = (items) => {
    if (!items.length) return <>У вас еще не было операций</>;
    return items.map((item) => <TransactionItem key={item._id} data={item} />);
  };

  onWithdrawClick = () => {
    TagsController.event('Buttons', 'TakeMoney', 'Balance');
  };

  render() {
    const { isLoading } = this.state;
    const { transactions, transactionsStats } = this.props;
    const {
      balance,
      is_self_employed,
      is_fns_enabled,
      hasTalkbankJob,
      hasRaiffeisenJob,
    } = this.props.user;

    const needSignActs = transactionsStats?.need_act > 0;

    return (
      <>
        <Header title="Счет" back="/profile" />
        <div>
          <div className="profile_page-header profile_page-balance-header pv3">
            <h1 className="mb1">Доступно к получению</h1>
            <strong className="profile-balance_amount mb2">
              {formatPrice(balance)}
            </strong>
            {balance === 0 ? (
              <Link to="/" className="btn btn_small btn_primary">
                Найти подработку
              </Link>
            ) : (
              <Link
                onClick={this.onWithdrawClick}
                to="/profile/balance/withdraw"
                className="btn btn_small btn_primary"
                disabled={!is_self_employed || balance <= 0}
              >
                Вывести деньги
              </Link>
            )}
          </div>
          {is_self_employed && (
            <div className="flex flex_center justify_center color_green4 mb1">
              Статус самозанятого подтверждён &nbsp;
              {Icons.checkGreen}
            </div>
          )}

          {/* banners */}
          {!is_self_employed ? (
            <SelfEmployedConfirmationAlert />
          ) : (
            <>
              {hasTalkbankJob && <TalkBankConnectBanner />}
              {is_fns_enabled && hasRaiffeisenJob && <FNSConnection />}
              {needSignActs && <SignAllActsAlert />}
            </>
          )}

          <div className="content_block">
            <div className="content_block-header mb2">
              <div className="content_block-title">История операций</div>
            </div>
            <div className="transactions-history">
              {isLoading ? (
                <Preloader />
              ) : (
                this.renderTransactions(transactions)
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect((state) => ({
  user: state.user,
  transactions: state.transactions,
  transactionsStats: state.env.transactionsStats,
}))(Balance);
