import React from 'react';
import Slider from 'rc-slider';

import { Header } from 'components/Modal2';
import history from 'controllers/History';
import api from 'controllers/Api';

import './index.styl';

const greenStyle = () => ({ style: "green", text: "Высокая" });
const orangeStyle = () => ({ style: "orange", text: "Средняя" });
const redStyle = () => ({ style: "red", text: "Низкая" });
const computeStyle = (style, color) => `${style} ${color}`;

class RateSlider extends React.Component {
  state = {
    data: [],
    value: 0,
    min: 0,
    mid: 50,
    max: 100,
  }

  componentDidMount = () => {
    const data = this.props?.location?.state;

    if (!data) {
      return;
    }

    const { rates = [] } = data;
    const [, mid, max] = rates;
    const { payment_per_hour: value } = data;

    this.setState({ min: 10, data, max, mid, value });
  }

  priceLabels() {
    const { rates } = this.state.data;
    const paymentPerHour = this.state.value;

    if (!rates) {
      return orangeStyle();
    }

    const [min, mid , max] = rates.sort();

    if (paymentPerHour > mid) {
      return greenStyle();
    }

    if (paymentPerHour < min) {
      return redStyle();
    }

    return orangeStyle();
  }

  renderSlider() {
    const value = this.state.value;
    const min = this.state.min;
    const max = this.state.max;
    const mid = this.state.mid;

    const { style, text } = this.priceLabels();

    return (
      <div className="slider-container">
        <div className= "resp-prob-title">Вероятность отклика</div>
        <div className={computeStyle('big-price-label', style)}>{value} <span>₽</span></div>
        <div className={computeStyle('small-price-label', style)}>{text}</div>
        <div className="content">
          <Slider
            value={value}
            min={min}
            max={max}
            trackStyle={{backgroundColor: 'transparent'}}
            handleStyle={{
              border: '1px solid #EEEEEE',
              height: 24,
              width: 24,
              marginLeft: '0px',
              marginTop: '-9px',
              style: {
                color: 'red'
              }
            }}
            handle={this.handle}
            onChange={(e) => this.setState({ value: e })}
          />
          <div className="marks">
            <div className="triangle-red"/>
            <div className="triangle-orange"/>
            <div className="triangle-green"/>
          </div>
        </div>
        <div className="marks prices">
          <div>{min} ₽</div>
          <div>{mid} ₽</div>
          <div>{max} ₽</div>
        </div>
      </div>
    );
  }

  async saveRate(data) {

    api.post('/rates', data)
      .then()
      .catch(console.log);
  }

  onClickRatesSave = async () => {
    const newRate = this.state.value;
    const { _id } = this.state.data;

    const updates = { _id, newRate };
    await this.saveRate([ updates ]);
    history.push('/rates');
  }


  render() {
    const data = this.state.data;
    const length = Object.entries(data).length;

    return (
      <>
        <Header title="Ставка в час" close="/rates"/>
        <div className="orders_list-day">
          <div className="orders_list">
            {
              !length &&
              <div className="layout_tall">
                <div className="empty_state">
                  <span role="img" aria-label="empty" className="empty_state-icon">🤷‍♂️</span>
                </div>
              </div>
            }
            {length && this.renderSlider()}
          </div>
        </div>
        <div className="btn btn_primary modal_btn" onClick={this.onClickRatesSave}>Сохранить изменения</div>
      </>
    );
  }
}

export default RateSlider;
