import moment from 'moment'
import Promise from 'bluebird'

import api from 'controllers/Api'

export function fetchInitialState() {
  // TODO: remove limit
    return Promise.props({
        companies: api.get('/super/contractors?limit=10000')
            .then((response) => response.data),
        balance: api.get('/super/balance?limit=10000')
            .then((response) => response.data)
            .then(
                (balance) => balance
                    .sort((itemA, itemB) => moment(itemA.createdAt).isBefore(moment(itemB.createdAt)) ? 1 : -1)
            ),
    })
}

export default { }
