import store from 'reducers';
import api from 'controllers/Api';

const ChatsController = {

  getList: () => {
    api.get(`/chats`)
    .then(r => {
      const list = r.data?.contacts || [];
      const unreadMessagesCount = list.map(item => item.unreadMessagesCount).reduce((a, b) => a + b, 0);

      store.dispatch({
        type: 'CHATS_LIST_SET',
        params: {
          list,
          unreadMessagesCount,
        }
      });
    });
  },

  readChat: (id) => {
    api.post(`/chat/${id}/mark_read`)
    .then(() => {
      ChatsController.getList();
    });
  },

  actions: {
    'CHATS_LIST_SET': (action, state) => {
      const { list, unreadMessagesCount } = action.params;
      return {
        ...state,
        list,
        unreadMessagesCount
      };
    },
  },

}

export default ChatsController;