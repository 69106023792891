import React from 'react';
import moment from 'moment';

import api  from 'controllers/Api';
import Icons from 'utils/Icons';
import history from 'controllers/History';
import { simulateModalLayout, isDesktop, formatName } from 'utils/Helpers';
import { Header } from 'components/Modal2';
import ChatsController from 'controllers/Chats';

const CHAT_POOL_TIMEOUT_MS = 2 * 1000;

class ChatPage extends React.Component {

  state = {
    data: null,
    input: '',
    isLoading: true,
    refreshTimer: null
  }

  componentDidMount = async () => {
    await this.fetch();

    const refreshTimer = setInterval(this.fetch, CHAT_POOL_TIMEOUT_MS)
    this.setState({ refreshTimer, isLoading: false });

    simulateModalLayout();
    this.scrollToBottom();
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshTimer);
    simulateModalLayout(false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.state.data) return true;
    if (nextState.data.messages.length !== this.state.data.messages.length) return true;
    if (nextState.input !== this.state.input) return true;
    if (nextState.isLoading !== this.state.isLoading) return true;

    return false;
  }

  componentDidUpdate = () => {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.scrollEnd.scrollIntoView({ behavior: 'smooth' });
  }

  fetch = async () => {
    const id = this.props.match?.params?.id;
    const { data } = await api.get(`/chat/${id}`)
    this.setState({ data }, this.handleRead);
  }

  handleRead = () => {
    const id = this.props.match?.params?.id;
    ChatsController.readChat(id);
  }

  handleTyping = (e) => {
    this.setState({ input: e.target.value });
  }

  handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    this.handleSend();
  }

  handleSend = () => {
    const id = this.props.match?.params?.id;
    const text = this.state.input;
    if(!text) return;

    api.post(`/chat/${id}`, { text })
    .then(response => {
      this.setState({ input: '' });

      api.get(`/chat/${id}`)
      .then(response => {
        this.setState({
          data: response.data,
          isLoading: false
        });
      });

    });
  }

  renderHistory = () => {
    const { data } = this.state;
 
    const history = data.messages.map(item => {
      const time = moment(item.createdAt).calendar();
      const isSelf = item.sender === data.me;
      let className = `chat-page_message`;
      if(isSelf) className += ` chat-page_message--self`;

      return (
        <div key={item._id} className={className} time={time} isSelf={isSelf}>
          <span className="chat-page_message-text">
              {item.text}
            </span>
        </div>
      );
    })
    .reverse()
    .reduce((result, current, index, array) => {
      result.push(current);

      const next = array[index+1];
      const { time, isSelf } = current.props;
      if(next) {
        const { time: nextTime, isSelf: nextIsSelf } = next.props;
        if(isSelf !== nextIsSelf || time !== nextTime) {
          const time = current.props.time;
          result.push(<div className="chat-page_message-time">{time}</div>);
        }
      } else {
        // last message
        result.push(<div className="chat-page_message-time">{time}</div>);
      }

      return result;
    }, []);

    return history;
  }

  goBack = () => {
    const { state } = this.props.location;
    if (!state) history.push('/chats');
    else this.props.history.goBack();
  }

  render() {
    const { isLoading, data } = this.state;

    let title = 'Загрузка...';
    let headerSuffix = null;
    let inputBoxContent = null;

    if(!isLoading) {
      title = `${formatName(data.contact)}`;
      if(!isDesktop()) headerSuffix = data.isActive ? <a href={`tel:${data.contact?.phone}`}>{Icons.phone}</a> : Icons.phoneInactive;

      const roleText = data.contact.role === 'employer' ? 'заказчиком' : 'исполнителем';
      inputBoxContent = data.isActive ? (
        <>
          <input
            value={this.state.input}
            onChange={this.handleTyping}
            onKeyDown={this.handleKeyDown}
            placeholder="Текст сообщения..." />
          <span onClick={this.handleSend}>{Icons.sendMessage}</span>
        </>
      ) : (
        <div className="color_gray">У вас нет активных подработок с {roleText}. Возможность общения ограничена.</div>
      );
    }

    return (
      <>
        <Header title={title} back={this.goBack} suffix={headerSuffix} />
        <div className="chat-page">
          <div className="chat-page_history">
            {!isLoading && this.renderHistory()}
            <span ref={(el) => { this.scrollEnd = el; }}></span>
          </div>
          <div className="chat-page_input-wrapper p2">
            {inputBoxContent}
          </div>
        </div>
      </>
    );
  }

}

export default ChatPage;
