import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import api, { baseURL } from 'controllers/Api';
import { simulateModalLayout } from 'utils/Helpers';
import Icons from 'utils/Icons';

import starFull from './star_big_full.svg';
import starEmpty from './star_big_empty.svg';
import wink from './wink.png';
import TagsController from 'controllers/Tags';


class ReviewPage extends React.Component {
  state = {
    role: this.props.match?.params?.jobid ? 'employer' : 'worker',
    rating: 0,
    text: '',
    error: null,
    isSubmitted: false,
  }

  componentDidMount = () => {
    simulateModalLayout(true);
  }

  componentWillUnmount() {
    simulateModalLayout(false);
  }

  setRating = (rating) => {
    this.setState({ rating });
  }

  renderStars = (data) => {
    const { rating } = this.state;

    const stars = [];
    for (let i=1; i<6; i++) {
      stars.push(rating >= i ? starFull : starEmpty);
    }
    const setRating = this.setRating;
    const starsImg = stars.map(function(star, i) {
      const rating = i + 1;
      return <img src={star} alt="" onClick={() => setRating(rating)}/>
    });

    return <div className="review_page-stars mb2">
      {starsImg}
    </div>
  }

  getAvatar = () => {
    const workerid = this.props.match?.params?.workerid;
    return { backgroundImage: `url(${baseURL}/user/${workerid}/avatar)` }; 
  }

  handleTextChange = (e) => {
    this.setState({ text: e.target.value });
  }

  handleSubmit = () => {
    const { text, rating, role } = this.state;
    console.log('this.state', this.state);
    const { id, jobid } = this.props.match?.params;

    const url = (role === 'worker') ? `/schedule/${id}/review` : `/order/${jobid}/review`;

    api.post(url, { text, rating })
    .then(r => {
      this.setState({ isSubmitted: true });
      TagsController.event('FormSend', 'Review', rating);
    })
    .catch(e => {
      this.setState({ error: e.response.data.message });
    });
  }

  render () {
    const { role, error, isSubmitted } = this.state;
    const { id, date, jobid } = this.props.match?.params;

    const backUrl = (role === 'worker') ? `/schedule/${id}` : `/orders/${id}/${date}/${jobid}`;
    if (isSubmitted) return <Redirect to={backUrl}/>;

    return (
      <>
        <div className="review_page p2">
          <Link to={backUrl} className="modal_close">{Icons.close}</Link>

          {role === 'worker' && <>
          <img src={wink} alt="" width="71px" />
          <h2>Работа выполнена!</h2>
          <p>Как оцениваете место и условия работы?</p>
          </>}

          {role === 'employer' && <>
          <div className="review_page-avatar" style={this.getAvatar()} />
          <h2>Работа выполнена!</h2>
          <p>Пожалуйста, оцените как исполнитель <br/> справился с задачей.</p>
          </>}

          {this.renderStars()}
          <textarea value={this.state.text} onChange={this.handleTextChange} placeholder="Текст отзыва (необязательно)"></textarea>

          {error && <strong className="color_red p2">{error}</strong>}

          <div className="modal_btn-holder"></div>
        </div>
        <div className="btn btn_primary modal_btn" onClick={this.handleSubmit}>Отправить</div>
      </>
    );
  }
}

export default ReviewPage;
