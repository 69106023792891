import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { statuses } from 'utils/Constants';
import { baseURL } from 'controllers/Api';

import MGStarIcon from 'components/MGStarIcon';
import { decl } from 'utils/Helpers';
import Tooltip from 'rc-tooltip';
import empty from './empty.svg';

const CountTooltipIcon = ({ count }) => {
  const [isOpened, setIsOpened] = useState();
  return (
    <span role="button" tabIndex={0} onClick={(e) => e.stopPropagation()}>
      <Tooltip
        className="rc-tooltip-arrow-inner"
        overlay={
          <>
            {decl(count, ['Завершена', 'Завершены', 'Завершено'])}{' '}
            <b>{count}</b> {decl(count, ['смена', 'смены', 'смен'])} с вами
          </>
        }
        trigger="click"
        placement="top"
        onVisibleChange={setIsOpened}
      >
        <span
          style={{
            width: 28,
            marginTop: -3,
            marginLeft: 2,
          }}
        >
          <MGStarIcon color={isOpened ? '#FF9B00' : undefined} />
        </span>
      </Tooltip>
    </span>
  );
};

CountTooltipIcon.propTypes = {
  count: PropTypes.number,
};

CountTooltipIcon.defaultProps = {
  count: 0,
};

const Worker = (props) => {
  const { job, status, onClickItem } = props;
  if (!job)
    return (
      <div className="workers-list_worker pb2">
        <img src={empty} height="32px" alt="" />
      </div>
    );

  const { worker, countClientJobs: count } = job;
  if (!worker?.user?.firstname && !worker?.user?.lastname) return null; // неподтвержденный юзер
  const name = `${worker.user.firstname} ${worker.user.lastname}`;
  const statusLabel = statuses[status] ?? status;
  const avatar = `${baseURL}/user/${worker.user._id}/avatar`;
  const statusClass =
    status === 'cancelled' ? 'workers-list_worker-cancelled' : '';

  return (
    <div
      role="button"
      tabIndex={0}
      className={`workers-list_worker ${statusClass} pb2`}
      onClick={(e) => {
        e.stopPropagation();
        onClickItem(props);
      }}
    >
      <div
        className="workers-list_worker-img"
        style={{ backgroundImage: `url(${avatar})` }}
      />
      <div className="workers-list_worker-texts">
        <div className="workers-list_worker-name">
          {name}
          {count > 0 ? <CountTooltipIcon count={count} /> : null}
        </div>
        <div className="workers-list_worker-status">{statusLabel}</div>
        {worker.user.phone ? (
          <div className="workers-list_worker-phone">
            Тел: <span style={{ color: 'black' }}>+{worker.user.phone}</span>
          </div>
        ) : null}
      </div>
      {/* <div className="workers-list_worker-message">
        <img src={message}/>
        <span class="bubble_count">3</span>
      </div> */}
    </div>
  );
};

Worker.propTypes = {
  job: PropTypes.shape({
    worker: PropTypes.shape({
      user: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        phone: PropTypes.number,
      }),
    }),
    countClientJobs: PropTypes.number,
  }),
  status: PropTypes.string.isRequired,
  onClickItem: PropTypes.func,
};

Worker.defaultProps = {
  job: undefined,
  onClickItem: () => {},
};

export default Worker;
