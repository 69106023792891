import Search from 'containers/Search';
import EmployerOrders from 'containers/EmployerOrders';
import SupervisorOrders from 'containers/SupervisorOrders';
import OrderPage from 'containers/OrderPage';
import WorkerPage from 'containers/WorkerPage';
import Schedule from 'containers/Schedule';
import SchedulePage from 'containers/SchedulePage';
import Profile from 'containers/Profile/Page';
import Balance from 'containers/Profile/Balance';
import Withdraw from 'containers/Profile/Withdraw';
import { SelfEmployedConfirmation } from 'containers/Profile/SelfEmployedConfirmation';
import Document from 'containers/Profile/Document';
import Chats from 'containers/Chats';
import ChatPage from 'containers/ChatPage';
import ReviewPage from 'containers/ReviewPage';
import BalancePage from 'containers/BalancePage';
import Notifications from 'containers/Notifications';
import Login from 'containers/Login';
import Receipt from 'containers/Profile/Receipt';
import Workers from 'containers/Workers';
import RateOrders from 'containers/Rates';
import OrderRate from 'containers/OrderRate';
import JobTiles from 'components/JobTiles';
import SupervisorExpenses from 'containers/SupervisorExpenses';

import ProtectedRoute from 'components/ProtectedRoute';
import ProtectedSwitch from 'components/ProtectedSwitch';

export default [
  {
    path: '/special/landing_jobs',
    component: JobTiles,
    exact: true,
  },
  {
    path: ['/', '/jobs/:id'],
    component: Search,
    exact: true,
  },
  {
    path: '/workers',
    render: ProtectedRoute(Workers, ['supervisor', 'employer']),
    exact: true,
  },
  {
    path: '/orders',
    render: ProtectedSwitch({
      supervisor: SupervisorOrders,
      employer: EmployerOrders,
    }),
    exact: true,
  },
  {
    path: '/rates',
    render: ProtectedRoute(RateOrders, ['employer']),
    exact: true,
  },
  {
    path: '/rates/:id',
    render: ProtectedRoute(OrderRate, ['employer']),
    exact: true,
  },
  {
    path: '/balance',
    render: ProtectedSwitch({
      supervisor: BalancePage,
      employer: BalancePage,
    }),
    exact: true,
  },
  {
    path: '/orders/:id',
    render: ProtectedSwitch({
      supervisor: OrderPage,
      employer: OrderPage,
    }),
    exact: true,
  },
  {
    path: '/orders/:id/:date',
    render: ProtectedSwitch({
      supervisor: OrderPage,
      employer: OrderPage,
    }),
    exact: true,
  },
  {
    path: '/orders/:id/:date/:jobid',
    render: ProtectedSwitch({
      supervisor: WorkerPage,
      employer: WorkerPage,
    }),
    exact: true,
  },
  {
    path: '/orders/:id/:date/:jobid/:workerid',
    render: ProtectedSwitch({
      supervisor: ReviewPage,
      employer: ReviewPage,
    }),
    exact: true,
  },
  {
    path: '/schedule',
    render: ProtectedRoute(Schedule, 'worker'),
    exact: true,
  },
  {
    path: '/schedule/:id',
    render: ProtectedRoute(SchedulePage, 'worker'),
    exact: true,
  },
  {
    path: '/schedule/:id/review',
    render: ProtectedRoute(ReviewPage, 'worker'),
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/profile',
    render: ProtectedRoute(Profile),
    exact: true,
  },
  {
    path: '/profile/balance',
    render: ProtectedRoute(Balance, 'worker'),
    exact: true,
  },
  {
    path: '/profile/balance/withdraw',
    render: ProtectedRoute(Withdraw, 'worker'),
    exact: true,
  },
  {
    path: '/profile/self-employed-confirmation',
    render: ProtectedRoute(SelfEmployedConfirmation, 'worker'),
    exact: true,
  },
  {
    path: '/profile/transactions/:id/receipt',
    render: ProtectedRoute(Receipt, 'worker'),
    exact: true,
  },
  {
    path: '/chats',
    render: ProtectedRoute(Chats),
    exact: true,
  },
  {
    path: '/chats/:id',
    render: ProtectedRoute(ChatPage),
    exact: true,
  },
  {
    path: '/review/:id',
    render: ProtectedRoute(ChatPage),
    exact: true,
  },
  {
    path: '/profile/documents/:type',
    component: Document,
    exact: true,
  },
  {
    path: '/notifications',
    render: ProtectedRoute(Notifications),
    exact: true,
  },
  {
    path: '/expenses',
    render: ProtectedRoute(SupervisorExpenses, 'supervisor'),
    exact: true,
  },
];
