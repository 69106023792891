import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Icons from 'utils/Icons';

function SmartAppBanner() {
	const dispatch = useDispatch()
	const { isSmartBannerShown } = useSelector(state => state.env)
	const { role } = useSelector(state => state.user)

	const isWorker = useMemo(
		() => !['employer', 'supervisor'].includes(role),
		[role],
	)

    const isAndroid = /(android)/i.test(navigator.userAgent);

    const isBannerDisplayed = isSmartBannerShown && isWorker && isAndroid;

	const handleClose = useCallback(() => {
		localStorage.setItem('smart-banner-closed', '1')

		dispatch({
			type: 'HIDE_SMART_BANNER',
		})

        window.location.reload(true);
	}, [dispatch])

	return isBannerDisplayed ? (
		<div className="app-smart-banner__container">
			<span onClick={handleClose}>
				{Icons.close}
			</span>

			<img src="https://play-lh.googleusercontent.com/cBAsaZrRMjLmh5htV6uQzALjPI0QhKQOPG2MvCdR2b0Z9EP14QDIeV4GlioJ1Uu94Eg=s360-rw" alt="MyGig" />

			<a href="https://play.google.com/store/apps/details?id=mygig.worker&hl=ru&gl=US&referrer=appmetrica_tracking_id%3D1107810456313099624%26ym_tracking_id%3D9411775264240576627">
				<span className="app-smart-banner__title">MyGig - подработка рядом с домом. Деньги сразу.</span>
				<span className="app-smart-banner__description">Скачать в Google Play</span>
			</a>
		</div>
	) : null
}

export default SmartAppBanner
