import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { formatPrice } from 'utils/Helpers';
import { documentTypes2 } from 'utils/Constants';

import Context from '../../../Context';

function cancelOrderTemplate(initiator) {
  switch (initiator) {
    case 'worker':
      return (
        <>
          Позвоните, если нужна помощь в поиске подработки, по бесплатному
          номеру 8 800 333-02-97.
        </>
      );
    default:
      return (
        <>
          К сожалению, нам пришлось отменить эту смену. Поможем разобраться и
          найти другую подработку: 8 800 333-03-29
        </>
      );
  }
}

const ActionText = () => {
  const { data, timeLeftHours, timeLeftMinutes, timeLeftDays } =
    useContext(Context);
  const { mobile_team: mobileTeam } = useSelector((state) => state.user);
  const getText = () => {
    if (
      ['booked', 'going', 'doccheck'].includes(data?.status) &&
      timeLeftDays === 0
    ) {
      if (timeLeftMinutes < 30 && timeLeftHours === 0) {
        return 'Отметьте начало работы, как только придёте на место.';
      }

      if (timeLeftHours < 16 && data.status !== 'going') {
        return (
          <>
            Подтвердите, что вы придёте на подработку. Если у вас поменялись
            планы, пожалуйста, сообщите об этом, чтобы мы могли найти вам
            замену.
          </>
        );
      }
    }

    switch (data.status) {
      case 'going':
      default: {
        const documentsText = [
          ...new Set(['passport', ...data?.documents]),
        ].map((key) => {
          const docName = documentTypes2[key]?.name || key;
          return <li key={key}>{docName}</li>;
        });

        return (
          <>
            Обязательно возьмите с собой документы:
            <ul style={{ paddingLeft: '1em' }}>{documentsText}</ul>
          </>
        );
      }
      case 'doccheck':
        return 'Мы проверяем ваши документы.';

      // опоздания
      case 'delayed':
        return (
          <>
            Мы отправили заказчику сообщение с просьбой подождать вас. Мы
            пришлём SMS, как только заказчик ответит.
          </>
        );
      case 'waiting':
        return 'Заказчик подождёт вас. Поторопитесь! ';
      case 'toolate':
        return (
          <>
            К сожалению, заказчик не готов ждать вас. Подработку пришлось
            отменить.
          </>
        );

      // чекин-работа
      case 'checkingin':
        return (
          <>
            Если вы действительно на месте, подойдите к директору и попросите
            отметить вас.
          </>
        );
      case 'inprogress':
        return 'Отметьте завершение работы, как только закончите.';

      // конец работы
      case 'checkingout':
        return (
          <>
            Если вы всё ещё на месте, подойдите к директору и попросите отметить
            вас.
          </>
        );
      case 'completed':
        return (
          !mobileTeam && (
            <>
              Мы всё проверим и пришлём SMS, когда можно будет получить деньги
              на карту. Обычно это занимает до 30 минут.
            </>
          )
        );
      case 'confirmed':
        return (
          !mobileTeam && (
            <>Вы заработали: {formatPrice(data.salary_per_job_fact)} ₽</>
          )
        );

      // ошибки
      case 'cancelled':
        return (
          <>
            {cancelOrderTemplate(data.initiator)}
            {data.payed_amount && (
              <>
                <br />
                Вам начислена оплата за {data.payed_time} часа:{' '}
                {data.payed_amount} ₽.
              </>
            )}
          </>
        );
      case 'failed':
        return (
          <>
            К сожалению, подработку пришлось отменить. Если это ошибка,
            позвоните оператору контактного центра.
          </>
        );
      case 'disputed':
        return 'Мы разберёмся в ситуации и сообщим вам решение по SMS.';
    }
  };

  const text = getText();
  if (!text) return null;
  return <p>{text}</p>;
};

export default ActionText;
