import React from 'react';
import api from 'controllers/Api';
import classNames from "classnames";
import { dateToTextLong, pick } from "utils/Helpers";
import Item from './components/Item';
import Preloader from "components/Preloader";
import { connect } from 'react-redux';
import Icons from "utils/Icons";


class Workers extends React.Component {

  state = {
    data: {},
    isLoading: true,
    workplaces: [],
    filters: {
      workplace: null,
      status: 'active',
      groupby: 'dates,workplaces',
      text: '',
    }
  }

  fetchInterval = null;

  componentDidMount() {
    this.fetch();
    this.fetchInterval = setInterval(() => {
      this.fetch(true);
    }, 30*1000);

    const workplaces = this.props.workplaces.map(item => pick(item, ['id', 'title']));
    this.setState({ workplaces });
  }

  componentWillUnmount() {
    clearInterval(this.fetchInterval);
  }

  fetch = (silent = false) => {
    const { isLoading, filters } = this.state;
    if (!silent && !isLoading) this.setState({isLoading: true});

    api.get(`/workers`, {
      params: filters,
    })
    .then(response => {
      this.setState({
        isLoading: false,
        data: response.data,
      });
    });
  }

  onClickTab = (e) => {
    const { filters } = this.state;
    const { tab } = e.currentTarget.dataset;
    if(tab === filters.status) return;
    filters.status = tab;
    this.setState({ filters }, this.fetch);
  }

  onChangeWorkplace = (e) => {
    const { filters } = this.state;
    const id = e.currentTarget.value;
    filters.workplace = id;
    if(id === 'all') filters.workplace = null;
    this.setState({
      filters,
    }, this.fetch);
  }

  onChangeSearch = (e) => {
    const { filters } = this.state;
    filters.text = e.target.value;
    this.setState({ filters }, this.fetch);
  }

  onResetSearch = () => {
    const { filters } = this.state;
    filters.text = '';
    this.setState({ filters }, this.fetch);
  }

  renderWorkplaceName = (workplaceId) => {
    const { workplaces } = this.state;
    const workplace = workplaces.find(w => w.id === workplaceId);
    return workplace?.title || workplaceId;
  }

  renderItems = () => {
    const { data, filters, workplaces: wp } = this.state;
    const { role } = this.props.user;
    if(!Object.keys(data).length) {
      if(filters.text) {
        return (
          <div className="layout_tall">
            <div className="empty_state">
              <span role="img" aria-label="empty" className="empty_state-icon">🤷‍♀️️</span>
              <div className="empty_state-title">Исполнитель не найден</div>
              <div className="empty_state-text" style={{ textAlign: 'center' }}>
                <strong className="color_blue" onClick={this.onResetSearch}>Сбросить поиск</strong>
              </div>
            </div>
          </div>
        );
      } else {
        const text = (filters.status === 'active') ? <>Исполнители ещё не <br/>взяли ваши заявки</> : <>Исполнители ещё не завершили <br/> у вас ни одной смены</>;
        return (
          <div className="layout_tall">
            <div className="empty_state">
              <span role="img" aria-label="empty" className="empty_state-icon">🤷‍♀️️</span>
              <div className="empty_state-title">Здесь никого нет</div>
              <div className="empty_state-text">{text}</div>
            </div>
          </div>
        );
      }
    }
    return Object.keys(data).map(date => {
      const item = data[date];
      const workplaces = Object.keys(item.workplaces).map(workplaceId => {
        const workplaceName = this.renderWorkplaceName(workplaceId);
        const items = item.workplaces[workplaceId].map(order => (
          <Item
            key={order.id}
            data={order}
            hideActions={(role === 'supervisor')}
            searchText={filters.text}
            fetch={this.fetch}
          />
        ));
        return (
          <React.Fragment key={workplaceId}>
            {wp.length > 1 && <div className="orders_list-workplace color_gray ph2">{workplaceName}</div>}
            <div className="workers-list_wrapper">
              {items}
            </div>
          </React.Fragment>
        );
      });
      return (
        <div key={date} className="orders_list orders_list-day">
          <div className="orders_day-title ph2 pt3 pb1">
            {dateToTextLong(date)}
          </div>
          {workplaces}
        </div>
      );
    });
  }

  render() {
    const { isLoading, filters, workplaces } = this.state;
    return (
      <>
        <div className="p2 pt3">
          <h1 className="mb2">Исполнители</h1>
          <div className="workers-search">
            <input
              onKeyPress={this.onKeyPressSearch}
              onChange={this.onChangeSearch}
              type="text"
              value={filters.text}
              placeholder="Поиск исполнителя"
              style={{ width: '100%' }} />
            {filters.text && <span className="workers-search_clear" onClick={this.onResetSearch}>{Icons.close}</span>}
          </div>
        </div>
        <div className="orders_tabs">
          <div
            className={classNames('orders_tab-button', { 'orders_tab-active': filters.status === 'active' })}
            onClick={this.onClickTab}
            data-tab="active">
            Актуальные смены
          </div>
          <div
            className={classNames('orders_tab-button', { 'orders_tab-active': filters.status !== 'active' })}
            onClick={this.onClickTab}
            data-tab="inactive">
            Завершённые смены
          </div>
        </div>
        {(workplaces.length && workplaces.length > 1) ? (
          <div className="ph2 pt2">
            <select style={{ width: '100%' }} onChange={this.onChangeWorkplace}>
              <option value="all">Все локации</option>
              {workplaces.map(workplace => <option key={workplace.id} value={workplace.id}>{workplace.title}</option>)}
            </select>
          </div>
        ) : null}
        {isLoading ? <Preloader className="layout_tall" /> : this.renderItems()}
      </>
    );
  }

}

export default connect(store => ({ user: store.user, workplaces: store.env.workplaces }))(Workers);
