import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import TagsController from 'controllers/Tags';

import Context from '../../../Context';

const ActionButtons = () => {
  const {
    data,
    actions,
    timeLeftHours,
    timeLeftMinutes,
    timeLeftDays,
    isSending,
    custom_name: name,
  } = useContext(Context);
  const { mobile_team: mobileTeam } = useSelector((state) => state.user);

  const onReview = () => {
    TagsController.event('Buttons', 'Review', name);
  };

  const onGetMoney = () => {
    TagsController.event('Buttons', 'GetMoney', name);
  };

  const getBtns = () => {
    if (
      ['booked', 'going', 'doccheck'].includes(data?.status) &&
      timeLeftDays === 0
    ) {
      if (timeLeftMinutes < 30 && timeLeftHours === 0) {
        return (
          <>
            <div
              role="button"
              disabled={isSending}
              className="btn btn_small"
              onClick={actions.delay}
            >
              Опаздываю
            </div>
            <div
              role="button"
              disabled={isSending}
              className="btn btn_small btn_primary"
              onClick={actions.checkin}
            >
              Я на месте
            </div>
          </>
        );
      }

      if (timeLeftHours < 16 && data.status !== 'going') {
        return (
          <>
            <div
              role="button"
              disabled={isSending}
              className="btn btn_small"
              onClick={actions.cancel}
            >
              Нет, отмените
            </div>
            <div
              role="button"
              disabled={isSending}
              className="btn btn_small btn_primary"
              onClick={actions.going}
            >
              Да, я приду
            </div>
          </>
        );
      }
    }

    switch (data.status) {
      default:
        return null;
      // опоздания
      case 'delayed':
      case 'waiting':
        return (
          <div
            role="button"
            disabled={isSending}
            className="btn btn_small btn_primary"
            onClick={actions.checkin}
          >
            Я на месте
          </div>
        );

      // чекин-работа
      case 'checkingin':
      case 'checkingout':
        return (
          <>
            <div
              role="button"
              disabled={isSending}
              className="btn btn_small"
              onClick={actions.call}
            >
              Позвонить оператору
            </div>
            <div
              role="button"
              disabled={isSending}
              className="btn btn_small btn_primary"
              onClick={actions.checkin}
            >
              Повторить
            </div>
          </>
        );

      case 'inprogress':
        return (
          <div
            role="button"
            disabled={isSending}
            className="btn btn_small btn_primary"
            onClick={actions.finish}
          >
            Закончить работу
          </div>
        );

      case 'completed':
      case 'confirmed':
        return (
          <>
            {!data.review && (
              <Link
                disabled={isSending}
                onClick={onReview}
                className={cn('btn', 'btn_small', {
                  btn_primary: data.status === 'completed',
                })}
                to={`${document.location.pathname}/review`}
              >
                Оставить отзыв
              </Link>
            )}
            {data.status === 'confirmed' && !mobileTeam && (
              <Link
                disabled={isSending}
                onClick={onGetMoney}
                className="btn btn_small btn_primary"
                to={`/profile/transactions/${data._id}/receipt`}
              >
                Получить деньги
              </Link>
            )}
          </>
        );

      // ошибки
      case 'cancelled':
        return (
          data.payed_amount && (
            <Link
              disabled={isSending}
              onClick={onGetMoney}
              className="btn btn_small btn_primary"
              to="/profile/balance"
            >
              Получить деньги
            </Link>
          )
        );
      case 'failed':
        return (
          <div
            role="button"
            disabled={isSending}
            className="btn btn_small"
            onClick={actions.call}
          >
            Позвонить оператору
          </div>
        );
    }
  };

  const btns = getBtns();
  if (!btns) return null;
  return <div className="text-right">{btns}</div>;
};

export default ActionButtons;
