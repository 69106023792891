import React, { useCallback, useEffect, useMemo, useState } from 'react';
import api from 'controllers/Api';
import Modal2 from 'components/Modal2';
import { useSelector } from 'react-redux';
import List from './components/List';
import Banner from './components/banner';

const REFRESH_INTERVAL = 10000;

const Orders = () => {
  const [data, setData] = useState({});
  const [status, setStatus] = useState('active');
  const [loading, setLoading] = useState(true);

  const permissions = useSelector((state) => state.user.permissions);

  const fetch = useCallback((filters = {}, refreshing = false) => {
    if (!refreshing) {
      setLoading(true);
    }

    const { status: newStatus } = filters;
    setStatus(newStatus);
    const query = Object.entries(filters)
      .map((pair) => pair.join('='))
      .join('&');

    api.get(`/orders?${query}`).then((res) => {
      setData(res.data);
      if (!refreshing) {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    fetch({ status, groupby: 'dates' });

    const refreshTimer = setInterval(() => {
      fetch({ status, groupby: 'dates' }, true);
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(refreshTimer);
    };
  }, [fetch, status]);

  const onClickOrderNew = () => {
    // Events.dispatch('MODAL_SHOW-ModalOrderNew');
    Modal2('ModalOrderNew');
  };

  const onClickActive = () => {
    fetch({ status: 'active', groupby: 'dates' });
  };

  const onClickInactive = () => {
    fetch({ status: 'inactive', groupby: 'dates' });
  };

  const isActive = useMemo(() => status === 'active', [status]);

  return (
    <>
      <Banner />
      <h1 className="orders_page-title p2">Мои заказы</h1>
      <div className="orders_tabs">
        <div
          role="button"
          tabIndex={0}
          className={`orders_tab-button ${isActive ? 'orders_tab-active' : ''}`}
          onClick={onClickActive}
        >
          Актуальные
        </div>
        <div
          role="button"
          tabIndex={0}
          className={`orders_tab-button ${isActive ? '' : 'orders_tab-active'}`}
          onClick={onClickInactive}
        >
          Завершённые
        </div>
      </div>
      <List data={data} loading={loading} status={status} />
      <div className="modal_btn-holder" />

      {!permissions.cannot_create_order && (
        <div
          role="button"
          tabIndex={0}
          className="btn btn_primary modal_btn"
          onClick={onClickOrderNew}
        >
          Новый заказ
        </div>
      )}
    </>
  );
};

export default Orders;
