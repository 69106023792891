import { useCallback, useEffect, useState } from 'react';
import { errorHandler } from './Helpers';

// Не добавил isLoading потому что кажется лучше оставить это внутри компонента
/** @return {{
 * handleError: (function(string[]=): void),
 * resetErrors: (function(): void),
 * errors: string[],
 * }} */
export function useErrorHandler() {
  const [errors, setErrors] = useState([]);

  const handleError = useCallback((err) => setErrors(errorHandler(err)), []);
  const resetErrors = useCallback(() => setErrors([]), []);

  return { errors, handleError, resetErrors };
}

/**
 * @param axiosInstall
 * @return {{
 * request: ((function({
 *   url: string,
 *   method?: string,
 *   params?: Object,
 *   data?: Object,
 *   headers?: Object,
 * }): Promise<*>)|*),
 * resetErrors: (function(): void),
 * isRequesting: boolean,
 * errors: string[],
 * }}
 */
export const useRequest = (axiosInstall) => {
  const { errors, handleError, resetErrors } = useErrorHandler();
  const [isRequesting, setIsRequesting] = useState(false);

  const request = useCallback(
    /**
     * @param {string} url
     * @param {string} method=
     * @param {object} params=
     * @param {object} data=
     * @param {object} headers=
     * @return {Promise<*>}
     */
    async ({
      url,
      method = 'GET',
      params = null,
      data = null,
      headers = {},
    }) => {
      setIsRequesting(true);
      try {
        const response = await axiosInstall.request({
          url,
          method,
          params,
          data,
          headers,
        });
        const { data: responseData } = response;
        return responseData;
      } catch (e) {
        handleError(e);
        throw e;
      } finally {
        setIsRequesting(false);
      }
    },
    [axiosInstall, handleError],
  );

  return {
    isRequesting,
    request,
    errors,
    resetErrors,
  };
};

export const useDebouncedEffect = (fn, deps, delay) => {
  const [debouncedFn, setDebouncedFn] = useState(fn);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedFn(fn), delay);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return debouncedFn;
};
