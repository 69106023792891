import React, { useMemo, useCallback, useState } from 'react'

export default function Citieslist({
	source = [],
	onSelect = () => { },
}) {
	const [filter, setFilter] = useState('')

	const handleSearch = useCallback(({ target }) => {
		setFilter(target.value)
	}, [])

	const list = useMemo(() => {
		if (filter === "") {
			return source
		}

		return source.filter((city) => {
			const key = filter.toLowerCase()

			return city.name.toLowerCase().indexOf(key) > -1
		})
	}, [source, filter])

	return (
		<div className="cities-list">
			<div className="cities-list--header">
				<div className="cities-list--header_logo">
					<img src="/img/logo.svg" alt="" />
				</div>

				<span className="cities-list--header_title">
					Укажите ваш город
				</span>

				<input
					value={filter}
					onChange={handleSearch}
					type="text"
					placeholder="Название города"
					className="cities-list--search" />
			</div>

			<div className="cities-list--body">
				{list.map((city) => (
					<span className="cities-list--city" key={city.name} onClick={() => onSelect(city)}>
						{city.name}
					</span>
				))}
			</div>
		</div>
	)
}