import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Popup = ({ visible, children, onClose }) => {
  return (
    <div className={cn('popup-wrapper', { 'popup-visible': visible })}>
      <div className="popup-content">
        <div
          role="button"
          tabIndex={0}
          className="popup-close"
          onClick={onClose}
        >
          &times;
        </div>
        {children}
      </div>
    </div>
  );
};

Popup.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Popup;
