import api from 'controllers/Api'

export function handleGetInvoice(contractorId, value) {
    return api.request({
        url: '/invoice',
        params: { value, contractorId },
        method: 'GET',
        responseType: 'blob',
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'invoice.pdf')
            document.body.appendChild(link)
            link.click()
        })
}

export default { }
