import React from "react";
import { connect } from "react-redux";
import Login from 'containers/Login';

const _ProtectedSwitch = (props) => {
  const { user, roles } = props;
  let Component = () => <Login disableClose={true} disableRedirect={true} />;
  if(user.isAuthorized) {
    if(Object.keys(roles).includes(user.role)) {
      Component = roles[user.role];
    } else {
      Component = () => <div className="p2">У вас нет доступа к этой странице</div>;
    }
  }
  return <Component {...props} />;
}
const ProtectedSwitch = connect(state => ({ user: state.user }))(_ProtectedSwitch);
export default (roles) => (props) => <ProtectedSwitch {...props} roles={roles} />;