import React from 'react';
import Icons from 'utils/Icons';
import { connect } from 'react-redux';
import { FormErrorHandler } from 'utils/Helpers';
import { documentTypes2 } from 'utils/Constants';
import api, { baseURL } from 'controllers/Api';
import UserController from 'controllers/User';
import Cookies from 'js-cookie'
import TagsController from 'controllers/Tags';
import plugPdf from '../../assets/images/plugPdf.svg';
const Uppy = require('@uppy/core');
const XHRUpload = require('@uppy/xhr-upload');
const { DragDrop } = require('@uppy/react');

const maxFileSize = 20; // мб

class DocumentUpload extends React.Component {

  state = {
    errors: null,
    isUploading: false,
  }

  FormErrorHandler = FormErrorHandler.bind(this);

  componentDidMount = () => {
    const { token } = Cookies.get() || {};
    this.uppy.use(XHRUpload, {
      endpoint: `${baseURL}/upload`,
      headers: {
        Authorization: token,
      },
      fieldName: 'file'
    });

    const { data } = this.props;
    if(data) this.setState({ data });
  }

  uppy = new Uppy({
    id: this.props.type,
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      allowedFileTypes: ['image/*', '.jpg', '.jpeg', 'application/pdf'],
      maxFileSize: maxFileSize*1024*1024,
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
    },
    onBeforeFileAdded: (currentFile, files) => {
      return {
        ...currentFile,
        name: currentFile.name + '__' + Date.now()
      }
    },
  })
  .on('restriction-failed', (file, error) => {
    console.error(error);
    this.setState({ isUploading: false, error: `Неподходящий формат или размер файла (изображения до ${maxFileSize} МБ)` });
  })
  .on('file-added', (file) => {
    this.setState({ isUploading: true, error: null });
  })
  .on('upload-error', (file, error, response) => {
    console.error(error);
    this.setState({ isUploading: false, error: 'Произошла ошибка' });
  })
  .on('error', (file, error, response) => {
    console.error(error);
    this.setState({ isUploading: false, error: 'Произошла ошибка' });
  })
  .on('upload-success', (file, response) => {
    const { filename, extension } = response.body;
    this.onUpload(filename, extension );
  });

  componentWillUnmount () {
    this.uppy.close();
  }

  onDelete = (e) => {
    const { id } = e.currentTarget.dataset;
    const { type, dispatch, user } = this.props;

    const documents = user.documents.filter(document => document.doctype !== type);

    console.log('documents before', user.documents);
    console.log('documents after', documents)

    dispatch({
      type: 'USER_SET',
      params: {
        documents: JSON.parse(JSON.stringify(documents)),
      }
    });

    api.delete(`/document/${id}`);
    this.uppy.reset();
  }

  onUpload = async (filename, extension) => {
    const { type } = this.props;
    try {
      if (this.props.onUploadStart) this.props.onUploadStart();
      await api.post(`/documents`, {
        doctype: type,
        filename,
        extension,
      });
      TagsController.event('FormSend', 'DownloadDocument', type);
      await UserController.getDocuments();
      this.setState({ isUploading: false });
      if (this.props.onUploadFinish) this.props.onUploadFinish();
    } catch(e) {
      this.setState({
        isUploading: false,
        error: e.response.data.error,
      });
    }
  }

  renderFiles = (document, multiple) => {
    const { type } = this.props;
    const { isUploading } = this.state;
    // нужно менять стесйт если файл заменяется
    // patch на document/:id

    let output = [];
    if(document && document.pages) {
      output = document.pages.map(page => {
        const url = `${baseURL}/document/${page.id}`;
        const style =
          page.filetype === 'pdf'
          ? {
            backgroundImage: `url(${plugPdf})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }
          : { backgroundImage: `url(${url})` }
        return (
          <div key={page.id} className="document_upload-box-image" style={style}>
            <div className="modal_close" onClick={this.onDelete} data-id={page.id}>{Icons.close}</div>
            {page.error && (
              <div className="document_upload-box-image-error">
                <strong className="color_red">{page.error} <br/>Удалите фото и загрузите заново</strong>
              </div>
            )}
          </div>
        );
      });
    }

    if(output.length < 1 || (multiple && isUploading)) {
      output.push(
        <div key="placeholder" className="document_upload-box-image document_upload-box-placeholder">
          <img src={`/img/icons/documents/${type}.png`} alt="" />
          {isUploading && <div className="document_upload-box-placeholder-uploading">{Icons.loading}<strong>Загружаю...</strong></div>}
        </div>
      );
    }

    return output;
  }

  render = () => {
    const { error, isUploading } = this.state;
    const { type, user } = this.props;

    const tmp = documentTypes2[type];
    const { documentInfoTexts } = tmp;
    const title = tmp?.description || tmp?.name || type;
    const name2 = tmp?.name2 || tmp?.name || type;
    const multiple = tmp.multiple;

    const document = user.documents?.find(d => d.doctype === type);
    const pagesCount = (document && document?.pages?.length) || 0;
    const disabled = (!multiple && pagesCount) || (!multiple && isUploading);

    // через прупсы это не сработало
    this.uppy.setOptions({
      locale: {
        strings: {
          'dropHereOr': ((multiple && (pagesCount > 0 || isUploading)) ? 'Загрузить ещё': 'Загрузить'),
        }
      }
    });

    return (
      <div className="content_block document_upload">
        <div className="content_block-header mb2">
          <div className="content_block-title">{title}</div>
        </div>
        {error && <div className="form_error mb2">{error}</div>}
        <div className="document_upload-box" id={type}>
          {this.renderFiles(document, multiple)}
          {type === 'med1' && (
            <div className="mb2">
              <a href="https://mygig.zendesk.com/hc/ru/articles/360007541937-%D0%A3-%D0%BC%D0%B5%D0%BD%D1%8F-%D0%BD%D0%B5%D1%82-%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%BE%D0%B9-%D0%BA%D0%BD%D0%B8%D0%B6%D0%BA%D0%B8" target="_blank" rel="noopener noreferrer" className="color_blue"><strong>У меня нет {name2}</strong></a>
            </div>
          )}
          {(type.startsWith('covidvax') && !!documentInfoTexts) && (
            <div className="mb2">
              <p>{documentInfoTexts[0]}</p>
              <ul>
                <li>{documentInfoTexts[4]}</li>
                <li>{documentInfoTexts[5]}</li>
                <li>{documentInfoTexts[6]}</li>
              </ul>
              <p>{documentInfoTexts[1]}</p>
              <a href={documentInfoTexts[3]} target="_blank" rel="noopener noreferrer" className="color_blue"><strong>{documentInfoTexts[2]}</strong></a>
            </div>
          )}
          <div className="document_upload-box-btn" disabled={disabled}>
            <DragDrop uppy={this.uppy} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ user: state.user }))(DocumentUpload);
