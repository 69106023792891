import React from "react";
import MaskedInput, { conformToMask } from "react-text-mask";
import { Field } from "formik";

export const mask = "+7 (___) ___ __ __";
const maskArray = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

const Input = ({ field }, props) => {

  const pipe = (conformedValue, { rawValue, ...config }) => {
    let value = conformedValue;
    const firstChar = rawValue.charAt(0);
    const char4 = conformedValue.charAt(4);
    const char5 = conformedValue.charAt(5);
    const countChars = rawValue.length;

    // если пустой инпут при ручном вводе
    if(char4 !== '_' && char5 === '_') {
      if((char4 === '7' || char4 === '8') && !window._phoneSkipFirst) {
        window._phoneSkipFirst = true;
        value = mask;
      }
    } else if(char4 === '_' && char5 === '_' && window._phoneSkipFirst) {
      delete window._phoneSkipFirst;
    }

    // логика при вставке/автофилле
    if(firstChar === '8' && countChars === 11) {
      value = conformToMask(`+7` + rawValue.substr(1), maskArray).conformedValue;
    } else if(firstChar === '7' && countChars === 11) {
      value = conformToMask(`+` + rawValue, maskArray).conformedValue;
    }

    return value;
  }

  return (
    <MaskedInput
      {...field}
      disabled={props.disabled}
      className="form-control"
      placeholder={mask}
      id="phone"
      keepCharPositions={true}
      mask={maskArray}
      guide={true}
      pipe={pipe}
    />
  );
}


const PhoneInput = (props) => <Field {...props} render={renderProps => Input(renderProps, props)} />;

export default PhoneInput;
