import { combineReducers, createStore } from 'redux';

import user from './user';
import jobs from './jobs';
import orders from './orders';
import config from './config';
import chats from './chats';
import transactions from './transactions';
import env from './env';

const reducers = combineReducers({
  user,
  jobs,
  orders,
  config,
  chats,
  transactions,
  env,
});

const enhancer = process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  : undefined;

const store = createStore(reducers, enhancer);

window.store = store;
export default store;
