
const initialState = {
  isSmartBannerShown: localStorage.getItem('smart-banner-closed') !== '1',
  workplaces: [],
  transactionsStats: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'HIDE_SMART_BANNER':
      return {
        ...state,
        isSmartBannerShown: false,
      }

    case 'SET_WORKPLACES':
        return {
            ...state,
            workplaces: action.payload,
        }

    case 'SET_TRANSACTIONS_STATS':
        return {
            ...state,
            transactionsStats: action.payload,
        }
    default:
      return state;
  }
}
