import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import FilterController from 'controllers/Filter';
import store from '../../../../reducers';

class ProfessionSelector extends React.PureComponent {

  renderItems = () => {
    const query = qs.parse(window.location.search.substr(1))

    const qMap = (query?.q?.spec?.in || '').split(',')

    const specs = this.props.config.specs;
    const { activeFilters } = this.props.jobs;
    return Object.keys(specs).map(key => {

      const isActive = (activeFilters.profession
        && activeFilters.profession.includes(key))
        || qMap.includes(key);

      return (
        <div key={key} onClick={this.onClick} data-key={key} className={isActive ? 'active' : ''}>
          {specs[key]}
        </div>
      )
    });
  }

  onClick = (e) => {
    const key = e.currentTarget.dataset.key;
    FilterController.toggleFilter('profession[]', key);

    const { activeFilters } = this.props.jobs;

    const { expirience, profession} = activeFilters;
    const difference = expirience?.filter(x => !profession?.includes(x));
    if (difference && difference.length > 0) {
      for (let value of difference) {
        FilterController.toggleFilter('expirience[]', value);
      }
    }
  }

  render() {
    return (
      <div className='jobs_search-professions pb2'>
        {this.renderItems()}
      </div>
    );
  }
}


export default connect(({ config, jobs }) => ({ config, jobs }))(ProfessionSelector);
