import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import Icons from 'utils/Icons';
import history from 'controllers/History';
import WorkersList from 'components/WorkersList';
import { documentTypes } from 'utils/Constants';
import { dateToTextLong, formatDuration, formatPrice, Alert } from 'utils/Helpers';
import { baseURL } from 'controllers/Api';
import Modal2 from 'components/Modal2';

const CANCEL_ONE = 'Только текущий';
const CANCEL_ALL = 'Текущий и все повторы';

class OrderDetails extends React.Component {

  state = {
    cancelTarget: null,
    isMenuOpen: false
  }


  onClickItem = ({ job }) => {
    if (!job) return;
    const { data } = this.props;
    // переход с детальной заказа на карточку работника
    history.push(`/orders/${data.id}/${job.date}/${job.id}`, { data });
  }


  onClickCopy = () => {
    const { data } = this.props;
    const copyData = { ...data } // данные повторного заказа
    copyData.start = moment().add(1, 'days').format('YYYY-MM-DD'); // по умолчанию ставим завтра
    copyData.finish = null;

    if (data.employer && data.employer._id) copyData.employer = data.employer._id;
    if (data.workplace && data.workplace._id) copyData.workplace = data.workplace._id;

    this.closeMenu();
    Modal2('ModalOrderNew', {
      initialState: {
        route: 'Edit',
        cart: [],
        currentItem: copyData
      }
    });
  }


  onSubmitAlert = () => {
    const { data } = this.props;
    const isCancelAll = (this.state.cancelTarget === CANCEL_ALL)
    Modal2('ModalCancelOrder', { data, isCancelAll });
  }


  onClickCancel = () => {
    const { data } = this.props;
    this.closeMenu();

    Modal2('ModalCancelOrder', { data, isCancelAll: false });
  }


  renderCancelOptions = () => {
    const options = [CANCEL_ONE, CANCEL_ALL].map(text => {
      const isActive = (this.state.cancelTarget === text);
      const className = classNames({
        active: isActive,
        radio: true,
      });
      return (
        <div key={text} data-key={text} className="radio_option" onClick={(e) => {
          const { key } = e.currentTarget.dataset;
          this.setState({ cancelTarget: key });
        }}>
          <strong>{text}</strong>
          <div className={className}></div>
        </div>
      );
    });

    return <div className="radio_options">{options}</div>
  }


  renderCancelAlert = () => {
    return Alert({
      closeOnClickOutside: true,
      title: <>Какой заказ <br /> отменяем?</>,
      icon: '🧐',
      text: (
        <>
          {this.renderCancelOptions()}
        </>
      ),
      buttons: (onClose) => {
        const alertActionOK = () => {
          this.onSubmitAlert();
          onClose();
        }
        const alertActionCancel = () => {
          this.setState({ cancelTarget: null });
          onClose();
        }
        return (
          <>
            <div className="btn btn_small" onClick={alertActionCancel}>Закрыть</div>
            <div className="btn btn_small btn_primary" onClick={alertActionOK}>Продолжить</div>
          </>
        );
      }
    });
  }

  openMenu = () => { this.setState({ isMenuOpen: true }) }
  closeMenu = () => { this.setState({ isMenuOpen: false }) }


  render = () => {
    const { data } = this.props;
    console.log('OrderDetails', data);

    const salary = data.payment_per_job || data.payment_per_hour * data.hours;
    const peopleCurrent = data.inwork.filter(i => i.status !== 'cancelled').length;
    const peopleTotal = data.amount;
    const salaryCurrent = data.inwork.reduce((sum, item) => sum + item.payment_per_job_fact, 0); //salary * peopleCurrent;
    const salaryTotal = salary * peopleTotal;

    const dateText = `${dateToTextLong(data.dates[0])}`;
    const isRed = !!dateText.match(/Сегодня|Завтра/);
    const timeText = `${data.start_time} - ${data.finish_time}`;

    const duration = formatDuration(data.minutes);
    const showBreak = !!data.break_time;

    return (
      <>


        <div className="order_item pt3">

          <div className="job_card-company flex">
            <div className="job_card-company-image" style={{ backgroundImage: `url(${baseURL}/client/${data.client})` }}></div>
            <div className="job_card-company-info">
              <div className="job_card-company-name">{data.workplace?.client.title}
                <div className="job_card-menu_icon" onClick={this.openMenu} style={{ float: 'right' }}>{Icons.kebab}</div>
                {this.state.isMenuOpen && <>
                  <div className="job_card-menu_overlay" onClick={this.closeMenu}></div>
                  <div className="job_card-menu_list">
                    <ul>
                      <li onClick={this.onClickCancel}>Отменить заказ</li>
                      <li onClick={this.onClickCopy}>Повторить заказ</li>
                    </ul>
                  </div>
                </>}
              </div>
              <div className="job_card-company-address">
                {data.distance && <>~{data.distance} м. &nbsp;</>}
                <span className="color_gray">{data.workplace?.address?.street}, {data.workplace?.address?.house}</span>
              </div>
            </div>
          </div>

          <div className="p2 pb0">
            <div className="job_card-header flex mb2">
              <div className="job_card-name"><strong>{data.custom_name}</strong></div>
              <div className="job_card-salary">{duration} <strong>/ {formatPrice(data.payment_per_job)} ₽</strong></div>
            </div>
            <div className="job_card-date flex mb1">
              <span className={`tag ${isRed ? 'tag--red' : ''}`}>{dateText}</span>
              <span className="tag">{data.is_night && '🌗'} {timeText}</span>
              {showBreak && <span className="tag">Перерыв {data.break_time}</span>}
            </div>

            <hr />

            <div className="order_item-workers-total flex mb2" onClick={this.toggleExpand}>
              <div className="order_item-workers flex">
                {Icons.worker} &nbsp; {peopleCurrent} / {peopleTotal}
                {peopleCurrent < peopleTotal && moment(data.dates[0]).isSameOrAfter(moment(), 'day') && !data.deleted &&
                  <span className="color_gray">идёт поиск&hellip;</span>}
              </div>
              <strong className="order_item-price">
                <span className="">{formatPrice(salaryCurrent)} ₽</span>
                &nbsp;/&nbsp;
                <span className={salaryCurrent !== salaryTotal ? 'order_item-price-in-progress' : ''}>
                  {formatPrice(salaryTotal)} ₽
                </span>
              </strong>
            </div>

            <WorkersList order={data} onClickItem={this.onClickItem} />
          </div>

          <div className="order_details">
            <div className="content_block-header mb2">
              <div className="content_block-title">Детали</div>
            </div>
            <p>
              <strong>Описание</strong>
              <span>{data.description || 'не указано'}</span>
            </p>
            <p>
              <strong>Адрес</strong>
              <span>{data.workplace?.address?.street}, {data.workplace?.address?.house}</span>
            </p>
            <p>
              <strong>Документы</strong>
              <span>{['passport'].concat(data.documents).map(item => documentTypes[item]).join(', ')}</span>
            </p>
            <p>
              <strong>Оплата</strong>
              <span>{formatPrice(data.payment_per_hour)}р/час</span>
            </p>
            <p>
              <strong>Опыт работы</strong>
              <span>{data.experience || 'не требуется'}</span>
            </p>
          </div>

        </div>


        {this.state.cancelTarget && this.renderCancelAlert()}
      </>
    );
  }
}

export default OrderDetails;
