import React from 'react';
import PropTypes from 'prop-types';

import { dateToTextLong } from 'utils/Helpers';

import Item from '../Item';

const Day = ({ items, date }) => {
  const dayTitle = dateToTextLong(date);

  return (
    <div key={date} className="orders_list-day">
      <div className="orders_day-title ph2 pt3 pb1">{dayTitle}</div>
      <div className="orders_list">
        {!items && <span className="orders_list-loading">Ошибка</span>}
        {items && !items.length && (
          <span className="orders_list-empty">Нет заявок</span>
        )}
        {items &&
          items.length &&
          items.map((item) => <Item key={item.id ?? item._id} data={item} />)}
      </div>
    </div>
  );
};

Day.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ _id: PropTypes.string, id: PropTypes.string }),
  ).isRequired,
  date: PropTypes.string.isRequired,
};

export default Day;
