import React, { lazy } from 'react';
import { Wrapper } from 'components/Modal';
export const Context = React.createContext();

class Modal extends Wrapper {

  _context = Context;

  routes = {
    Home: lazy(() => import('./screens/Home')),
    Success: lazy(() => import('./screens/Success')),
  };


  state = {
    route: 'Home',
    reason: null,
    reasonText: null,
  }

  functions = {
    onChangeReason: (e) => {
      const { key } = e.currentTarget.dataset;
      this.setState({ reason: key });
    },
    onChangeReasonText: (e) => {
      const reasonText = e.currentTarget.value;
      this.setState({ reasonText });
    },
  }

}

export default Modal;
