import React from 'react';

import { isDesktop } from 'utils/Helpers';
import { supportPhone, supportPhoneText } from 'utils/Constants';

import MenuIcons from './MenuIcons';

const contacts = {
  vk: 'https://vk.com/public195322973',
  tg: 'https://teleg.run/MyGigBot',
};

const ContactsSection = () =>
  isDesktop() ? (
    <div className="menu_overlay-list">
      <a href={contacts.vk} target="_blank" rel="noopener noreferrer">
        {MenuIcons.vk} <span>Вконтакте</span>
      </a>
      <a href={contacts.tg} target="_blank" rel="noopener noreferrer">
        {MenuIcons.tg} <span>Telegram</span>
      </a>
      <a href={`tel:${supportPhone}`} target="_blank" rel="noopener noreferrer">
        {MenuIcons.phone2} <span>{supportPhoneText}</span>
      </a>
    </div>
  ) : (
    <div className="menu_overlay-contacts">
      <a href={contacts.vk} target="_blank" rel="noopener noreferrer">
        {MenuIcons.vk}
      </a>
      <a href={contacts.tg} target="_blank" rel="noopener noreferrer">
        {MenuIcons.tg}
      </a>
      <a href={`tel:${supportPhone}`} target="_blank" rel="noopener noreferrer">
        {MenuIcons.phone2}
      </a>
    </div>
  );

export default ContactsSection;
