const CONSTANTS = require('@mygigtechnologies/constants');

export default {
  yandexMapLink:
    'https://api-maps.yandex.ru/2.1/?apikey=af448e13-f125-4ee7-b73d-a209d7d707d7&lang=ru_RU',
};

export const googlePlayLink =
  'https://play.google.com/store/apps/details?id=mygig.worker&hl=ru&gl=US&referrer=appmetrica_tracking_id%3D1107810456313099624%26ym_tracking_id%3D9411775264240576627';

// TODO: отрефакторить и вынести в @mygigtechnologies/constants!
// CONSTANTS.COMPANY.
export const supportPhone = `+78003330329`;
export const supportPhoneText = `8 800 333-03-29`;
export const supportAddress = `Москва, 5-й Донской проезд, дом 4, подъезд 3, проходная 14, этаж 3, офис 9`;
export const supportAddressLon = 37.597752;
export const supportAddressLat = 55.706597;

export const specs = CONSTANTS.SPECS;
export const statuses = CONSTANTS.STATUSES;
export const documentsGroups = CONSTANTS.DOCGROUPS;
export const documentTypes = CONSTANTS.DOCS;
export const covidvaxDocsAdditionalTexts =
  CONSTANTS.DOCS_ADDITIONAL_TEXTS.COVIDVAX;

export const MINIMAL_SALARY = 1000;

// TODO: отрефакторить и вынести в @mygigtechnologies/constants!
export const documentTypes2 = {
  passport: {
    name: 'Паспорт',
  },
  passport_selfie: {
    name: 'Селфи с паспортом',
    name2: null,
    description: 'Ваше Селфи с паспортом',
  },
  passport_main: {
    name: 'Основной разворот паспорта',
    name2: 'паспорта РФ',
    description: null,
  },
  passport_registration: {
    name: 'Разворот паспорта с пропиской',
    name2: 'паспорта РФ',
    description: null,
  },

  medical: {
    name: 'Медицинская книжка',
  },

  covidvax: {
    name: 'COVID-19',
    description: null,
  },

  covidvax1: {
    name: 'COVID-19, Страница 1',
    description: null,
    documentInfoTexts: covidvaxDocsAdditionalTexts,
  },

  covidvax2: {
    name: 'COVID-19, Страница 2',
    description: null,
    documentInfoTexts: covidvaxDocsAdditionalTexts,
  },

  covidvax3: {
    name: 'COVID-19, Страница 3',
    description: null,
    documentInfoTexts: covidvaxDocsAdditionalTexts,
  },

  covidvax4: {
    name: 'COVID-19, Страница 4',
    description: null,
    documentInfoTexts: covidvaxDocsAdditionalTexts,
  },

  med1: {
    name: 'Основной разворот медкнижки',
    name2: 'медицинской книжки',
    description: null,
  },
  med2: { name: 'Отметки о профилактических прививках' },
  med3: {
    name: 'Заключение врача о допуске к работе по результатам медицинского обследования',
  },
  med4: { name: 'Результаты обследования на туберкулёз' },
  med5: {
    name: 'Результаты исследования на носительство возбудителей кишечных инфекционных заболеваний',
  },
  med6: {
    name: 'Результаты лабораторных исследований и осмотра дерматовенеролога',
  },
  med7: { name: 'Результаты исследования на гельминтозы' },
  med8: {
    name: 'Результаты исследования на носительство возбудителей дифтерии',
  },
  med9: { name: 'Профессиональная гигиеническая подготовка и аттестация' },

  drive: {
    name: 'Водительское удостоверение',
  },
  drive_front: {
    name: 'Водительское удостоверение. Лицевая сторона',
    name2: 'водительского удостоверения',
    description: null,
  },
  drive_back: {
    name: 'Водительское удостоверение. Обратная сторона',
    name2: 'водительского удостоверения',
    description: null,
  },
};

export const TALKBANK_STATUSES = {
  ERROR: 'error',
  UNBOUND: 'unbound',
  REGISTERED: 'registered',
  UNREGISTERED: 'unregistered',
};

export const TALKBANK_INSTRUCTIONS_LINK = 'http://help.mygig.ru/article/44128';

export const RECEIPT_STATUSES = {
  NEED_RECEIPT: 'need_receipt',
  BANK_RECEIPT: 'bank_receipt',
  BANK_PROCESSED: 'bank_processed',
  MODERATION: 'moderation',
  INCORRECT: 'incorrect',
  NEED_ACT: 'need_act',
  OFFLINE: 'offline',
  CORRECT: 'correct',
  VOID: 'void',
};

export const MONTHS_SHORT = [
  'янв.',
  'фев.',
  'мар.',
  'апр.',
  'мая',
  'июн.',
  'июл.',
  'авг.',
  'сен.',
  'окт.',
  'ноя.',
  'дек.',
];

export const NATIONAL_ACCESSIBILITY = {
  RUS: 'RUS',
  EAEU: 'EAEU',
  OTHER: 'OTHER',
}

export const LEGAL_TYPES = {
  userAgreement: 'Пользовательское соглашение',
  agreementToProcessingUserData: 'Согласие на обработку персональных данных',
  privacyPolicy: 'Политика конфиденциальности',
};

export const ICON_SIZE = [120, 42];
export const ICON_OFFSET = [-16, -16]; // Пиксельное смещение иконки относительно заданной позиции.
export const ICON_SHAPE = {
  type: 'Rectangle',
  coordinates: [[-5, -5], ICON_SIZE], // активная область метки для клика
};

export const PLACEMARK_ZOOM = 18;

export const DATE_OF_PAYMENT = {
  END_OF_WORK: 'endOfWork',
  EVERY_WEEK: 'everyWeek',
  EVERY_TWO_WEEKS: 'everyTwoWeeks',
  ONCE_MONTH: 'onceMonth',
  END_OF_WORK_TEXTS: 'В конце смены',
  EVERY_WEEK_TEXT: 'Каждую неделю',
  EVERY_TWO_WEEKS_TEXT: 'Каждые две недели',
  ONCE_MONTH_TEXT: 'Раз в месяц',
  CHAR_OF_ACTUAL: '🔥',
};

DATE_OF_PAYMENT.DATE_OF_PAYMENT_TEXTS_MAPPING = {
  [DATE_OF_PAYMENT.END_OF_WORK]: DATE_OF_PAYMENT.END_OF_WORK_TEXTS,
  [DATE_OF_PAYMENT.EVERY_WEEK]: DATE_OF_PAYMENT.EVERY_WEEK_TEXT,
  [DATE_OF_PAYMENT.EVERY_TWO_WEEKS]: DATE_OF_PAYMENT.EVERY_TWO_WEEKS_TEXT,
  [DATE_OF_PAYMENT.ONCE_MONTH]: DATE_OF_PAYMENT.ONCE_MONTH_TEXT,
};
