import React, {Component} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import history from 'controllers/History';
import Icons from 'utils/Icons';

import cGreen from './counter_green.svg';
import cOrange from './counter_orange.svg';
import cRed from './counter_red.svg';

const greenStyle = () => ({ icon: cGreen, style: "rate-subtext green", text: "высокая" });
const orangeStyle = () => ({ icon: cOrange, style: "rate-subtext orange", text: "средняя" });
const redStyle = () => ({ icon: cRed, style: "rate-subtext red", text: "низкая" });

class OrdersItem extends Component {
  state = {
    isOpen: false,
    data: this.props.data,
    paymentPerHour: 0,
    newRates: {},
  }

  componentDidMount = () => {
    const data = this.props.data;
    const initPrice = this.state.paymentPerHour || this.state.data?.payment_per_hour;
    this.setState({ data, paymentPerHour: initPrice });
  }

  onClick = (e) => {
    const { data } = this.props;
    const [min, mid, max] = this.getRates(data).sort();

    history.push(`/rates/${data._id}`, { ...data , rates: [min, mid, max] });
  }

  getRates = (data) => {
    const { spec } = data;
    const professions = useSelector((s) => s.config.dictionaries.professions);
    const { rates } = professions.find((item) => item.spec === spec);
    return rates;
  }

  priceLabels(data) {
    const rates = this.getRates(data);
    const paymentPerHour = this.state.paymentPerHour;

    if (!rates) {
      return orangeStyle();
    }

    const [min, , max] = rates.sort();

    if (paymentPerHour > max) {
      return greenStyle();
    }

    if (paymentPerHour < min) {
      return redStyle();
    }

    return orangeStyle();
  }

  onChangeInput = (e) => {
    const value = e.target.value;
    const { _id } = this.state.data;
    this.props.updateRates({ [_id]: value });
    this.setState({ paymentPerHour: value });
  }

  render() {
    const data = this.state.data;
    const paymentPerHour = this.state.paymentPerHour;

    const peopleTotal = data.amount;

    const hoursView = () => {
      if (data.hours) {
        return (
          <div className="job_card-salary">
            <strong>{Math.round(data.hours)} ч.</strong>
          </div>
        )
      }
    }

    const { icon, style, text} = this.priceLabels(data);

    return (
      <div className="orders_item p2" onClick={this.onClick}>
        <div className="order_item-workers flex">
          <span>{Icons.worker}</span>
          {peopleTotal > 2 && (<>x<span>{peopleTotal}</span></>)}
          <div className="job_card-name"><strong><span>{data.custom_name}</span></strong></div>
          {hoursView()}
        </div>
        <div className="flex mb1 pt3 pb1">
          <div className="tag_wrapper">
            <div className="tag">
              <strong>{data.is_night && '🌗'} { moment(data.start).format('DD MMMM') } {data.start_time.replace(/^0/, '')} – {data.finish_time.replace(/^0/, '')}</strong>
            </div>
          </div>
        </div>
        <hr/>
        <div className="rate-block">
          <div className="block-left">
            <img alt="" src={icon}/>
            <div>
              <div className="rate-title">Ставка в час</div>
              <div className={style}>{text}</div>
            </div>
          </div>
          <div className="block-right" onClick={(e) => e.stopPropagation()}>
            <input className="text-input" onChange={this.onChangeInput} type="text" placeholder={`${paymentPerHour} ₽`} value={paymentPerHour} />
          </div>
        </div>
      </div>
    );
  }
}

export default OrdersItem;
