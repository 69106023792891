import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import Cookies from 'js-cookie';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

import api from 'controllers/Api';
import { errorHandler, parseReferrer } from 'utils/Helpers';
import UserController from 'controllers/User';
import TagsController from 'controllers/Tags';

import Modal2 from 'components/Modal2';
import CodeResender from './CodeResender';
import PhoneInput, { mask } from './PhoneInput';

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

const Login = ({ close, data, disableRedirect, disableClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [isShowChallenge, setIsShowChallenge] = useState(false);
  const [legalAccepted, setIsLegalAccepted] = useState(IS_PRODUCTION);
  const [legals, setLegals] = useState(null);
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    setToken(await executeRecaptcha('login'));
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    api
      .get('/legals')
      .then(({ data: legalsData }) =>
        legalsData
          ?.filter?.((legal) => legal.isActive)
          .reduce((acc, legal) => {
            acc[legal.type] = legal.name;
            return acc;
          }, {})
      )
      .then(setLegals)
      .finally(() => setIsLoading(false));
  }, []);

  const formik = useRef();

  const formErrorHandler = (e) => {
    setIsLoading(false);
    setErrors(errorHandler(e));
  };

  const submitCode = async (phone, code) => {
    try {
      const res = await api.post(
        `/auth/code`,
        { phone, code },
        { withCredentials: true }
      );

      let withRedirect = !data.callback;
      if (disableRedirect) withRedirect = false;
      UserController.onLogin(res.data, withRedirect);
      if (!disableClose && !res.data.isFirstLogin) close();
      if (data.callback) data.callback();

      TagsController.event('Auth', 'CodeValidation', 'Success');
    } catch (err) {
      TagsController.event('Auth', 'CodeValidation', 'Fail');
      formErrorHandler(err);
    }
  };

  const submitPhone = async (phone) => {
    return api.post(`/auth/phone`, {
      phone,
      token,
      meta: {
        ...parseReferrer(),
        // eslint-disable-next-line no-underscore-dangle
        _ga: Cookies.get()?._ga,
      },
    });
  };

  const onSubmit = async (values) => {
    // Защита от submit без кнопки
    if (!legalAccepted) return;

    // phone validation
    if (!values.phone || values.phone.includes('_')) {
      setErrors([`Введите телефон в формате: \n${mask.replace(/_/g, '*')}`]);
      return;
    }

    // update recaptcha token
    handleReCaptchaVerify();

    // show spinner & reset errors
    setIsLoading(true);
    setErrors(null);

    const phone = values.phone.replace(/[^\d+]/g, '');

    if (!isShowChallenge) {
      // request challenge
      try {
        const {
          data: { DEV_MODE },
        } = await submitPhone(phone);

        if (DEV_MODE) {
          // eslint-disable-next-line no-console
          console.log(`code: ${DEV_MODE}`);
          await submitCode(phone, DEV_MODE);
        }

        setIsShowChallenge(true);
        setIsLoading(false);
        TagsController.event('Auth', 'GetCode', 'Success');
      } catch (err) {
        TagsController.event('Auth', 'GetCode', 'Fail');
        formErrorHandler(err);
      }
    } else {
      // submit challenge
      await submitCode(phone, values.code);
    }
  };

  const onChangeNumber = () => {
    TagsController.event('Auth', 'Buttons', 'AnotherNumber');
    setIsLoading(false);
    setErrors(null);
    setIsShowChallenge(false);
  };

  const onResend = () => {
    const { values } = formik.current.state;
    if (!values.phone) return;
    const phone = values.phone.replace(/[^\d+]/g, '');

    submitPhone(phone)
      .then((response) => {
        // eslint-disable-next-line no-alert
        if (response.data.DEV_MODE) alert(`code: ${response.data.DEV_MODE}`);
      })
      .catch(formErrorHandler);
  };

  const onChangeLegalAccept = () => {
    setIsLegalAccepted((prev) => !prev);
  };

  const openLegalModal = (type, name) => {
    Modal2('ModalLegal', { data: { type, name } });
  };

  return (
    <Formik onSubmit={onSubmit} ref={formik} initialValues={{ phone: '' }}>
      {({ values }) => (
        <Form>
          {isShowChallenge ? (
            <>
              <p>
                На номер <strong>{values.phone}</strong> отправлен код для входа
              </p>
              <div className="form_group">
                <Field name="code" required placeholder="Введите код из СМС" />
                {errors && <div className="form_error">{errors}</div>}
              </div>
              <button
                type="submit"
                className="btn btn_primary btn_fullwidth"
                disabled={!(values.code && values.code.length === 4)}
              >
                Войти
              </button>
              <CodeResender onResend={onResend} />
              <span
                role="link"
                tabIndex={0}
                className="color_blue clickable"
                onClick={onChangeNumber}
              >
                Ввести другой номер телефона
              </span>
            </>
          ) : (
            <>
              <p>
                Введите ваш номер телефона. Мы вышлем на него код подтверждения
                для входа.
              </p>
              <div className="form_group">
                <PhoneInput
                  name="phone"
                  disabled={isLoading}
                  placeholder="+7"
                />
                {errors && errors.indexOf('Мы переехали') && (
                  <div className="form_error">
                    Мы переехали, для авторизации перейдите по ссылке:{' '}
                    <a href="https://hrd.mygig.ru">https://hrd.mygig.ru</a>
                  </div>
                )}
              </div>

              <div className="flex mb2">
                <checkbox
                  onClick={onChangeLegalAccept}
                  className={`checkbox mr2 ${legalAccepted && 'active'}`}
                />
                <span className="ml1">
                  Я принимаю{' '}
                  <strong
                    role="link"
                    tabIndex={0}
                    className="color_blue clickable"
                    onClick={() =>
                      openLegalModal('userAgreement', legals?.userAgreement)
                    }
                  >
                    пользовательское соглашение
                  </strong>{' '}
                  и{' '}
                  <strong
                    role="link"
                    tabIndex={0}
                    className="color_blue clickable"
                    onClick={() =>
                      openLegalModal('privacyPolicy', legals?.privacyPolicy)
                    }
                  >
                    политику конфиденциальности
                  </strong>
                  , а также даю согласие{' '}
                  <strong
                    role="link"
                    tabIndex={0}
                    className="color_blue clickable"
                    onClick={() =>
                      openLegalModal(
                        'agreementToProcessingUserData',
                        legals?.agreementToProcessingUserData
                      )
                    }
                  >
                    на обработку персональных данных
                  </strong>
                  .
                </span>
              </div>

              <button
                type="submit"
                className="btn btn_primary btn_fullwidth"
                disabled={isLoading || !legalAccepted}
              >
                Получить SMS пароль
              </button>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

Login.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.objectOf({
    callback: PropTypes.func,
  }),
  disableRedirect: PropTypes.bool,
  disableClose: PropTypes.bool,
};

Login.defaultProps = {
  disableRedirect: false,
  disableClose: false,
  data: {},
};

export default Login; // withRouter(Login);
