import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from 'controllers/Api';

function AcceptOferta() {
  const [text, setText] = useState('Loading...');
  const {
    phone,
    accept_oferta: oferta,
    role,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const isReady = useMemo(
    () => phone && !oferta && !['employer', 'supervisor'].includes(role),
    [phone, oferta, role],
  );

  const handleClick = useCallback(() => {
    api.patch(`/user/profile`, { accept_oferta: true }).then(() => {
      dispatch({
        type: 'USER_SET',
        params: { accept_oferta: true },
      });
    });
  }, [dispatch]);

  useEffect(() => {
    api.get('/legal/oferta_worker.html').then((r) => {
      const newText = r.data.match(/<body[^>]*>((.|[\n\r])*)<\/body>/im)[1];
      setText(newText);
    });
  }, []);

  return isReady
    ? createPortal(
        <div className="accept-offerta">
          <div className="content_block">
            <div className="alert_header flex mb2">
              <div className="alert_title">Оферта</div>
              <span
                role="img"
                aria-label="fire"
                className="alert_icon"
                style={{ fontSize: 48 }}
              >
                🔥
              </span>
            </div>
            <div>
              <strong>Прочтите и примите оферту</strong>
            </div>
          </div>

          <br />
          <br />

          <div className="content_block html">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>

          <div
            role="button"
            tabIndex={0}
            onClick={handleClick}
            className="btn btn_primary modal_btn offerta_btn"
          >
            Принять
          </div>
        </div>,
        document.getElementById('root'),
      )
    : null;
}

export default AcceptOferta;
