import React, { useState } from 'react';
import TransactionsController from 'controllers/Transactions';
import API from 'controllers/Api';

const SignAllActsAlert = () => {
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      await API.post('/user/transactions/sign_all_acts');
      await Promise.all([
        TransactionsController.getStats(),
        TransactionsController.getList(),
      ]);
    } catch (err) {
      console.error('Error on bulk act signing', err);
    } finally {
      setLoading(false);
    }
  };

  const btnText = loading ? 'Подписываем акты...' : 'Подписать все акты';

  return (
    <div className="content_block bg_yellow">
      <div className="content_block-title color_black mb2">
        Осталось подписать акты!
      </div>
      <p>Все акты можно подписать одной кнопкой!</p>
      <div className="flex flex-end">
        <button
          disabled={loading}
          onClick={submit}
          className="btn btn_small btn_blue"
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default SignAllActsAlert;
