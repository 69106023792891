/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import cn from 'classnames';

import {
  RECEIPT_STATUSES,
  DATE_OF_PAYMENT,
  ICON_SHAPE,
  ICON_OFFSET,
} from 'utils/Constants';

import { getFnsLink } from './FnsAppButton';
import Icons from './Icons';

const {
  BANK_RECEIPT,
  NEED_RECEIPT,
  MODERATION,
  INCORRECT,
  NEED_ACT,

  OFFLINE,
  CORRECT,
  VOID,
} = RECEIPT_STATUSES;

const Helpers = {};

export const parseReferrer = () => {
  const url = new URL(window.document.referrer || window.location.href);
  return {
    medium: url.searchParams.get('utm_medium'),
    source: url.searchParams.get('utm_source'),
  };
};

/**
 * @typedef {Object} Datetime
 * @property {number?} salary_per_job
 * @property {number?} salary_max
 * @property {number?} salary_min
 * @property {number?} hours
 * @property {number?} minutes
 */

/**
 * @typedef {Object} Order
 * @property {number?} salary_per_hour
 * @property {number?} salary_per_job
 * @property {number?} salary_per_job_fact
 * @property {number?} salary_max
 * @property {number?} salary_min
 * @property {number?} hours
 * @property {number?} minutes
 * @property {Datetime[]?} datetimes
 */

/**
 * @param {Order} order
 */
const calculateOrderSalary = (order) => {
  return order.salary_per_hour * (order.hours + (order.minutes ?? 0) / 60);
};

/**
 * @param {Order | Datetime} order
 * @returns {number | null}
 */
export const getSalary = (order) => {
  const salary =
    order.salary_per_job_fact ??
    Math.max(order.salary_per_job ?? 0, order.salary_max ?? 0) ??
    order.salary_min;
  if (salary <= 0) return NaN;
  return salary;
};

/**
 * @param {Order} order
 */
export const getBestSalary = (order) => {
  if (order.datetimes && order.datetimes.length) {
    const best = order.datetimes
      ?.slice()
      .sort((l, r) => getSalary(r) - getSalary(l))[0];
    const salaries = (order.datetimes || []).map(getSalary);
    const isMultiple = new Set(salaries).size > 1;

    const bestSalary = getSalary(best);
    const isMax = bestSalary === best?.salary_max;
    const isMin = bestSalary === best?.salary_min;

    return {
      isMultiple,
      bestSalary: bestSalary ?? calculateOrderSalary(best ?? {}) ?? 0,
      hours: best.hours,
      minutes: best.minutes,
      isMax,
      isMin,
    };
  }

  const bestSalary = getSalary(order);
  const isMax = bestSalary === order.salary_max;
  const isMin = bestSalary === order.salary_min;

  return {
    isMultiple: false,
    bestSalary: bestSalary ?? calculateOrderSalary(order) ?? 0,
    hours: order.hours,
    minutes: order.minutes,
    isMax,
    isMin,
  };
};

export const isCheckinTime = (start) =>
  moment().add(1, 'hour').isSameOrAfter(start);

export const receiptInfo = (status, receipt) => {
  let isActNeeded = false;
  let isReceiptNeeded = false;
  let isProcessing = false;
  let message = null;

  const NEED_RECEIPT_TEXT = 'Нужно приложить чек!';

  switch (status) {
    case BANK_RECEIPT: {
      isActNeeded = false;
      isReceiptNeeded = false;
      isProcessing = true;
      message = 'будет выписан банком';
      break;
    }
    case NEED_ACT: {
      isActNeeded = true;
      isReceiptNeeded = true;
      break;
    }
    case NEED_RECEIPT: {
      isReceiptNeeded = true;
      message = NEED_RECEIPT_TEXT;
      break;
    }
    case MODERATION: {
      if (receipt) {
        isProcessing = true;
        message = 'Выставлен';
      } else {
        isReceiptNeeded = true;
        message = NEED_RECEIPT_TEXT;
      }
      break;
    }
    case INCORRECT: {
      isReceiptNeeded = true;
      message = 'Чек неправильный';
      break;
    }
    case VOID: {
      isReceiptNeeded = true;
      message = NEED_RECEIPT_TEXT;
      break;
    }
    case OFFLINE: {
      isProcessing = true;
      message = 'Чек ожидает синхронизации с ИФНС';
      break;
    }
    case CORRECT:
    default:
  }

  const isOk = !isActNeeded && !isReceiptNeeded && !isProcessing;

  return {
    message,
    isOk,
    isActNeeded,
    isReceiptNeeded,
    isProcessing,
  };
};

export const formatName = (user) => {
  const fullName = `${user?.firstname || ''} ${user?.lastname || ''}`.trim();
  return fullName.length ? fullName : '(имя не указано)';
};

export const formatPrice = (v, trimPennies = false) =>
  v
    ? v
        .toLocaleString('ru-RU', {
          style: 'currency',
          currency: 'RUB',
          currencyDisplay: 'code',
          // maximumSignificantDigits: 10,
          maximumFractionDigits: trimPennies ? 0 : 2,
          minimumFractionDigits: trimPennies ? 0 : undefined,
        })
        .replace('RUB', '')
    : '0';

export const calculateJobTotalSalary = (jobData) => {
  const salaryByHours = jobData.salary_per_hour * jobData.hours;
  const salaryByUnits = jobData.salary_per_unit * jobData.units_planned;
  let salary;

  if (jobData.salary_per_unit) {
    if (jobData.salary_per_hour) {
      salary = `~ ${formatPrice(
        salaryByHours + salaryByUnits,
        true,
      ).toString()}`;
    } else {
      salary = formatPrice(salaryByUnits, true);
    }
  } else {
    salary = formatPrice(salaryByHours, true);
  }

  return salary;
};

export const formatDuration = (minutes) => {
  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);
  return m ? `${h} ч ${m} мин` : `${h} ч`;
};

// NOTE: не используется. Вынести как отдельный компонент при надобности
// export const WithContext = (Context, Component) => {
//   return (props) => (
//     <Context.Consumer>
//       {(value) => <Component {...props} contextValue={value} />}
//     </Context.Consumer>
//   );
// };

export const simulateModalLayout = (remove) => {
  if (remove === false) {
    document.body.classList.remove('modal_fake');
  } else {
    document.body.classList.add('modal_fake');
  }
};

export default Helpers;

const errorHandler = (e) => {
  const errors = [];
  if (e && e.response) {
    if (e.response.data && e.response.data.message) {
      if (!Array.isArray(e.response.data.message)) {
        e.response.data.message = [{ message: e.response.data.message }];
      }
      e.response.data.message.forEach((error) => {
        errors.push(error.message);
      });
    } else if (e.response.data && e.response.data.error) {
      errors.push(e.response.data.error);
    } else {
      switch (e.response.status) {
        case 401:
          errors.push('Unauthorized, try re-login');
          break;
        case 400:
          errors.push('Произошла ошибка');
          break;
        default:
      }
    }
  } else if (e && e.message) {
    errors.push(e.message);
  }

  return errors;
};

export { errorHandler };

export function FormErrorHandler(e) {
  this.setState({ isLoading: false, errors: errorHandler(e) });
}

export { default as Events } from './Events';

export function getOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export const Alert = (options) => {
  return confirmAlert({
    closeOnEscape: false,
    closeOnClickOutside: options.closeOnClickOutside || false,
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => {
      return (
        <div className="alert">
          <div className="alert_header flex mb2">
            <div className="alert_title">{options.title}</div>
            <div className="alert_icon">{options.icon}</div>
          </div>
          <div className="alert_text color_gray mb2">{options.text}</div>
          <div className="alert_footer flex">{options.buttons(onClose)}</div>
        </div>
      );
    },
  });
};

export const getPosition = (isSilent = false) => {
  const reject = () => {
    const url =
      getOS() === 'iOS'
        ? 'https://mygig.zendesk.com/hc/ru/articles/360007514657-Настройка-доступа-к-геолокации-на-iOs'
        : 'https://mygig.zendesk.com/hc/ru/articles/360007514637-Настройка-доступа-к-геолокации-на-Android';

    Alert({
      title: (
        <>
          Нужно <br />
          снять запрет
        </>
      ),
      icon: '☃️',
      text: (
        <>
          Разрешите браузеру определять местоположение. <br />
          Эта опция может быть в настройках браузера или настройках смартфона.{' '}
          <br />
          Включите ее и возвращайтесь на сайт
        </>
      ),
      buttons: (onClose) => (
        <>
          <a href={url} target="#" className="btn btn_small">
            Инструкция
          </a>
          <div
            role="button"
            tabIndex={0}
            className="btn btn_small btn_primary"
            onClick={onClose}
          >
            Закрыть
          </div>
        </>
      ),
    });
  };
  if (navigator.geolocation) {
    return new Promise((resolve) => {
      return navigator.geolocation.getCurrentPosition(
        ({ coords }) =>
          resolve({ lat: coords.latitude, lon: coords.longitude }),
        () => {
          if (!isSilent) return reject();
          return resolve({});
        },
      );
    });
  }
  return new Promise(() => reject());
};

export const checkPositionAccess = () => {
  if (navigator.permissions && navigator.geolocation) {
    return navigator.permissions.query({ name: 'geolocation' });
  }
  return new Promise((_, reject) => reject());
};

export const getLocationByCity = () => {
  const city = JSON.parse(localStorage.getItem('city'));

  return city ? [city.lat, city.lon] : [55.751476906419136, 37.620548047607436];
};

export const utcOffset = () => moment().utcOffset();

export const dateToText = (date, format) => {
  if (!date) return '';
  if (typeof date !== 'string' && !date.toISOString) return '';
  const tmp = moment(date).utcOffset(utcOffset());
  let text;

  if (format) {
    text = tmp.format(format).toLowerCase();
  } else {
    const calendar = tmp.calendar().toLowerCase();
    if (calendar.includes('today') || calendar.includes('сегодня')) {
      text = `Сегодня`;
    } else if (calendar.includes('tomorrow') || calendar.includes('завтра')) {
      text = `Завтра`;
    } else {
      text = tmp.format('D MMM').toLowerCase();
    }
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const dateToTextLong = (date) => {
  const string = moment(date).locale('ru').calendar(null, {
    sameDay: '[Сегодня], D MMMM',
    nextDay: '[Завтра], D MMMM',
    nextWeek: 'dddd, D MMMM',
    lastDay: 'dddd, D MMMM',
    lastWeek: 'dddd, D MMMM',
    sameElse: 'dddd, D MMMM',
  });
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const UUID = () => Math.random().toString(36).slice(2);

export function pick(obj, keys) {
  return keys
    .map((k) => (k in obj ? { [k]: obj[k] } : {}))
    .reduce((res, o) => Object.assign(res, o), {});
}

export const randomPercents = (min, max) =>
  `${Math.floor(Math.random() * (max - min + 1)) + min}%`;

export const withContext = (Context, Element) => {
  return React.forwardRef((props, ref) => {
    return (
      <Context.Consumer>
        {(context) => <Element context={context} {...props} ref={ref} />}
      </Context.Consumer>
    );
  });
};

export const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i < 6; i++) {
    let star = Icons.star.empty;
    if (rating >= i - 0.8) star = Icons.star.half;
    if (rating >= i - 0.2) star = Icons.star.full;
    stars.push(star);
  }
  return <div className="stars">{stars}</div>;
};

export const isDesktop = () => window.innerWidth > 992;

export const formatPhoneNumber = (str) => {
  if (!str) return null;
  const tmp = `${str}`.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
  return `+7 (${tmp[2]}) ${tmp[3]}-${tmp[4]}-${tmp[5]}`;
};

export function decl(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

export const openFNS = () => {
  const OS = getOS();
  window.open(getFnsLink(OS), '_blank');
};

/**
 * Prepare input value in string mode
 * If parsed value is NaN null will be returned
 * @param {number | string} input
 * @param {{allowDecimal: boolean, round: number, decimalSeparator: string}} decimal
 * @param {{min: number, max: number}} edges
 * @return {string | null}
 */
export const prepareInputNumber = (input, decimal, edges) => {
  if (!input) {
    return '';
  }

  if (input.length === 1 && input[0] === '-') {
    return input;
  }

  let isLastDot =
    input.endsWith('.') || input.endsWith(decimal?.decimalSeparator);

  let value =
    typeof input === 'string'
      ? Number(input.replace(decimal?.decimalSeparator, '.'))
      : input;

  if (Number.isNaN(value)) {
    return null;
  }

  if (!decimal?.allowDecimal) {
    isLastDot = false;
    value = Math.trunc(value);
  } else if (decimal?.round) {
    value = Math.trunc(value * 10 ** decimal.round) / 10 ** decimal.round;
  }
  if (typeof edges?.max === 'number' && value > edges.max) return edges.max;
  if (typeof edges?.min === 'number' && value < edges.min) return edges.min;

  const separator = decimal?.decimalSeparator ?? '.';

  return isLastDot
    ? `${value}${separator}`
    : value.toString().replace('.', separator);
};

// to avoid cycle dependencies w/ Api
const baseURL = process.env.REACT_APP_BASE_URL;
export const getLegalLink = (name) => `${baseURL}/legals/view-file/${name}.pdf`;

export const getOfferString = (
  item,
  { mobileTeam = false, prefixMultiple = true, displayBy = true } = {},
) => {
  if (mobileTeam) return `${item.hours} ч`;

  const { bestSalary, minutes, hours, isMax, isMin, isMultiple } =
    getBestSalary(item);
  const prefix =
    /* eslint-disable-next-line no-nested-ternary */
    isMax || (isMultiple && prefixMultiple) ? 'до ' : isMin ? 'от ' : '';
  const time = formatDuration(minutes ?? hours * 60);

  if (bestSalary) {
    return `${prefix}${formatPrice(bestSalary, !(bestSalary % 1))} ₽${
      displayBy ? ` / ${time}` : ''
    }`;
  }

  return `~ ${formatPrice(item.payment_per_unit * item.units_planned)} ₽${
    displayBy ? ` / ${item.units_planned}` : ''
  } ед.`;
};

export const makePlacemarks = (
  data,
  history,
  { mobileTeam = false, single = false, displayBy = true } = {},
) => {
  try {
    const placemarks = data.map((item) => {
      if (!item) return null;
      const location = item.workplace?.latlon;
      if (!location) return null;
      const dateOfPaymentIsEndWork =
        item.workplace?.client?.dateOfPayment === DATE_OF_PAYMENT.END_OF_WORK ||
        false;
      const offerString = getOfferString(item, {
        mobileTeam,
        prefixMultiple: !single,
        displayBy,
      });

      const regularClassName = cn(
        'map_placemark-circle',
        { ' inactive': item.is_active === false && item.type !== 'regular' },
        { ' regular': item.type === 'regular' },
      );
      const regularClassNamePrice = cn(
        'map_placemark-price',
        { ' inactive': item.is_active === false && item.type !== 'regular' },
        { ' regular': item.type === 'regular' },
      );

      const layout = window?.ymaps?.templateLayoutFactory?.createClass(`
        <div class="${regularClassName}">
          <img src="/img/icons/professions/${item.spec}.svg" />
        </div>
        <div class="${regularClassNamePrice}">${offerString}</div>
        ${
          (single &&
            !mobileTeam &&
            dateOfPaymentIsEndWork &&
            `<div class="map_placemark-note">⚡️ деньги сразу </div>`) ||
          ''
        }
      `);

      const options = {
        iconLayout: layout,
        iconShape: ICON_SHAPE,
        iconOffset: ICON_OFFSET,
      };

      if (single) {
        delete options.iconShape;
        options.iconOffset = [-84, -27];
      }

      const placemark = new window.ymaps.Placemark(
        location,
        {
          data: pick(item, [
            'id',
            'salary_per_hour',
            'salary_per_job',
            'salary_max',
            'salary_min',
            'hours',
            'minutes',
            'is_active',
            'type',
          ]),
        },
        options,
      );

      // handle click only for big map
      if (!single) {
        placemark.events.add('click', () => {
          history.push(`/jobs/${item.id}`);
        });
      }

      return placemark;
    });

    return placemarks.filter((item) => item !== null);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
};

/**
 * get distance between two coordinates
 * @param {[number, number]} a [lat1, lon1]
 * @param {[number, number]} b [lat2, lon2]
 * @returns kilometers
 */
export const getDistance = (a, b) => {
  const [lat1, lon1] = a;
  const [lat2, lon2] = b;
  const R = 6371; // radius of Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;

  const ab =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1Rad) *
      Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(ab), Math.sqrt(1 - ab));

  return R * c;
};
