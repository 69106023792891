import React, { Component, Suspense } from 'react';

import { Events } from 'utils/Helpers';
import history from 'controllers/History';
import Icons from 'utils/Icons';

const timing = 100;

export const Header = (props) => {
  return (
    <div className="header modal_header">
      {props.back && <div className="modal_header-back" onClick={props.navigate} data-route={props.back}>{Icons.back}</div>}
      {props.title ? (
        <div className="modal_header-title text_medium">{props.title}</div>
      ) : (
        <div className="header_logo">
          <img alt="MyGig logo" src="/img/logo.svg" />
        </div>
      )}
      {props.close && <div className="modal_header-close" onClick={props.close}>{Icons.close}</div>}
    </div>
  );
}

export class Wrapper extends React.Component {

  state = {
    route: null,
  }

  _context = null;

  componentDidMount = () => {
    //this.setState(this._state)
  // console.log(this.context);
  }

  navigate = (e, data) => {
    let route;
    if(typeof e === 'string') {
      route = e;
    } else {
      route = e.currentTarget.dataset.route;
    }
    this.setState({ route });
  }

  render() {
    const { close } = this.props;
    const { route } = this.state;
    if(!route || !this._context) return null;

    const Route = this.routes[route];

    const value = {
      state: this.state,
      navigate: this.navigate,
      close,
      ...this.props,
      functions: this.functions,
    }

    return (
      <this._context.Provider value={value}>
        <Suspense fallback={<div>Загрузка...</div>}>
          <Route />
        </Suspense>
      </this._context.Provider>
    );
  }

}

export default class Modal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      data: {},
    }
  }

  componentDidMount() {
    const { id } = this.props;
    Events.follow(`MODAL_SHOW-${id}`, this.open);
    Events.follow(`MODAL_HIDE-${id}`, this.close);
    document.addEventListener("keydown", this.handleEscapeButton, false);
  }

  open = ({ detail: data }) => {
    const { id } = this.props;
    this.unlisten = history.listen(() => Events.dispatch(`MODAL_HIDE-${id}`));
    this.setState({ isShow: true, data })
    setTimeout(() => {
      document.body.classList.add('modal__open');
    }, timing);
  }

  close = () => {
    document.body.classList.remove('modal__open');
    setTimeout(() => {
      this.setState({ isShow: false, data: {} })
    }, timing);
  }

  handleEscapeButton = (e) => {
    if(e.keyCode === 27) this.close();
  }

  componentWillUnmount = () => {
    const { id } = this.props;
    Events.unfollow(`MODAL_SHOW-${id}`);
    Events.unfollow(`MODAL_HIDE-${id}`);
    document.removeEventListener("keydown", this.handleEscapeButton, false);
    // this.unlisten();
  }

  render() {
    const { isShow, data } = this.state;
    const { children, id } = this.props;
    if(!isShow) return null;
    return (
      <div className="modal_overlay" id={id} >
        <div className="modal">
          {React.cloneElement(children, { ...this.props, data, close: this.close })}
        </div>
      </div>
    )
  }
}
