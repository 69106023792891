import React from 'react';
import { connect } from 'react-redux';
import Preloader from  'components/Preloader';
import Cookies from 'js-cookie';
import Login from 'containers/Login';

const NoAceess = () => <div className="p2">У вас нет доступа к этой странице</div>;

const _ProtectedRoute = (props) => {
  const { token } = Cookies.get() || {};
  if(!token) return <Login disableClose={true} disableRedirect={true} />

  const { role, Component, user } = props;
  if(!user.id) return <div className="p2"><Preloader /></div>;

  if(role) {
    if(Array.isArray(role) && !role.includes(user.role)) return <NoAceess />;
    if(typeof role === 'string' && role !== user.role) return <NoAceess />;
  }

  return <Component {...props} />
}
const ProtectedRoute = connect(state => ({ user: state.user }))(_ProtectedRoute);
export default (Component, role = null) => (props) => <ProtectedRoute Component={Component} {...props} role={role} />
