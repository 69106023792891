import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import JobCard from 'components/JobCard';
import FakeJobCard from 'components/JobCard/Fake';
import VirtualList from 'components/VirtualList';
import FilterController from 'controllers/Filter';

const List = ({ fetch, isLoading }) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.jobs.data);

  useEffect(() => {
    const { filters, otherParams } = FilterController.parse();

    dispatch({
      type: 'JOBS_FILTER_SET',
      params: {
        activeFilters: filters,
        otherSearchParams: otherParams,
      },
    });

    fetch();
  }, [dispatch, fetch]);

  if (isLoading) {
    return (
      <>
        <FakeJobCard />
        <FakeJobCard />
        <FakeJobCard />
      </>
    );
  }
  if (!data || data.length === 0) {
    return (
      <div className="empty_state">
        <span role="img" aria-label="empty" className="empty_state-icon">
          🤷‍♂️
        </span>
        <div className="empty_state-title">Ничего не найдено</div>
        <div className="empty_state-text">
          Попробуйте изменить параметры поиска <br />
          или воспользуйтесь фильтрами.
        </div>
      </div>
    );
  }

  return (
    <VirtualList className="p2" height="calc(100vh - 165px)">
      {data?.map((item) => (
        <JobCard key={item.id || item._id} data={item} similar={data} />
      ))}
    </VirtualList>
  );
};

List.propTypes = {
  fetch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

List.defaultProps = {
  isLoading: false,
};

export default List;
