import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Icons from 'utils/Icons';
import { baseURL } from 'controllers/Api';

const ProfileSection = ({ onClickAuth }) => {
  const { isAuthorized, role, id, firstname, lastname, phone } = useSelector(
    (state) => state.user,
  );

  if (!isAuthorized)
    return (
      <div className="menu_overlay-list menu_overlay-profile">
        <div
          role="button"
          tabIndex={0}
          className="no-link"
          onClick={onClickAuth}
          style={{ cursor: 'pointer' }}
        >
          <span className="mra">Войти</span> {Icons.next}
        </div>
      </div>
    );

  const withName = (
    <>
      <span
        className="menu_overlay-avatar"
        style={{ backgroundImage: `url(${baseURL}/user/${id}/avatar)` }}
      />{' '}
      <span>{firstname ? `${firstname} ${lastname || ''}` : `+${phone}`}</span>
    </>
  );

  return (
    <div className="menu_overlay-list menu_overlay-profile">
      {role === 'worker' ? (
        <Link to="/profile">
          {withName} {Icons.next}
        </Link>
      ) : (
        <div className="no-link">{withName}</div>
      )}
    </div>
  );
};

ProfileSection.propTypes = {
  onClickAuth: PropTypes.func.isRequired,
};

export default ProfileSection;
