import React from 'react';
import moment from 'moment';
import Icons from 'utils/Icons';
import Context from "../Context";
import { withContext } from "utils/Helpers";

const _second = 1000;
const _minute = _second * 60;
const _hour = _minute * 60;
const _day = _hour * 24;



// статусы для остановки таймера
const fixedStatuses = [
  "completed", //"Завершена",
  "confirmed", // "Подтверждена",
  "toolate", //"Отменена из-за опоздания",
  "cancelled", // "Отменена",
  "expired", // "Просрочена",
  "failed", // "Не выполнена",
  "disputed", // "Открыт спор",
  "refunded", // "Оформлен возврат",
];

const initialState = {
  timeLeft: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
}

class Timer extends React.Component {

  state = {
    ...initialState,
  }

  interval = null;

  componentDidUpdate = (prevProps) => {
    const { isLoading } = this.props.context;
    if(prevProps.context.isLoading === true && isLoading === false) {
      this.updateTimer(true); // первый запуск без таймаута
      this.interval = setInterval(this.updateTimer, 1000);
    }
  }

  componentWillUnmount = () => {
    this.stopTimer();
  }

  updateTimer = (initialStart = false) => {
    const { start, finish, status, ...data } = this.props.context.data;
    const { updateTimeLeft  } = this.props.context.actions;

    // не "тикать" при фиксированных статусах
    if(fixedStatuses.includes(status)) {
      this.stopTimer();
      return;
    }

    let msToStart = moment(start).diff();
    const msToFinish = moment(finish).diff();

    // опоздание, прибавление delay к start
    if(['delayed', 'waiting'].includes(status)) {
      msToStart = msToStart + (data.delay * 60 * 1000);
    }

    let timeLeft = msToStart;

    if(status === 'inprogress') {
      timeLeft = msToFinish;
      if(timeLeft <= 0) {
        this.stopTimer();
        return;
      }
    } else {
      if(timeLeft <= 0) {
        // новый статус придет в течении 30 минут с бэка
        timeLeft = 0;
      }
    }

    const values = {
      timeLeft,
      days: Math.floor(timeLeft / _day),
      hours: Math.floor((timeLeft % _day) / _hour),
      minutes: Math.floor((timeLeft % _hour) / _minute),
      seconds: Math.floor((timeLeft % _minute) / _second),
    };

    this.setState({ ...values });

    // обновление контекстного таймера только раз в минуту
    if(initialStart || values.seconds === 59) {
      updateTimeLeft(values.days, values.hours, values.minutes);
    }

  }

  stopTimer = () => {
    this.interval && clearInterval(this.interval);
    this.setState(initialState);
    this.props.context.actions.updateTimeLeft(0);
  }

  format = (x) => x < 10 ? `0${x}` : x;

  render() {
    const { isLoading, data, actions } = this.props.context;
    const { days, hours, minutes, seconds } = this.state;

    // console.log(days, hours, minutes, seconds);
    if(isLoading) {
      return (
        <div className="content_block">
          <div className="content_block-header mb2">
            <div className="content_block-title">
              <div className="fake" style={{ width: '60%' }} />
            </div>
            <div className="content_block-link">
              <div className="fake" style={{ width: '60%' }} />
            </div>
          </div>
          <div className="countdown_block flex __fake">
            <div>
              <strong>
                <span className="fake" />
                <span className="fake" />
              </strong>
              <div><span className="fake" /></div>
            </div>
            <strong>:</strong>
            <div>
              <strong>
                <span className="fake" />
                <span className="fake" />
              </strong>
              <div><span className="fake" /></div>
            </div>
            <strong>:</strong>
            <div>
              <strong>
                <span className="fake" />
                <span className="fake" />
              </strong>
              <div><span className="fake" /></div>
            </div>
          </div>
        </div>
      )
    }
    if(isLoading === false && fixedStatuses.includes(data.status)) return null; //<div>finish!</div>;

    let title = 'До начала работы осталось';
    if(['inprogress', 'checkingout'].includes(data.status)) title = 'Осталось работать';

    return (
      <div className="content_block">
        <div className="content_block-header mb2">
          <div className="content_block-title">{title}</div>
          <div className="content_block-link" onClick={actions.cancel}>Отменить {Icons.cancelCircle}</div>
        </div>
        <div className="countdown_block flex">
          {days ? (
            <>
              <div>
                <strong>{this.format(days)}</strong>
                <div>дней</div>
              </div>
              <strong>:</strong>
              <div>
                <strong>{this.format(hours)}</strong>
                <div>часов</div>
              </div>
              <strong>:</strong>
              <div>
                <strong>{this.format(minutes)}</strong>
                <div>минут</div>
              </div>
            </>
          ) : (
            <>
              <div>
                <strong>{this.format(hours)}</strong>
                <div>часов</div>
              </div>
              <strong>:</strong>
              <div>
                <strong>{this.format(minutes)}</strong>
                <div>минут</div>
              </div>
              <strong>:</strong>
              <div>
                <strong>{this.format(seconds)}</strong>
                <div>секунд</div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

}

export default withContext(Context, Timer);