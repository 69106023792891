import React from 'react';
import api from 'controllers/Api';
import Icons from 'utils/Icons';
import { getPosition, simulateModalLayout, renderStars } from 'utils/Helpers';
import { Link } from 'react-router-dom';
import Header from 'containers/Job/components/Header';
import Map from 'containers/Job/components/Map';
import NotFound from 'components/NotFound';
import Modal2 from 'components/Modal2';
import { supportPhone } from 'utils/Constants';
import TagsController from 'controllers/Tags';
import { connect } from 'react-redux';
import Timer from './components/Timer';
import ActionBlock from './components/ActionBlock';
import Context from './Context';
import Details from '../Job/components/Details';
import Salary from '../Job/components/Salary';
import Documents from '../Job/components/Documents';
import UserController from '../../controllers/User';
import SignedDocuments from './components/SignedDocuments';

class SchedulePage extends React.Component {
  state = {
    data: {},
    isLoading: true,
    isSending: false,
    timeLeft: null,
    timeLeftDays: 0,
    timeLeftHours: 0,
    timeLeftMinutes: 0,
    actions: {
      updateStatus: (status) => {
        const { data } = this.state;
        data.status = status;
        this.setState({ data });
      },
      updateTimeLeft: (
        timeLeftDays = 0,
        timeLeftHours = 0,
        timeLeftMinutes = 0,
      ) => {
        this.setState({ timeLeftDays, timeLeftHours, timeLeftMinutes });
      },
      going: () => {
        const { data, actions } = this.state;
        TagsController.event('Buttons', 'ConfirmBooking', data.custom_name);
        this.disableButtons();
        api
          .post(`/schedule/${data.id}/go`, {})
          .then(() => {
            this.enableButtons();
            actions.updateStatus('going');
          })
          .catch((e) => {
            alert(
              `Произошла ошибка: \n\n${e.response?.data?.error || e.message}`,
            );
            this.enableButtons();
          });
      },
      cancel: () => {
        const { data } = this.state;
        TagsController.event('Buttons', 'CancelJob', data.custom_name);
        Modal2('ModalCancelJob', { data });
      },
      delay: () => {
        const { data } = this.state;
        TagsController.event('Buttons', 'Late', data.custom_name);
        const onSuccess = () => this.fetch();
        Modal2('ModalDelayJob', {
          data,
          onSuccess,
        });
      },
      checkin: async () => {
        const { data, actions } = this.state;
        TagsController.event('Buttons', 'OnPlace', data.custom_name);
        const coords = await getPosition(true);
        this.disableButtons();
        api
          .post(`/schedule/${data.id}/checkin`, coords)
          .then((r) => {
            this.enableButtons();
            actions.updateStatus(r.data.status);
            if (r.data.status === 'inprogress')
              TagsController.ecomEventJobStart(data);
          })
          .catch(() => {
            this.enableButtons();
          });
      },
      finish: async () => {
        const { data, actions } = this.state;
        TagsController.event('Buttons', 'FinishJob', data.custom_name);
        const coords = await getPosition(true);
        this.disableButtons();
        api
          .post(`/schedule/${data.id}/checkout`, coords)
          .then((r) => {
            this.enableButtons();
            actions.updateStatus(r.data.status);
            TagsController.ecomEventJobFinish(data);
            TagsController.ecomEventJobConfirmed(data);
          })
          .catch(() => {
            this.enableButtons();
          });
      },
      call: () => {
        TagsController.event('Buttons', 'OperatorContact', 'Call');
        window.open(`tel:${supportPhone}`, '_self');
      },
    },
  };

  disableButtons = () => this.setState({ isSending: true });

  enableButtons = () => this.setState({ isSending: false });

  intervalFetch = null;

  componentDidMount() {
    // history data
    const data = this.props.location?.state?.data;
    if (data) this.setState({ data, isLoading: false });

    simulateModalLayout();
    this.fetch();
    this.intervalFetch = setInterval(this.fetchUpdates, 30 * 1000);

    document.querySelector('.layout').scrollTop = 0;
  }

  componentWillUnmount() {
    simulateModalLayout(false);
    clearInterval(this.intervalFetch);
  }

  fetch = () => {
    const id = this.props.match?.params?.id;
    api
      .get(`/schedule/${id}`)
      .then((r) => {
        this.setState({
          data: r.data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          data: null,
        });
        clearInterval(this.intervalFetch);
      });
  };

  fetchUpdates = () => {
    const { id } = this.state.data;
    api.get(`/schedule/${id}`).then((r) => {
      const { data, actions } = this.state;
      // r.data.status = 'inprogress';
      if (data.status !== r.data.status) actions.updateStatus(r.data.status);
      if (data.status !== 'confirmed' && r.data.status === 'confirmed') {
        UserController.getBalance();
      }
    });
  };

  renderContact = () => {
    const { contact, status } = this.state.data;
    const showPhone = ['checkingin', 'checkingout', 'inprogress'].includes(
      status,
    );
    const showChat = !['doccheck', 'confirmed', 'cancelled', 'failed'].includes(
      status,
    );

    const onClickWrite = () =>
      TagsController.event('Buttons', 'CustomerContact', 'Chat');
    const onClickCall = () =>
      TagsController.event('Buttons', 'CustomerContact', 'Call');

    return (
      <div className="content_block">
        <div className="content_block-title mb2">Заказчик</div>
        <div className="">
          {contact?.firstname} {contact?.lastname}
        </div>
        {(showChat || showPhone) && (
          <div className="flex_btns mt2">
            {showChat && contact?.chat && (
              <Link
                onClick={onClickWrite}
                to={`/chats/${contact.chat}`}
                className="btn btn_small"
              >
                Написать
              </Link>
            )}
            {showPhone && contact?.phone && (
              <a
                onClick={onClickCall}
                href={`tel:+${contact.phone}`}
                className="btn btn_small btn_green btn_outline"
              >
                Позвонить
              </a>
            )}
          </div>
        )}
      </div>
    );
  };

  onClickChange = () => {
    TagsController.event(
      'Buttons',
      'ChangeReview',
      this.state.data.custom_name,
    );
  };

  renderReview = () => {
    const { review } = this.state.data;
    if (!review) return null;
    return (
      <div className="content_block">
        <div className="content_block-header mb2 flex_center">
          <strong>{review.rating.toFixed(1)}</strong>
          {renderStars(review.rating)}
          <Link
            onClick={this.onClickChange}
            to={`${document.location.pathname}/review`}
            className="content_block-link"
          >
            Изменить {Icons.next}
          </Link>
        </div>
        {review.text}
      </div>
    );
  };

  render() {
    const { data } = this.state;
    const { mobile_team } = this.props.user;
    // if(isLoading) return <div className="layout_tall"><Preloader /></div>;
    if (data === null) return <NotFound />;

    return (
      <Context.Provider value={this.state}>
        <>
          <Link to="/schedule" className="modal_close">
            {Icons.close}
          </Link>
          {data?.workplace?.latlon ? (
            <Map data={data} />
          ) : (
            <div className="job_page-map" />
          )}
          <div className="job_page-container">
            <Header data={data} isSchedulePage />
          </div>
          <Timer />
          <ActionBlock />
          {this.renderReview()}
          {this.renderContact()}
          <Details data={data} />
          {!mobile_team && <Salary data={data} />}
          <Documents data={data} />
          <SignedDocuments data={data.signed_docs} />
        </>
      </Context.Provider>
    );
  }
}

export default connect((state) => {
  return {
    user: {
      mobile_team: state.user.mobile_team,
    },
  };
})(SchedulePage);
