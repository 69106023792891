export default {

  follow: (name, target) => {
    if(!document.EventsList) document.EventsList = {}
    document.EventsList[name] = true
    document.addEventListener(name, target)
  },

  unfollow: (name, target) => {
    if(document.EventsList && document.EventsList[name]) delete document.EventsList[name]
    document.removeEventListener(name, target)
  },

  list: () => (document.EventsList && Object.keys(document.EventsList).map(key => key)) || [],

  dispatch: (name, params = {}) => {
    document.dispatchEvent(
      new CustomEvent(name, {
        detail: params,
      })
    );
  },
}
