import React, { useRef } from 'react';
import { useVirtual } from 'react-virtual';
import PropTypes from 'prop-types';

const VirtualList = ({ children: rows, height, width, ...props }) => {
  const parentRef = useRef();

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
  });

  return (
    <>
      <div
        ref={parentRef}
        style={{
          height,
          overflow: 'auto',
        }}
        {...props}
      >
        <div
          style={{
            height: rowVirtualizer.totalSize,
            width,
            position: 'relative',
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <div style={{ height: rows[virtualRow.index] }}>
                {rows[virtualRow.index]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

VirtualList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

VirtualList.defaultProps = {
  width: '100%',
};

export default VirtualList;
