import React from 'react';

import Icons from 'utils/Icons';

import MenuIcons from './MenuIcons';

const MenuInfo = () => (
  <div className="menu_overlay-list">
    <a href="https://help.mygig.ru" target="_blank" rel="noopener noreferrer">
      {MenuIcons.thunder} <span>Справочник</span> {Icons.next}
    </a>
    <a
      href="https://help.mygig.ru/article/43825"
      target="_blank"
      rel="noopener noreferrer"
    >
      {MenuIcons.question} <span>Как работает MyGig?</span> {Icons.next}
    </a>

    <a href="https://mygig.ru/legal" target="_blank" rel="noopener noreferrer">
      {MenuIcons.binocular} <span>Правовые документы</span> {Icons.next}
    </a>
  </div>
);

export default MenuInfo;
