import React, { useState, useEffect } from 'react';
import Icons from 'utils/Icons';
import api, { baseURL } from 'controllers/Api';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Events } from 'utils/Helpers';
import TagsController from 'controllers/Tags';

const Header = ({ user, data, onFetch, ...props }) => {
  const [isLiked, setLiked] = useState(data.is_favorite);
  useEffect(() => setLiked(data.is_favorite), [data.is_favorite])

  const isAuthorized = user.isAuthorized;

  const toggle = async () => {
    TagsController.event('Buttons', 'SubscribeJob', isLiked ? 'false' : 'true');
    const id = data.source || data._id;
    if (isAuthorized) {
      const uri = `/favorites/${id}`;
      if (isLiked) {
        await api.delete(uri);
        if (!data.is_active) TagsController.event('Actions', 'UnsubscribeJob', data.custom_name);
      } else {
        await api.put(uri);
        if (!data.is_active) TagsController.event('Actions', 'SubscribeJob', data.custom_name);
      }
      setLiked(!isLiked);
    } else {
      // XXX: костыль */
      const open = () => onFetch && onFetch()
      Events.dispatch('MODAL_SHOW-ModalAuth', { callback: open });
    }
  };

  const { city, street, house, metro_station } = data?.workplace?.address || {};
  const address = `${city}, ${metro_station ? `м. ${metro_station}, ` : ''}${street}, ${house}`;

  const onClickCopy = () => {
    copy(address);
    toast(`Адрес скопирован`);
  }

  return (
    <div className="content_block">
      <div className="job_card-company flex mb2">
        <div className="job_card-company-image" style={{ backgroundImage: `url(${baseURL}/client/${data.client})` }} />
        <div className="job_card-company-info">
          <div className="job_card-company-name text_medium">{data.custom_name}</div>
          <div className="job_card-company-address">
            <span className="color_gray">{data.workplace?.client.title}</span>
          </div>
        </div>
        <div onClick={toggle}>{isLiked ? Icons.heartActive : Icons.heart}</div>
      </div>

      <div className="job_card-address flex">
        {Icons.compass}
        {data.distance && <strong>~{data.distance} м. &nbsp;</strong>}
        <span className="color_gray mr1">{address}</span>
        <div onClick={onClickCopy}>{Icons.copy}</div>
      </div>

    </div>
  );
}

export default connect(state => ({ user: state.user }))(Header);
