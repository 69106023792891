import React from 'react';

import api from 'controllers/Api';
import { Header } from 'components/Modal2';
import history from 'controllers/History';

import List from './components/List';

class Rates extends React.Component {
  state = {
    data: [],
    isLoading: true,
    refreshTimer: null,
    newPayments: {},
  }

  componentDidMount = () => {
    this.fetch();
  }

  saveRate(data) {
    api.post('/rates', data)
      .catch(console.log);
  }

  fetch = () => {
    const { isLoading } = this.state;
    if (!isLoading) this.setState({ isLoading: true });

    api.get('/rates')
    .then(response => {
      const data = response.data;
      this.setState({
        data,
        isLoading: false
      });
    });
  }

  updateRates =  (value) => {
    const newPayments = this.state.newPayments;
    this.setState({ newPayments: { ...newPayments, ...value} });
  }

  onClickRatesSave = (e) => {
    const newPayments = this.state.newPayments;
    const keys = Object.keys(newPayments);

    const body = keys.map((_id) => ({ _id, newRate: newPayments[_id] }));
    this.saveRate(body);

    history.push('/orders');
  }

  render() {
    const { data, isLoading } = this.state;

    return (
      <>
        <Header title="Увеличить ставку" close="/orders"/>
        <List data={data} isLoading={isLoading} updateRates={this.updateRates}/>
        <div className="modal_btn-holder"></div>

        <div className="btn btn_primary modal_btn" onClick={this.onClickRatesSave}>Сохранить изменения</div>
      </>
    );
  }
}

export default Rates;
