import React from 'react';
import Day from './day';
import Preloader from 'components/Preloader';

class List extends React.Component {

  renderItems = () => {
    const { data, updateRates } = this.props;

    return <Day items={data} updateRates={updateRates} />
  }

  render() {
    const { isLoading, data } = this.props;
    const length = Object.entries(data).length;

    if(!isLoading && !length) {
      return (
        <div className="layout_tall">
          <div className="empty_state">
            <span role="img" aria-label="empty" className="empty_state-icon">🤷‍♂️</span>
            <div className="empty_state-title">Нет подходящих заказов</div>
          </div>
        </div>
      );
    }

    return isLoading
      ? <Preloader className="layout_tall"/>
      : (
        <div className="orders_list">
          {this.renderItems()}
        </div>
      );

  }

}

export default List;
