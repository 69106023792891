import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Modal2 from 'components/Modal2';
import { baseURL } from 'controllers/Api';
import { formatPrice, receiptInfo } from 'utils/Helpers';
import Icons from 'utils/Icons';
import TagsController from 'controllers/Tags';

const isSkipReceipt = (job) => {
  const { receipt_status: status, skip_receipt: skip = false } = job;

  return status === 'correct' && skip;
};

const TransactionIncome = ({ data }) => {
  const {
    _id,
    title,
    value,
    receipt_status: status,
    act_pdf_url: actUrl,
    receipt,
    createdAt,
    transaction_type: type,
  } = data;

  const date = moment(createdAt).format('DD.MM.YYYY, в HH:mm');

  const to = {
    pathname: `/profile/transactions/${_id}/receipt`,
    state: { data },
  };

  const { isActNeeded, isReceiptNeeded, isProcessing, message, isOk } =
    receiptInfo(status, receipt);

  const classes = classNames({
    'transactions-history_item-income': true,
    'transactions-history_item': true,
    pv2: true,
    bb: true,
    'transactions-history_item-action-required': isActNeeded || isReceiptNeeded,
    'transactions-history_item-processing': isProcessing,
    'transactions-history_item-ok': isOk,
  });

  const viewTransaction = () => Modal2('ModalTransaction', { data });
  const onCreateReceipt = () =>
    TagsController.event('Buttons', 'CreateCheck', 'Balance');

  return (
    <div key={_id} className={classes}>
      <div className="flex mb1">
        <span className="color_gray">{date}</span>
        <span className="transactions-history_item-amount mla">
          + {formatPrice(value)}
          {!isOk && Icons.clock}
        </span>
      </div>

      <div
        role="button"
        tabIndex={0}
        className="transactions-history_item-title"
        onClick={type !== 'gift' ? viewTransaction : null}
      >
        {title}
      </div>

      {isActNeeded && (
        <div className="flex mt1">
          <span className="color_red mra">Нужно подписать акт!</span>
          <Link to={to} className="color_blue">
            <strong>Подписать акт</strong>
          </Link>
        </div>
      )}

      {isReceiptNeeded && !isActNeeded && (
        <div className="flex mt1">
          <span className="color_red mra">{message}</span>
          <Link onClick={onCreateReceipt} to={to} className="color_blue">
            <strong>Создать чек</strong>
          </Link>
        </div>
      )}

      {type !== 'gift' && (
        <div className="mt1">
          {!isActNeeded && type !== 'prepay' && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseURL}${actUrl}`}
              className="mr2 color_blue"
            >
              Акт
            </a>
          )}
          {!isSkipReceipt(data) && !isReceiptNeeded && !isActNeeded && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={receipt}
              className="color_blue"
            >
              Чек {isProcessing && (message ? `(${message})` : '(проверяется)')}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

TransactionIncome.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    receipt_status: PropTypes.string.isRequired,
    act_pdf_url: PropTypes.string.isRequired,
    receipt: PropTypes.string.isRequired,
    transaction_type: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default TransactionIncome;
