const TAX_APP_URL_IOS = 'https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%BD%D0%B0%D0%BB%D0%BE%D0%B3/id1437518854';
const TAX_APP_URL_ANDROID = 'https://play.google.com/store/apps/details?id=com.gnivts.selfemployed&hl=ru';
const TAX_APP_URL_WEB = 'https://npd.nalog.ru/app/';

/**
 * Get fns url depend on platform
 * @param OS
 * @return {string}
 */
export const getFnsLink = (OS) => {
  switch (OS) {
    case 'iOS': return TAX_APP_URL_IOS;
    case 'Android': return TAX_APP_URL_ANDROID;
    default: return TAX_APP_URL_WEB;
  }
}
