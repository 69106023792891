import React from 'react';
import PropTypes from 'prop-types';

const baseURL = process.env.REACT_APP_BASE_URL;

const SignedDocuments = ({ data }) => {
  return (
    <div className="content_block">
      <div className="content_block-title mb2">Подписанные документы</div>
      {data?.map((doc) => (
        <a
          key={doc._id}
          href={`${baseURL}/document/view-signed/${doc.nameSignedDoc}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {doc.docTitle}
        </a>
      ))}
    </div>
  );
};

SignedDocuments.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any).isRequired),
};

SignedDocuments.defaultProps = {
  data: [],
};

export default React.memo(SignedDocuments);
