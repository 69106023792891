import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import DisplayError from '../../../../../components/DisplayError';

const baseURL = process.env.REACT_APP_BASE_URL;

const SignDoc = ({ doc, form, field, index }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        <div
          aria-label="set_value"
          role="button"
          tabIndex={0}
          className={`checkbox mr2 ${field.value && 'active'}`}
          onClick={() => form.setFieldValue(field.name, !field.value)}
        />
        <a
          href={`${baseURL}/document/${doc.file?._id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="ml1 mr2"
          style={{ lineHeight: 1.5 }}
        >
          {doc.title}
        </a>
      </div>
      <DisplayError
        isDisplay={!!form.errors.documents_for_signature?.[index]}
        value={form.errors.documents_for_signature?.[index]?.status}
        style={{ lineHeight: 1.5 }}
      />
    </div>
  );
};

SignDoc.propTypes = {
  doc: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};

export default compose(React.memo)(SignDoc);
