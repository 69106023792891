import controller from 'controllers/Chats';

const initialState = {
  list: [],
  unreadMessagesCount: 0,
};

export default (state = initialState, action) => {
  const fn = controller.actions[action.type];
  if(fn && typeof fn === 'function') return fn(action, state);
  return state;
}
