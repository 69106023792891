import React, { useCallback, useEffect, useState } from 'react';
import api from 'controllers/Api';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { dateToTextLong, formatPrice, pick } from 'utils/Helpers';
import Item from 'containers/EmployerOrders/components/Item';
import Preloader from 'components/Preloader';
import Modal2 from 'components/Modal2';

const REFRESH_INTERVAL = 30 * 1000;

const SupervisorOrders = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    workplace: null,
    status: 'active',
    groupby: 'dates,workplaces',
  });

  const workplaces = useSelector((s) =>
    s.env.workplaces?.map((w) => pick(w, ['id', 'title'])),
  );

  const fetch = useCallback(
    (silent = false) => {
      const controller = new AbortController();

      if (!silent) setLoading(true);

      api
        .get(`/orders`, {
          params: filters,
          signal: controller.signal,
        })
        .then((res) => {
          setData(res.data);
        })
        .finally(() => {
          setLoading(false);
        });

      return () => controller.abort();
    },
    [filters],
  );

  useEffect(() => {
    let abort = fetch();
    const fetchInterval = setInterval(() => {
      abort();
      abort = fetch(true);
    }, REFRESH_INTERVAL);
    return () => {
      abort();
      clearInterval(fetchInterval);
    };
  }, [fetch]);

  const onClickTab = (e) => {
    const { tab } = e.currentTarget.dataset;
    if (tab === filters.status) return;
    setFilters({ ...filters, status: tab });
  };

  const onChangeWorkplace = (e) => {
    const id = e.currentTarget.value;
    setFilters({ ...filters, workplace: id === 'all' ? null : id });
  };

  const onClickOrderNew = () => {
    Modal2('ModalOrderNew');
  };

  const renderItems = () => {
    if (!Object.keys(data).length) {
      return (
        <div className="layout_tall">
          <div className="empty_state">
            <span role="img" aria-label="empty" className="empty_state-icon">
              🤷‍♂️
            </span>
            <div className="empty_state-title">
              Нет {filters.status === 'active' ? 'актуальных' : 'завершенных'}{' '}
              заказов
            </div>
            <div className="empty_state-text">
              Попробуйте изменить условия поиска
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ height: 'calc(100vh - 244px)', overflowY: 'auto' }}>
        {Object.keys(data).map((date) => {
          const item = data[date];
          const renderedWorkplaces = Object.keys(item.workplaces).map(
            (workplaceId) => {
              const items = item.workplaces[workplaceId].map((order) => (
                <Item key={order.id} data={order} hideSalary />
              ));
              return <React.Fragment key={workplaceId}>{items}</React.Fragment>;
            },
          );
          return (
            <div key={date} className="orders_list orders_list-day">
              <div className="orders_day-title ph2 pt3 pb1 flex">
                {dateToTextLong(date)}
                <div className="orders_day-expenses mla">
                  {formatPrice(item.day_total)} ₽
                  {item.day_projected && item.day_projected !== item.day_total && (
                    <>
                      {' '}
                      / <span>{formatPrice(item.day_projected)} ₽</span>
                    </>
                  )}
                </div>
              </div>
              {renderedWorkplaces}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="p2 pt3">
        <h1 className="mb2">Заказы</h1>
        <select style={{ width: '100%' }} onChange={onChangeWorkplace}>
          <option value="all">Все локации</option>
          {workplaces.map((workplace) => (
            <option key={workplace.id} value={workplace.id}>
              {workplace.title}
            </option>
          ))}
        </select>
      </div>
      <div className="orders_tabs">
        <div
          role="button"
          tabIndex={0}
          className={classNames('orders_tab-button', {
            'orders_tab-active': filters.status === 'active',
          })}
          onClick={onClickTab}
          data-tab="active"
        >
          Актуальные
        </div>
        <div
          role="button"
          tabIndex={0}
          className={classNames('orders_tab-button', {
            'orders_tab-active': filters.status !== 'active',
          })}
          onClick={onClickTab}
          data-tab="inactive"
        >
          Завершённые
        </div>
      </div>
      {loading ? <Preloader className="layout_tall" /> : renderItems()}
      <div className="modal_btn-holder" />

      <div
        role="button"
        tabIndex={0}
        className="btn btn_primary modal_btn"
        onClick={onClickOrderNew}
      >
        Новый заказ
      </div>
    </>
  );
};

export default SupervisorOrders;
