import React from 'react';
import api from 'controllers/Api';
import moment from 'moment';
import Preloader from 'components/Preloader';
import { connect } from 'react-redux'
import {formatPrice, pick} from "../../utils/Helpers";

class SupervisorExpenses extends React.Component {

  state = {
    data: {},
    workplaces: [],
    isLoading: true,
    filters: {
      workplace: null,
    }
  }

  componentDidMount() {
    this.fetch();

    const workplaces = this.props.workplaces.map(item => pick(item, ['id', 'title']));
    this.setState({ workplaces });
  }


  fetch = () => {
    const { isLoading, filters } = this.state;
    if(!isLoading) this.setState({ isLoading: true });

    // TODO: remove limit
    api.get(`/super/expenses?limit=10000`, {
      params: filters,
    })
    .then(response => {
      this.setState({
        data: response.data,
        isLoading: false
      });
    });
  }

  onChangeWorkplace = (e) => {
    const { filters } = this.state;
    const id = e.currentTarget.value;
    filters.workplace = id;
    if(id === 'all') filters.workplace = null;
    this.setState({
      filters,
    }, this.fetch);
  }

  renderItems = () => {
    const { data } = this.state;
    const keys = Object.keys(data.confirmed.by_month);
    if(!keys.length) {
      return (
        <div className="layout_tall">
          <div className="empty_state">
            <span role="img" aria-label="empty" className="empty_state-icon">🤷‍♀️️</span >
            <div className="empty_state-title">Пока пусто</div>
            <div className="empty_state-text" style={{ textAlign: 'center' }}>
              Затраты по данным параметрам
              <br />пока не появились
            </div>
          </div>
        </div>
      );
    }

    return keys.map((key, i) => {
      const date = moment(key).format('MMMM YYYY');
      const { cost, projected } = data.confirmed.by_month[key];
      return (
        <div key={key} className="expenses-list-item p2 bb">
          <div className="flex">
            <strong className="text-capitalize">{date}</strong>
            <strong className="mla">{formatPrice(cost)} ₽
              {!!projected && <><span className="color_gray"> / {formatPrice(projected)} ₽</span></>}
            </strong>
          </div>
        </div>
      )
    });
  }

  render() {
    const { isLoading, workplaces } = this.state;
    return (
      <>
        <div className="p2 pt3">
          <h1 className="mb2">Затраты</h1>
          <select style={{ width: '100%' }} onChange={this.onChangeWorkplace}>
            <option value="all">Все локации</option>
            {workplaces.map(workplace => <option key={workplace.id} value={workplace.id}>{workplace.title}</option>)}
          </select>
        </div>
        <hr className="m0" />
        {isLoading ? <Preloader className="layout_tall" /> : this.renderItems()}
      </>
    );
  }

}

const mapStateToProps = (state) => ({
    workplaces: state.env.workplaces,
})

export default connect(mapStateToProps)(SupervisorExpenses);
